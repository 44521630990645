function randomPick(arr) {
    if (arr.length === 0) {
      return null; // Return null if the array is empty
    } else {
      const randomIndex = Math.floor(Math.random() * arr.length);
      return arr[randomIndex];
    }

}

export default randomPick;