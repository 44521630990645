<template>
    <div v-if="project && project.name!=='Unassigned Calls'">
        <button @click="inviteCollabButtonClick" class="invite-collab-btn mr-3">
            <!-- <i class="ri-user-shared-line"></i> -->
            <i class="ri-share-line"></i>
            <!-- <img class="mr-1" src="../../../public/analysis-invite-icon.svg" /> -->
            Share Project
        </button>
        <LoopActionModal
            :width="600"
            :showModal="showInviteModal"
            :isSmallTitle="true"
            :title="`Share ${project.emoji ? project.emoji+' '+project.name : project.name} project`"
            :showCta="false"
            v-on:toggle-modal="toggleShowInviteModal"
        >
            <template v-slot:content>
                <div class="invite-modal-helper mb-3">
                    Invite collaborators via email to view the discussion guide, calls, notes and analysis in this project
                </div>
                <div class="invite-analysis-container" v-if="!disableAnalysisEditAccess">
                    <input :value="access=='read-write-analysis'" type="checkbox" @change="toggleAccess" />
                    Allow invited collaborators to edit analysis
                </div>
                <div class="invite-modal-action mb-5">
                    <input  @keyup.enter="sendInvite" v-model="invitee" class="invite-modal-input" placeholder="Enter collaborator’s email ID"/>
                    <button :disabled="!invitee || sendInviteLoading" @click="sendInvite" class="invite-modal-send-btn">
                        <template v-if="sendInviteLoading">Sharing...</template>
                        <template v-else>Share Project</template>
                    </button>
                </div>
                <p class="send-invite-error" v-if="sendInviteError">{{  sendInviteError }}</p>
                <div class="invites-container">
                    <p>Collaborators</p>
                    <div class="invites-list" v-if="invites.length>0">
                        <div v-for="invite of invites" :key="invite.id" class="invite-container"
                            :style="{'opacity':removeInviteLoading==invite.id?'0.5':'1'}"
                        >
                        <v-hover v-slot="{ hover}">
                            <div class="invite-item">
                                <div>
                                    <LoopColoredAvatar :avatarText="invite.inviteeEmail" size="medium" />
                                    <template v-if="invite.showRemoveAccessConfirmation">
                                        <span class="email ml-2">Remove access for {{invite.inviteeEmail}}?</span>
                                    </template>
                                    <template v-else>
                                        <span class="email ml-2">{{invite.inviteeEmail}}</span>
                                    </template>
                                </div>
                                <div class="status">
                                    <template></template>
                                    
                                    <template v-if="invite.showRemoveAccessConfirmation">
                                        <div>
                                            <button @click="cancelRemoveInviteAccess(invite)" class="cancel-remove-invite-btn mr-1">Cancel</button>
                                            <button @click="confirmRemoveInviteAccess(invite)" class="confirm-remove-invite-btn">Confirm</button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="showResentInviteSuccessMessage==invite.inviteeEmail">
                                            <div class="resend-invite-success">
                                                <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.00004 14.6693C4.31814 14.6693 1.33337 11.6845 1.33337 8.0026C1.33337 4.3207 4.31814 1.33594 8.00004 1.33594C11.6819 1.33594 14.6667 4.3207 14.6667 8.0026C14.6667 11.6845 11.6819 14.6693 8.00004 14.6693ZM7.33511 10.6693L12.0492 5.95522L11.1064 5.01242L7.33511 8.78367L5.44951 6.898L4.5067 7.84087L7.33511 10.6693Z" fill="#33B373"/>
                                                </svg>
                                                New invite sent
                                            </div>
                                        </template>
                                        <template v-else-if="resendingInvite==invite.inviteeEmail">
                                            <div class="resend-invite-progress">
                                                <v-progress-circular class="mr-2" indeterminate color="#5B7EDB" :size="15" :width="2"></v-progress-circular>
                                                Resending Invite...
                                            </div>
                                        </template>
                                        <template v-else-if="invite.status==='pending'">
                                            <template v-if="hover">
                                                <template>
                                                    <button :disabled="resendingInvite==invite.inviteeEmail" @click="resendInvite(invite)" class="resend-invite-btn">
                                                        <template v-if="resendingInvite==invite.inviteeEmail">
                                                            <v-progress-circular class="mr-2" indeterminate color="#5B7EDB" :size="15" :width="2"></v-progress-circular>
                                                            Resending Invite...
                                                        </template>
                                                        <template v-else>
                                                            Resend Invite
                                                        </template>
                                                    </button>
                                                </template>
                                            </template>
                                            <template v-else>
                                                {{invite.status}}
                                            </template>
                                        </template>
                                        <template v-else-if="invite.status==='accepted'">
                                            <template v-if="hover">
                                                <button v-show="hover" :disabled="removeInviteLoading==invite.id" @click="removeInviteAccess(invite)" class="remove-access-btn">
                                                    <template v-if="removeInviteLoading==invite.id">
                                                        Removing Access...
                                                    </template>
                                                    <template v-else>
                                                        Remove Access
                                                    </template>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <template v-if="invite.access=='read-write-analysis'">
                                                    Project viewer, can edit analysis
                                                </template>
                                                <template v-else>
                                                    Project viewer
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                        </div>
                        </v-hover>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="workspace-members-container">
                    <div class="workspace-members-toggle" @click="toggleShowWorkspaceMembers">
                        <div class="workspace-menu-activator">{{ userWorkspace.name[0] }}</div>
                        {{userWorkspace.name}} Workspace members
                        <template v-if="showWorkspaceMembers">
                            <i class="ri-arrow-up-s-line"></i>
                        </template>
                        <template v-else>
                            <i class="ri-arrow-down-s-line"></i>
                        </template>
                        
                    </div>
                    <div class="workspace-members mt-5" v-if="showWorkspaceMembers">
                        <Member 
                            :showDividersBetweenMembers="true"
                            v-for="member in workspaceMembers" :workspaceId="userWorkspace.id"
                            :disableWorkspaceRoleMenu="true"
                            :key="member.id" :member="member" :owner="member.id ==userWorkspace.owner" :renderingForOwner="false"
                        />    
                    </div>
            
                </div>
                <div class="invite-to-workspace-container">
                    <div class="invite-to-workspace" @click="inviteToWorkspace">
                        Invite to Workspace Instead
                        <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M12 9.83333L10.6667 9.83333L10.6667 13.1667L3.33333 13.1667L3.33333 5.83333L6.66667 5.83333L6.66667 4.5L2.66666 4.5C2.48985 4.5 2.32028 4.57024 2.19526 4.69526C2.07024 4.82029 2 4.98986 2 5.16667L2 13.8333C2 14.0101 2.07024 14.1797 2.19526 14.3047C2.32028 14.4298 2.48985 14.5 2.66667 14.5L11.3333 14.5C11.5101 14.5 11.6797 14.4298 11.8047 14.3047C11.9298 14.1797 12 14.0101 12 13.8333L12 9.83333ZM14 2.5L8 2.5L10.5287 5.02933L6.52867 9.02867L7.47133 9.97133L11.4713 5.972L14 8.5L14 2.5Z" fill="#5B7EDB"/>
                        </svg>
                    </div>
                </div>
            </template>
        </LoopActionModal>
        <Snackbar
            :renderSuccessTick="true"
            :message="'Invite sent successfully'"
            :showSnackbar="inviteEmailSuccess"
        />
        <Snackbar
            :message="'Removed access successfully'"
            :showSnackbar="removeInviteSuccess"
        />
    </div>
</template>

<script>
import LoopActionModal from '@/components/modals/LoopActionModal.vue';
import LoopColoredAvatar from '@/components/avatars/LoopColoredAvatar.vue'
import Snackbar from '@/components/notifications/Snackbar.vue';
import WorkspaceAPI from '@/api/workspace/workspace';
import ProjectsAPI from '@/api/projects/projects';
import { auth } from '@/firebase';
import { mapState } from "vuex";
import Member from '../workspace/Member.vue'

export default{
    data() {
        return {
            showInviteModal: false,
            invitee:'',
            inviteEmailSuccess: false,
            removeInviteSuccess: false,
            invites:[],
            workspaceMembers:[],
            sendInviteLoading: false,
            removeInviteLoading: null,
            access:'read-all',
            currentUser: auth.currentUser.uid,
            showWorkspaceMembers: false,
            showResentInviteSuccessMessage: null,
            resendingInvite: null,
            sendInviteError: null

        };
    },
    computed:{
        ...mapState([
            "userProfile",
            "trailEnded",
            "userWorkspace",
            "subscriptionActive",
            "plan"
        ]),
        trialEnded() {
            return this.$store.state.trialEnded;
        },
        subscriptionActive(){
            return this.$store.state.subscriptionActive;
        },
        plan(){
            return this.$store.state.plan;
        },
        disableAnalysisEditAccess(){
            const trialEnded = this.trialEnded;
            const subscriptionActive = this.subscriptionActive;
            const plan = this.plan;
            // User is on a trial
            if (trialEnded == false && subscriptionActive == false)
                return false

            // User's trial has expired
            if (trialEnded == true && subscriptionActive == false)
                return true

            // User is on an active subscription
            if (trialEnded == true && subscriptionActive == true){
                if (plan == "Solo") return true
                return false  
            }

            // User is on an expired subscription
            if (trialEnded == true && subscriptionActive == false)
                return true

            return false;
            },
    },
    props:{
        projectId:{
            require:true
        },
        project:{},
        disabled:{
            default: false,
            type: Boolean
        }
    },
    components: { LoopActionModal, Snackbar, Member, LoopColoredAvatar },
    mounted(){
        this.fetchInvitations()
        this.fetchWorkspaceMembers()
    },
    methods:{
        inviteToWorkspace(){
            window.open(`${window.origin}/settings?tab=workspace`, '_blank');  
        },
        toggleShowWorkspaceMembers(){
            this.showWorkspaceMembers = !this.showWorkspaceMembers
        },
        toggleAccess(){
            if(this.access==='read-all') this.access = 'read-write-analysis';
            else this.access = 'read-all';
        },
        async fetchWorkspaceMembers(){
            const workspace_members_response = await WorkspaceAPI.getWorkspaceMembers(this.userWorkspace.id)
            if(workspace_members_response.status && workspace_members_response.status == 'success'){
                console.log('workspace members',workspace_members_response.data)
                this.workspaceMembers = workspace_members_response.data;
            }
        },   
        async fetchInvitations(){
            const invitations = await ProjectsAPI.fetchSharedProjectInvites(this.projectId)
            const { data } = invitations;
            this.invites = data;
            console.log('invites',this.invites)
        },
        inviteCollabButtonClick(){
            if (this.disabled) return;
            window.analytics.track("click on invite collaborators button", {
                email: auth.currentUser.email,
            });
            this.toggleShowInviteModal()
        },
        toggleShowInviteModal(){
            this.showInviteModal=!this.showInviteModal;
        },
        // This function checks if a project collaborator invitee has similar access to the project 
        // already being part of a workspace
        // view-only project = workspace member view-only or more
        // analysis edit access = workspace member editor
        checkIfInviteeHasEquivalentWorkspaceAccess(inviteeEmail,access){
            let isCollaborationInviteRedundant = false
            // First check if the inviteeEmail is a workspace member
            this.workspaceMembers.forEach(member=>{
                if(member.email==inviteeEmail){
                    // Invitee is a workspace member
                    // Now check access level
                    // A workspace member will always have at least read only access to the project => an explicit project collaboration invite is redundant
                    if(access == 'read-all') isCollaborationInviteRedundant = true
                    // Giving edit access to analysis is redundant since the invitee is a workspace editor and can already edit analysis of this project
                    else if(access == 'read-write-analysis' && member.workspace_role == 'editor') isCollaborationInviteRedundant = true
                return 
                }
            })
            return isCollaborationInviteRedundant
        },
        async resendInvite(invite){
            console.log('resend this invite',invite)
            const invitee = invite.inviteeEmail
            this.resendingInvite = invitee
            // console.log('sending invite to',this.invitee)
            const reSendInvite = await ProjectsAPI.sendSharedProjectInvite(invitee,this.projectId,invite.access)
            this.sendInviteLoading = false;
            if(reSendInvite.status == "success"){
                window.analytics.track("resend a collaboration invite", {
                    email: auth.currentUser.email,
                });
                this.resendingInvite = null
                this.showResentInviteSuccessMessage = invitee;
                setTimeout(()=>{
                    this.showResentInviteSuccessMessage = null;
                    // this.fetchInvitations()
                },1500)
                
            }else{
                alert("An error occurred while re-inviting this collaborator. Please try again later or contact support")
                console.log("An error occurred while re-inviting this collaborator", reSendInvite)
            }
        },
        async sendInvite(){
            this.sendInviteError = null
            if(this.userProfile && this.userProfile.email && this.userProfile.email == this.invitee){
                alert("Cant invite yourself to your own project")
                return;
            }
            //Call invite API
            this.sendInviteLoading = true;
            this.invitee = this.invitee.trim()
            // console.log('sending invite to',this.invitee)
            if(this.checkIfInviteeHasEquivalentWorkspaceAccess(this.invitee,this.access)){
                this.sendInviteLoading = false;
                this.sendInviteError = `${this.invitee} has access to this project already`
                // alert("This person is a member of your workspace and already has access to the project")
                return;
            }
            const sendInvite = await ProjectsAPI.sendSharedProjectInvite(this.invitee,this.projectId,this.access)
            this.sendInviteLoading = false;
            if(sendInvite.status == "success"){
                window.analytics.track("send a collaboration invite", {
                    email: auth.currentUser.email,
                });
                this.invitee = '';
                this.inviteEmailSuccess = true;
                this.access = 'read-all'
                setTimeout(()=>{
                    this.inviteEmailSuccess = false;
                    this.fetchInvitations()
                },1500)
                
            }else{
                alert("You've already invited this collaborator")
            }

        },
        async removeInviteAccess(invite){
            window.analytics.track("click on remove access on invite", {
                    email: auth.currentUser.email,
            });
            this.invites = this.invites.map(i=>i.id==invite.id?{...i,showRemoveAccessConfirmation:true}:i)
        },
        async cancelRemoveInviteAccess(invite){
            this.invites = this.invites.map(i=>i.id==invite.id?{...i,showRemoveAccessConfirmation:false}:i)
        },
        async confirmRemoveInviteAccess(invite){
            //Call invite API
            this.removeInviteLoading = invite.id;
            const removeInvite = await ProjectsAPI.acceptOrRejectSharedProjectInvite(invite.id,this.projectId,'revoked')
            if(removeInvite.status == "success"){
                window.analytics.track("remove access on invite", {
                        email: auth.currentUser.email,
                });
                this.removeInviteSuccess = true;
                setTimeout(()=>{
                    this.removeInviteSuccess = false;
                    this.fetchInvitations()
                    this.removeInviteLoading=null;
                },1500)
                
            }else{
                alert("Error revoking access for this collaborator")
            }
            this.invites = this.invites.filter(i=>i.id!==invite.id)
        }
    },
    watch:{
        invitee(newVal,oldVal){
            if(this.sendInviteError) this.sendInviteError = null
        }
    }
}

</script>

<style scoped>
    .invite-collab-btn {
        box-sizing: border-box;
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        width: auto;
        border: 1px solid #5B7EDB;
        border-radius: 44px;
        outline:none;
    }

    .invite-collab-btn{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        letter-spacing: -0.01em;
        text-transform: capitalize;

        /* Primary/500 */

        color: #5B7EDB;
    }

    .invite-collab-btn i{
        margin-right: 8px;
    }

    .invite-modal-helper{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */


        /* Neutrals/400 */

        color: #4C4C4C;
        margin-bottom: 10px;
    }

    .invite-analysis-container{
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px;
        background: #F6F8FE;
        margin-bottom: 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #5B7EDB;
    }

    .invite-modal-action{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .invite-modal-input{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px;
        color: #4C4C4C;
        width: 67%;
        height: 37px; /* Primary/400 */
        border: 1px solid #D2DCFB;
        border-radius: 8px;
    }

    .invite-modal-send-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 4px;

        width: 148px;
        height: 37px;

        /* Primary/500 */

        background: #5B7EDB;
        
        border-radius: 32px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        text-transform: capitalize;

        /* Neutrals/White */

        color: #FFFFFF;
    }

.invite-modal-send-btn:disabled{
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}

.invites-container{
    width: 100%;
    border-top: 1px solid #EDEDED;
    /* padding-top: 10px; */
}

.invites-container p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    margin-bottom:16px;
    margin-top:16px;
    /* Neutrals/400 */

    color: #999999;
}

.invites-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 12px; */
    flex-direction: column;
}

.invites-list>div{
    width: 100%;
    justify-content: space-between;
}

.invites-list div{
    display: flex;
    align-items: center;
}

.invites-list .email{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;
    /* Neutrals/600 */
    color: #4C4C4C;
}

.invites-list .status{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */


    /* Neutrals/400 */

    color: #999999;

}

.remove-access-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    height: 29px;

    /* Accent/Red/Light */

    background: #FFF5F5;
    border-radius: 100px;

    /* Accent/Red/Default */

    color: #C53030;
    border: none;
    outline: none;
}

.cancel-remove-invite-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    outline: none;
    border: none;
    /* Primary/500 */
    color: #5B7EDB;
}

.confirm-remove-invite-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    /* width: 79px; */
    height: 29px;
    /* Accent/Red/Light */
    background: #FFF5F5;
    border-radius: 100px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    /* Accent/Red/Default */
    color: #C53030;
}

.invite-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.workspace-members-container{
    /* margin-bottom:20px; */

}

.workspace-members{
    transition: all 0.1s;
    max-height: 200px;
    overflow-y: scroll;
    margin-left: 38px;
}

.workspace-members::-webkit-scrollbar{
        display: none;
}
.workspace-menu-activator{
    display: flex;
    width: 28px;
    height: 28px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 2px solid var(--primary-300, #E8EDFC);
    background: var(--primary-100, #FAFBFE);
    margin-right: 12px;
    color: var(--primary-500, #5B7EDB);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    text-transform: capitalize;
    outline:none;
}

.workspace-members-toggle{
    display: flex;
    align-items: center;
    color: var(--Neutrals-600, #4C4C4C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
    cursor: pointer;
    padding-bottom:12px;
    border-bottom: 1px solid #EDEDED;
}

.workspace-members-toggle i{
    position: absolute;
    right: 5%;
    color: #5B7EDB;
}

.resend-invite-btn{
    display: flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 1px solid var(--Primary-500, #5B7EDB);
    color: var(--Primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    text-transform: capitalize;
}

.resend-invite-btn:disabled{
    opacity: 0.7;
    cursor: not-allowed;
}

.resend-invite-success{
    color: var(--Accent-Green-Default, #33B273);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
}

.resend-invite-progress{
    color: var(--Primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    text-transform: capitalize;
}

.invite-container{
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 12px;
    margin-bottom: 12px
}

.invite-to-workspace-container{
    margin-top: 12px;
    margin-bottom: 24px;
}

.invite-to-workspace{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding-top: 12px; */
    color: var(--Primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
}

.send-invite-error{
    color: var(--Accent-Red-Default, #C53030);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    margin-top: -15px;
}
</style>