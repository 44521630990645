import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// firebase init - add your own config here
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyAOfJ6feJYWu-0P0E7G7XCMJvGIBDQltHA",
  authDomain: "app.looppanel.com",
  projectId: "poc-project-309214",
  storageBucket: "poc-project-309214.appspot.com",
  messagingSenderId: "206823708291",
  appId: "1:206823708291:web:03aaff36cc768eed4cedb6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()

// collection references0=
const usersCollection = db.collection('users')
const transcriptsCollection = db.collection('transcripts')
const annotationsCollection = db.collection('annotations')
const tagsCollection = db.collection('tags')
const zoomRecordingsCollection = db.collection('zoom_recordings')
const companiesCollection = db.collection('companies')
// const botRecordingsCollection = db.collection('dummy_recordings')
const botRecordingsCollection = db.collection('bot_recordings')
const projectsCollection = db.collection('projects')
const scriptsCollection = db.collection('scripts')
const questionsCollection = db.collection('questions')
const insightsCollection= db.collection('insights')
const phTranscriptsCollection = db.collection('ph_transcripts')
const phAnnotationsCollection = db.collection('ph_annotations')
const phUsersCollection = db.collection('ph_users')
const workspaceCollection = db.collection('workspaces')
const ssoOrganizationsCollection = db.collection('sso_organizations')
const paymentsCollection = db.collection('payments')
const aiSummariesCollection = db.collection('ai_summaries')


// export utils/refs
export {
  db,
  auth,
  storage,
  usersCollection,
  transcriptsCollection,
  annotationsCollection,
  tagsCollection,
  zoomRecordingsCollection,
  companiesCollection,
  // botRecordingsCollection,
  botRecordingsCollection,
  projectsCollection,
  scriptsCollection,
  questionsCollection,
  insightsCollection,
  phTranscriptsCollection,
  phAnnotationsCollection,
  phUsersCollection,
  workspaceCollection,
  ssoOrganizationsCollection,
  paymentsCollection,
  aiSummariesCollection
};

