function hexToRgb(hex) {
    // Remove the '#' character if it exists
    hex = hex.replace(/^#/, '');
  
    // Parse the hex color components
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
  
    // Return the RGB color as an object
    return {
      r: r,
      g: g,
      b: b
    };
  }
  
  // Example usage:
//   const hexColor = "#336699";
//   const rgbColor = hexToRgb(hexColor);
//   console.log("RGB Color:", rgbColor);
  
export default hexToRgb;