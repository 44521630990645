<template>
    <v-menu
         :disabled="disableMenu"
         v-model="showCallMenu"
         content-class="call-menu"
         :close-on-content-click="false"
         :close-on-click="true"
         :nudge-width="200"
         :nudge-bottom="10"
         :offset-y="true"
         z-index="100"
     >
        <template v-slot:activator="{ on, attrs }">
             <div @click="clickedActivator" v-bind="attrs" v-on="on">
                 <slot name="custom-activator">

                 </slot>
             </div>
               
              
         </template>

          <div v-if="showCallMenu" class="call-popup-container">

               <div class="call-popup-search">
                     <i class="ri-search-line"></i>
                      <input v-model="callsSearched" :placeholder="`Search for a ${type==='sessions'?'file':'project'}`" type="text"/>
               </div>
                            
               
                            
             <div class="call-popup-options">
                    <div @click="selectCall(call)" class="call-option-container" :key="call.id" 
                        v-for="call in searchedCalls" :value="call.fileName">
                            <template v-if="type==='sessions'">
                                <p class="call-option">{{call.fileName}}</p>
                            </template>
                            <template v-else>
                                <p class="call-option">
                                    <template v-if="call.default">
                                            <img class="mr-1" src="../../../public/loop-logo-small.svg" />
                                    </template>
                                    <template v-else>
                                        {{call.emoji}}
                                    </template>
                                    {{call.name}}
                                </p>
                            </template>
                    </div>
             </div>

         </div>

</v-menu>
</template>

<script>
import { auth } from "@/firebase";


export default {
    components:{},
    props:{
        calls:{},
        type:{
            default:'sessions'
        },
        disableMenu:{
            default: false
        }
    },
    data(){
        return{
           callsSearched:'',
           showCallMenu:false
        }
    },
    methods:{
        clickedActivator(){
            if(this.showCallMenu==true){
                setTimeout(()=>this.callsSearched='',1000)
            }
        },
        selectCall(call){
            console.log('called selectCall',call)
            if(this.type==='sessions'){
                window.analytics.track("open another call using dropdown from editor view", {
                    email: auth.currentUser.email,
                });
                this.$router.push(`/editor?projectId=${call.projectId}&transcriptId=${call.id}`)
            }
            else {
                this.$router.push(`/project/${call.id}/sessions`)
            }
            this.showCallMenu=false
            this.$emit('rerender');
            // window.open(`/editor?projectId=${call.projectId}&transcriptId=${call.id}`,'_self')
            // this.$emit('apply-insight',insight)
            // this.showInsightsMenu=false;
        },
    },
computed: {
    searchedCalls(){
        return this.calls.filter(
                call=>
                this.type==='sessions'?
                    call.fileName.toLowerCase().includes(
                        this.callsSearched.toLowerCase()
                    )
                    :
                    call.name.toLowerCase().includes(
                        this.callsSearched.toLowerCase()
                    )
        )
    }
},
watch:{
    showCallMenu:function (newVal,oldVal){
        if(newVal!==oldVal){
            this.callsSearched=''
        }
    }
}



}
</script>

<style scoped>
.call-menu{
    box-shadow: none;
    min-width: 0px;
    background: #FFFFFF;
    /* Shadow- L1 */
    min-width: 0px !important;
    box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.15);
    border-radius: 12px;
    padding:0px !important;
    max-width: 325px !important;
}

.call-popup-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* width: 208px; */
    width:100%;
    height: 160px;
    overflow-y: scroll;
    background: #FFFFFF;
    /* Shadow- L1 */

    box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.15);
    border-radius: 12px;
}

.call-popup-search{
    border-bottom: 1px solid #ededed;
    padding:5px 12px;
    display:flex;
    flex-direction: row;
    width:100%;
}

.call-popup-search input{
    border:none;
    outline: none;
    margin-left: 10px;
}

.call-popup-search input::placeholder{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    display: flex;
    align-items: center;

    /* Neutrals/400 */

    color: #999999;
}
.call-popup-options{
    display:flex;
    flex-direction: column;
    width:100%;
    padding:12px;
}

.call-option-container{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}


.call-option-container i,.insights-option-chose i{
    color:#5B7EDB
}



.call-option{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 18px */
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor:pointer;
    /* Neutrals/500 */

    color: #666666;
}

</style>