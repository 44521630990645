<template>
    <div class="invite-workspace-members-modal-container">
      <button class="invite-workspace-members-modal-btn" :disabled="disabled" @click="showInviteWorkspaceMembersModal">
        <i class="ri-group-line"></i>
        Invite members
      </button>
      <LoopActionModal
        v-if="workspace"
        :showModal="showInviteMembersModal"
        :title="`Invite members to Team ${userWorkspace.name}`"
        :showCta="false"
        v-on:skip="toggleInviteMembersModal"
        v-on:toggle-modal="toggleInviteMembersModal"
        :width="600"
        :showBorder="true"
        :isSmallTitle="true"
    >
            <template v-slot:content>
                <div class="invite-member-modal-container mt-3 mb-3">
                    <p class="message">
                        Invite members to view and collaborate to your workspace. Store all your data in one place. Stay updated with your team’s work.
                    </p>
                    <InviteMember :workspaceId="workspace.id" :type="'minimal'" :showInvitations="true"/>

                    <!-- <button @click="toggleInviteMembersModal" class="skip-btn">Skip</button> -->
                </div>
            
            </template>
        </LoopActionModal>
    </div>
</template>

<script>
import WorkspaceAPI from '@/api/workspace/workspace';
import LoopActionModal from './LoopActionModal.vue';
import InviteMember from '@/views/workspace/InviteMember.vue';
import { auth } from '@/firebase';
import { mapState } from "vuex";

export default {
    components:{LoopActionModal, InviteMember},
    data(){
        return{
            workspace:null,
            showInviteMembersModal: false,
        }
    },
    props:{
        disabled: {
            default: false,
            type: Boolean
        }
    },
    mounted(){
        this.getWorkspace()
        
    },
    computed:{
        ...mapState([
            "userProfile",
            "userWorkspace",
        ]),
    },
    methods:{
        showInviteWorkspaceMembersModal(){
            window.analytics.track('click on Invite Members button from homepage', {
                email: auth.currentUser.email,
            });
            this.toggleInviteMembersModal()
        },
        toggleInviteMembersModal(){
            this.showInviteMembersModal = !this.showInviteMembersModal
        },
        async getWorkspace(){
            if (!this.userWorkspace.owner){
                console.log('no owner for this workspace yet')
                this.showWelcomeWorkspaceModal = true
            }
            this.workspace = this.userWorkspace
            // const workspaceResponse = await WorkspaceAPI.getUserWorkspace()
            // if(workspaceResponse.status == 'success'){
            //     this.workspace = workspaceResponse.data;
            //     this.$store.dispatch("setUserWorkspace", this.workspace)
            //     console.log('workspace owner',this.workspace.owner)
            //     if(!this.workspace.owner){
            //         console.log('no owner for this workspace yet')
            //         this.showWelcomeWorkspaceModal = true
            //     }
            // }
        },
    }
}
</script>

<style scoped>

.invite-workspace-members-modal-btn{
    margin-left: 10px;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 44px;
    border: 1px solid var(--primary-500, #5B7EDB);
    background: var(--neutrals-white, #FFF);
    color: var(--primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    text-transform: capitalize;
    outline: none;
}
.invite-member-modal-container{
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
}

.message{
    color: #4c4c4c;
    /* text-align: center; */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 16px;
}


</style>