var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showTooltip)?[_c('span',[_c('v-tooltip',{attrs:{"content-class":"tooltip-collaborator","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"mr-1"},'div',attrs,false),on),[_c('div',{staticClass:"avatar",style:({
                          'background-color':_vm.backgroundColor,
                          'color':_vm.color,
                          'border':`1.5px solid ${_vm.strokeColor}`,
                          'height':_vm.size == 'small' ? '24px' : '30px',
                          'width':_vm.size == 'small' ? '24px' : '30px',
                      })},[_vm._v(" "+_vm._s(_vm._f("upperCase")(_vm.avatarText[0]))+" ")])])]}}],null,false,1075143343)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(_vm.tooltipText))])])],1)]:[_c('div',{staticClass:"avatar",style:({
                          'background-color':_vm.backgroundColor,
                          'color':_vm.color,
                          'border':`1.5px solid ${_vm.strokeColor}`,
                          'height':_vm.size == 'small' ? '24px' : '30px',
                          'width':_vm.size == 'small' ? '24px' : '30px',
                      })},[_vm._v(" "+_vm._s(_vm._f("upperCase")(_vm.avatarText[0]))+" ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }