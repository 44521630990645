<template>
    <router-link :to="globalNav===true?link.url:`${relativePath}${link.url}`" exact-path>
        <!-- {{  link.name }} / {{  showSearch }} -->
        <div @click="trackNavLinkClick(link)" v-if="link.name!='search' || (link.name=='search' && showSearch)" :class="link.name=='search' ? 'pulsating-border link-name':'link-name'">
            <i v-if="link.name==='home'" class="ri-home-line"></i>
            <i v-if="link.name==='projects'" class="ri-folder-line"></i>
            <i v-if="link.name==='calendar'" class="ri-calendar-event-line"></i>
            <i v-if="link.name==='settings'" class="ri-settings-2-line"></i>
            <i v-if="link.name==='search'" class="ri-search-line"></i>
            <span @click="trackCallsNudge" :class="glovalNav ? 'global-nav-link' : 'global-link'">
                {{link.name}}
                <Beacon v-if="showBeacon && link.name =='calls'"/>
            </span>
        </div>
    </router-link>
</template>

<script>
import Beacon from '../nudges/Beacon.vue'
import { SearchPageEvents } from '@/views/search/events';
import { auth } from '@/firebase';

export default {
    props: {
        link: {},
        globalNav:{},
        relativePath:{},
        showBeacon:{
            default: false
        },
        showSearch:{
            default: false
        }
    },
    components: {Beacon  },
    methods:{
        trackCallsNudge(){
            this.$emit('track-calls-nudge')
        },
        trackNavLinkClick(link){
            if(link.name == 'search'){
                console.log('Track search')
                window.analytics.track(SearchPageEvents.OPEN_SEARCH.event, {
                    email: auth.currentUser.email,
                })
            }

        }
    }
}
</script>

<style scoped>

    .global-link{
        display: flex;
        align-items: center;
    }
    .link-name{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        text-transform: capitalize;
        /* Neutrals/300 */
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 12px 16px;
        /* width: 96px; */
        height: 37px; 
        min-width: 86px;
        /* Primary/200 */
        /* background: #F6F8FE; */
        border-radius: 33px;
        margin-right: 12px;
        white-space: nowrap;

    }

    .link-name span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        text-transform: capitalize;
        /* Neutrals/300 */
        color: #666666; 
    }

    .link-name:hover {
        background: #F8F8F8;
    }

    .link-name i{
        margin-right: 5px;
    }

    .router-link-active .link-name,.router-link-active .link-name.link-name.gradient{
        background: #F6F8FE;
        border-radius: 33px;
        color: #5B7EDB !important;
    }

    .router-link-active .link-name span{
        color: #5B7EDB !important;
        font-weight: 600;
    }

    .router-link-active .link-name.link-name.gradient{
        -webkit-text-fill-color: rgba(125, 68, 219, 0.9);
    }

    .link-name.gradient{
        background:  linear-gradient(90deg, rgba(125, 68, 219, 0.9) -3.45%, rgba(91, 126, 219, 0.9) 99.14%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    /* For this screen size we'll hide the links name and only display it's icon to squeeze some space for recording UI */
    @media only screen and (min-width:800px) and (max-width: 1120px) {
        .global-nav-link{
            display: none;
        }

        .link-name{
            padding: 13px !important;
            width: 45px !important;
            
        }

        .link-name i{
            margin-right: 0px !important;
        }
    }

</style>