import axios from "axios"
import {getFunctionUrl} from '@/functions/functionsConfig'


const getRemainingTranscriptionDuration = async(workspaceId) => {
    // const url = "http://127.0.0.1:8000/get_transcription_duration"
    // BACKEND_API
    const url = getFunctionUrl('get_transcription_duration')

    const postParams = {"workspace_id": workspaceId}
    try{
        const response = await axios.post(url, postParams);
        return {status: "success", data: response}
    }
    catch(error){
        return {status: "error", data: error}

    }
}

export default {
    getRemainingTranscriptionDuration
}