<template>
  <div class="member-container"
  :style="{
    'border-bottom': showDividersBetweenMembers ? '1px solid #EDEDED' : 'none',
    'padding-bottom' :  showDividersBetweenMembers ? '12px' : '0'
    }"
  >
    <div class="avatar">
        <LoopColoredAvatar :avatarText="type=='member' ? member.name : member.inviteeEmail" size="medium" />
    </div>
    <div class="details">
        <span class="name">
            <template v-if="type=='member'">{{ member.name }}</template>
            <template v-else>{{ member.inviteeEmail }}</template>
        </span>
        <span v-if="type=='member'" class="email">{{ member.email }}</span>
    </div>
    <div class="action">
        <template v-if="type=='member'">
            <template v-if="owner">Workspace Owner</template>
            <template v-else-if="renderingForOwner && !owner ">
                <WorkspaceRoleMenu :disableWorkspaceRoleMenu="disableWorkspaceRoleMenu" :value="member.workspace_role" type="access-control" v-on:update-workspace-role="updateWorkspaceRole">
                    <template v-slot:remove-access-button>
                         <button  :disabled="removingAccess" @click="removeWorkspaceAccess" class="remove-access-btn">
                            <template v-if="removingAccess">Removing Access...</template>
                            <template v-else>Remove Access</template>
                        </button>
                    </template>
                </WorkspaceRoleMenu>
               
            </template>
            <template v-else-if="!renderingForOwner && !owner">
                <template v-if="member.workspace_role == 'view-only'">Workspace viewer</template>
                <template v-if="member.workspace_role == 'editor'">Workspace editor</template>
            </template>
        </template>
        <template v-else>
            {{  member.status }}    
        </template>
    </div>
    <Snackbar
        message="You removed this user from your workspace"
        :showSnackbar="showRemovedAccessSnackbar"
      />
  </div>
</template>

<script>
import WorkspaceAPI from '@/api/workspace/workspace';
import Snackbar from '@/components/notifications/Snackbar.vue';
import { auth } from '@/firebase';
import LoopColoredAvatar from '@/components/avatars/LoopColoredAvatar.vue';
import WorkspaceRoleMenu from '@/components/menus/WorkspaceRoleMenu.vue';

export default {
    components: {  Snackbar, LoopColoredAvatar, WorkspaceRoleMenu },
    props:{
        member:{},
        type:{
            default:'member'
        },
        owner:{},
        workspaceId:{},
        renderingForOwner:{
            default:false
        },
        disableWorkspaceRoleMenu:{
            deafault: false
        },
        showDividersBetweenMembers:{
            default: false
        }
    },
    data(){
        return{
            removingAccess:false,
            showRemovedAccessSnackbar: false,
            currentUser: auth.currentUser
        }
    },
    methods:{
        updateWorkspaceRole(newRole){
            this.$emit('update-workspace-role',newRole,this.member)
        },
        async removeWorkspaceAccess(){
            this.removingAccess=true;
            const removeAccessResponse = await WorkspaceAPI.acceptOrRejectWorkspaceInvite(this.workspaceId,this.member.id,'revoked')
            this.removingAccess = false
            this.showRemovedAccessSnackbar = true;
            setTimeout(()=>{
                this.showRemovedAccessSnackbar = false;
            },1500)
            this.$emit('removed-access')
        }
    }
}
</script>

<style scoped>
.member-container{
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.member-container .avatar{
    margin-right: 10px;
}

.member-container .details{
    flex: 1;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}

.member-container .details .name{
    color: var(--neutrals-600, #4C4C4C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
}

.member-container .details .email{
    color: var(--neutrals-400, #999);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
}

.member-container .action{
    color: var(--neutrals-300, #B2B2B2);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
}

.remove-access-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 10px 12px;
    border-top: 1px solid #EDEDED;
    /* height: 29px; */
    width: 100%;
    /* Accent/Red/Light */

    /* Accent/Red/Default */

    color: #C53030;
    outline: none;
}

.remove-access-btn:hover{
        background: #FFF5F5;

}

.remove-access-btn:disabled{
    opacity: 0.5;
}
</style>