<template>
    <v-row class="no-gutters start-recording-container">
        <div v-show="!recordingBot.status">
            <input id="call-recording-input" :class="`${noResponsive ? 'recording-input' : 'recording-input recording-input-responsive'}`" type="text" v-model="botInputURL" 
                placeholder="Enter a Zoom, Google Meet or MS Teams call link"
            />
        </div>
        <div v-show="!recordingBot.status">
            <v-tooltip bottom content-class="start-tooltip" nudge-top="4">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" :disabled="isLoadingBotRecordingDisabled"
                           v-show="!recordingBot.status" min-width="161" height="64" :class="`${noResponsive ? 'recording-btn' : 'recording-btn recording-btn-responsive'}`"
                           text @click="choseQuestionScript" @click.native="trackBotEvent('add')"
                    >
                        <v-progress-circular v-if="isLoadingBotRecordingDisabled" indeterminate
                            color="#5B7EDB" :size="25" :width="3" class="loginBtnLoader"
                        ></v-progress-circular>
                        <span
                            v-if="!isLoadingBotRecordingDisabled"
                            style="font-weight: 600 !important; color: #5B7EDB"
                            class="mb-0"
                          >
                            Start Recording
                        </span>
                    </v-btn>
                </template>
                <div class="d-flex">
                    <div style="align-self: center; padding-right: 8px">
                        <i class="ri-information-line" style="color: #b7791f"></i>
                    </div>
                    <div class="start-tooltip-content">
                          Looppanel’s Notetaker will join <br />
                          and start recording your <br />
                          call. You can take timestamped <br />
                          notes in Looppanel during the call.
                    </div>
                </div>
            </v-tooltip>
        </div>
        <!-- Important: removing style="display:flex" breaks button layouts during recording state -->
        <v-col class="text-right" v-show="recordingBot.status" cols="12" style="display:flex">
            <button @click="openNotetaking" class="open-notetaking-btn">
                Open Notetaker
            </button>
            <v-btn large :disabled="isLoadingBotRemovingDisabled" v-show="recordingBot.status"
                   color="rgba(219, 91, 91, 0.2)" elevation="0" class="remove-recorder-btn uppercase rounded-lg"
                   style="border-radius: 12px; min-width: 160px" @click="removeBotFromCall"
                    @click.native="trackBotEvent('remove')"
            >
                <v-progress-circular
                    v-if="isLoadingBotRemovingDisabled" indeterminate color="#c53030"
                        :size="25" :width="3" class="loginBtnLoader"
                ></v-progress-circular>
                      <span
                        v-if="!isLoadingBotRemovingDisabled"
                        style="color: #c53030; font-weight: 700"
                      >
                        <!-- Remove Notetaker From Call -->
                        Stop Recording
                      </span>
            </v-btn>
        </v-col>
        <SelectNotetakingGuide
            :showChoseQuestionScriptModal="showChoseQuestionScriptModal"
            :questionScripts="questionScripts"
            v-on:toggle-question-script-modal="toggleQuestionScriptModal"
            v-on:select-script="selectScript"
        />
         <!-- <LoopActionModal
              :showModal="showChoseQuestionScriptModal"
              title="Select a discussion guide"
              ctaEvent="select-script"
              ctaBtnColor="#5B7EDB"
              ctaText="start taking notes"
              v-on:toggle-modal="toggleQuestionScriptModal"
              v-on:select-script="selectScript"
          >
            <template v-slot:content>
              <div>
                <v-radio-group class="m-0" v-model="selectedScript">
                  <v-radio
                    v-for="(script,index) in questionScripts"
                    :key="script.id"
                    :value="script"
                    color="#5b7edb"
                    :class="index<questionScripts.length-1 ? 'mb-5' : 'mb-0'"
                  >
                    <template v-slot:label>
                        <div class="script-name-container">
                            <span class="question-script-name">{{script.name}}</span>
                        </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </template>
          </LoopActionModal> -->

        <LoopActionModal
            :showModal="showNotetakingDemoModal"
            :showHighlightedInfo="true"
            :isLongTitle="true"
            :hideCloseButton="true"
            v-on:toggle-modal="showNotetakingDemoModal=!showNotetakingDemoModal"
            title="🎥  Record an ongoing call on Zoom, Google Meet or MS Teams"
            ctaEvent="close-modal"
            ctaBtnColor="#5b7edb"
            ctaText="Got it"
            :gifContainerHeight="270"
        >
            <template v-slot:highlightedInfo-gif>
                <figure>
                    <img 
                    style="border-top-left-radius:16px; border-top-right-radius:16px;"
                    src="https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/static%2FGif-8MB.gif?alt=media&token=b7cf4d09-16d8-4b2f-9ebe-248e159713a5"/>
                </figure>
            </template>
            <template v-slot:content>
                <p class="empty-call-text">
                    Simply enter a call link and Looppanel's notetaker will record your call. You can also take notes on Looppanel during the call.
                </p>
            </template>
      </LoopActionModal>
      <LoopActionModal
        :showModal="showOngoingZoomCallModal && clickedStartRecordingFirstTime"
        :showCtaHelper="true"
        title="Is the link for an Ongoing call?"
        ctaEvent="start-recording"
        v-on:start-recording="startRecording"
        v-on:toggle-modal="toggleOngoingZoomCallModal" 
        ctaBtnColor="#5b7edb"
        ctaText="Start recording"
      >
        <template v-slot:content>
          <p class="ongoing-call-text">
            Loopppanel recorder <span>can only record ongoing calls.</span> 
            Make sure the <span>link you pasted is of an ongoing call.</span>
          </p>
        </template>
        <template v-slot:ctaHelper-template>
           <v-checkbox
              v-model="dontShowPopupAgain"
              dense
              class="m-0 p-0"
              style="font-size:12px"
              color="#5b7edb"
            >
              <template v-slot:label>
                <p class="mb-0 p-0 dont-show-label">
                  Don’t show this pop up again
                </p>
              </template>
            </v-checkbox>
        </template>
      </LoopActionModal>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import { auth } from '@/firebase';
import QuestionScriptsAPI from '@/api/question-script/question-scripts';
import sessionsAPI from '@/api/sessions/sessions'
import axios from 'axios';
import LoopActionModal from "../modals/LoopActionModal.vue";
import QuestionsPreviewMenu from '@/components/menus/QuestionsPreviewMenu.vue';
import Miscellaneous from '@/constants/miscellaneous';
import SelectNotetakingGuide from '../modals/SelectNotetakingGuide.vue';
import {getFunctionUrl} from '@/functions/functionsConfig'

export default {
    props:{
        noResponsive:{
            default:false
        }
    },
    components:{
        LoopActionModal,
        SelectNotetakingGuide
        // QuestionsPreviewMenu
    },
    data(){
        return{
            botInputURL: "",
            botRecordingStatus: false,
            isLoadingBotRecordingDisabled: false,
            isLoadingBotRemovingDisabled: false,
            showBotRecordingError:false,
            learnMorePage: false,
            showChoseQuestionScriptModal:false,
            questionScripts:null,
            selectedScript:null,
            clickedStartRecordingFirstTime:false,
            dontShowPopupAgain:true,
            showOngoingZoomCallModal:false,
            showNotetakingDemoModal:false

        }
    },
    computed: {
        ...mapState([
            "userProfile",
            // "transcripts",
            "zoomIntegration",
            "hasZoomSupport",
            "currentRecording",
            // "zoomRecordingsList",
            "recordingBot",
            "userWorkspace"
        ]),
    },
    mounted(){
        //this.checkOngoingCallPopupState()
        const resizeListener=window.addEventListener("resize", this.onResize);  
        
        //check & auto select demo call url when first time visit
        // var firstTimeVisit = localStorage.getItem("firstTimeVisit");
        // console.log("firstTimeVisit.. start recording",firstTimeVisit)
        // if(firstTimeVisit===null){
        //     this.botInputURL =  Miscellaneous.OnboardingZoomCallUrl
        // }
    },
    methods:{
        toggleOngoingZoomCallModal(){
            console.log('clicked toggle')
            this.showOngoingZoomCallModal=!this.showOngoingZoomCallModal;
            this.isLoadingBotRecordingDisabled=false;
        },
        onResize(event){
            // console.log('resized',window.innerWidth)
        },
        openNotetaking(){
            window.analytics.track(`clicked open notetaker for ongoing call from home view`, {
                      email: auth.currentUser.email,
              });

            window.open(`${window.origin}/notes/${this.userProfile.notetaking_room_id}`, '_blank').focus();  
        },
        trackBotEvent(type) {
            if (type == "add") {
                if (this.botInputURL.length > 0) {
                    window.analytics.track(
                    "add bot to call by pasting zoom link in sessions view",
                    {
                        email: auth.currentUser.email,
                    }
                    );
                } else {
                    window.analytics.track(
                    "click on start recording without providing input",
                    {
                        email: auth.currentUser.email,
                    }
                    );
                }
            } else if (type == "remove") {
                window.analytics.track("remove bot from sessions view", {
                email: auth.currentUser.email,
                });
            }
        },
        toggleQuestionScriptModal(){
            this.showChoseQuestionScriptModal=!this.showChoseQuestionScriptModal;
        },
        async startRecording(){
            if(this.dontShowPopupAgain) this.clickedStartRecordingFirstTime=false;
            this.getQuestionScripts()
        },
        async checkOngoingCallPopupState(){

            if(sessionStorage.showOngoingCallPopup){
                const showOngoingCallPopup=JSON.parse(sessionStorage.getItem('showOngoingCallPopup'));
                // console.log({showOngoingCallPopup});
                // this.clickedStartRecordingFirstTime=false;
                // this.dontShowPopupAgain=true;
            }else{
            const botSessions=await sessionsAPI.getAllBotRecordedSessions();
            // console.log({botSessions})
            if(botSessions.status==='success'){
                    if(botSessions.sessions.length>2){
                    //dont show the ongoing call popup
                    this.clickedStartRecordingFirstTime=false;
                    this.dontShowPopupAgain=true;
                    // sessionStorage.setItem('showOngoingCallPopup',false);
                    }else{
                    //show the ongoing call popup
                    this.clickedStartRecordingFirstTime=true;
                    this.dontShowPopupAgain=true;
                    }
                }
            }


        },
        async choseQuestionScript(){
            this.isLoadingBotRecordingDisabled=true;
            console.log('choseQuestionScript fired')
            if (this.botInputURL.length == 0) {
                console.log('empty input')
                this.showNotetakingDemoModal=true;
                this.isLoadingBotRecordingDisabled=false;
                throw new Error('Empty bot input error');
                // return;
            }
            if(this.clickedStartRecordingFirstTime){
                console.log('clicked start recording first time')
                this.showOngoingZoomCallModal=true;
            }
            else this.getQuestionScripts()
            

        },
        async getQuestionScripts(){
            const noneScript={id:'1',none:true,name:'None'}
            // console.log('getting qs')
            // this.questionScripts=await QuestionScriptsAPI.getScript_beta();
            const response=await QuestionScriptsAPI.getAllScripts(this.userWorkspace.id);
            this.questionScripts=[noneScript,...response.data];
            console.log('question scripts',this.questionScripts)
            this.isLoadingBotRecordingDisabled=false;
            if(this.questionScripts.length<=1){ 
                this.$store.commit("setSelectedScriptForNotetaking",noneScript);
                this.addBotToCall();
            }
            else this.showChoseQuestionScriptModal=true;
            // console.log('Open QS modal, select QS then invoke addBotToCall');
            //this.addBotToCall()

            // default selected script
            this.selectedScript = this.questionScripts[1]
        },
        selectScript(_selectedScript){
            this.selectedScript = _selectedScript;
            this.$store.commit("setSelectedScriptForNotetaking",_selectedScript);
            this.addBotToCall()
        },
        // Handles bot addition to a meeting
        addBotToCall() {
            if (this.botInputURL.length == 0) {
                return;
            }
            this.$store.commit("setCurrentRecording", {});
            this.isLoadingBotRecordingDisabled = true;
            let postParams={
                meeting_url: this.botInputURL,
                type: "ADD",
                user_id: auth.currentUser.uid,
                onboarding_tour_call: this.botInputURL == Miscellaneous.OnboardingZoomCallUrl ? true : false,
            }
            if(this.userProfile.bot_name) postParams['bot_name']=this.userProfile.bot_name;
            if(!this.selectedScript || this.selectedScript.none){
                // console.log('send nothing')
            }else{
                postParams['script_id']=this.selectedScript.id
            }
        console.log({postParams: postParams})
        // Call backend function from here with the URL
            var data = JSON.stringify(postParams);
            // BACKEND_API
            var config = {
                method: "post",
                url: getFunctionUrl('invite_bot'),
                headers: {
                "Content-Type": "application/json",
                },
                data: data,
            };
            
        axios(config)
            .then((response) => {
            // console.log('a',response.data.id);
            // console.log(JSON.stringify(response.data));
                // this.isLoadingBotRecordingDisabled = false;

            })
            .catch((error) => {
                this.isLoadingBotRecordingDisabled = false;
                console.log(error);
                this.$store.commit("setSelectedScriptForNotetaking", null);
                alert(
                    "Couldn't start recording, Please check the meeting link and try again"
                );
                // this.throwError(error);
            })
            .finally(() => {
                this.botInputURL = "";
            });
        },
        // Handles bot removal from a meeting
        removeBotFromCall() {
            this.isLoadingBotRemovingDisabled = true;
            // Call backend function to remove bot from call using meeting url
            var data = JSON.stringify({
                bot_id: this.recordingBot.id,
                type: "REMOVE",
            });

            // console.log("session storage value...",sessionStorage.getItem("onboardingComplete"))
            // if(sessionStorage.getItem("onboardingComplete")=="false"){
            // console.log("onboarding complete...",sessionStorage.getItem("onboardingComplete") )
            // window.analytics.track("completed product onboarding", {
            //     email: this.userEmail,
            // });
            // sessionStorage.setItem("onboardingComplete", "true");
            // }
            // BACKEND_API
            var config = {
                method: "post",
                url: getFunctionUrl('invite_bot'),
                headers: {
                "Content-Type": "application/json",
                },
                data: data,
            };
        // console.log({config})
        axios(config)
            .then((response) => {
                this.isLoadingBotRemovingDisabled=false;
                this.isLoadingBotRecordingDisabled = false;
                console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
            this.throwError(error);
            // console.log(error);
            // this.isLoadingBotRemovingDisabled = false;
            // console.log(error);
            // alert("Couldn't stop recording, Please try again or remove the notetaker from meeting")
            })
            .finally(() => {
            this.botInputURL = "";
            });
        },
    },
    watch:{
        recordingBot(_latest, old) {
            //this.checkCallStatusNew();
            const latest=_latest.statusName;
            console.log({latest})
            if (latest == "joining_call" || latest == "in_call_recording" || latest == "in_call_not_recording" || latest == "in_waiting_room") {
                this.isLoadingBotRecordingDisabled = false;
                //this.$router.push(`notes/${this.userProfile.notetaking_room_id}`);
            }else if(latest==("error")){
                this.isLoadingBotRecordingDisabled=false;
                this.isLoadingBotRemovingDisabled=false;
                // this.showBotRecordingError=true;
                // setTimeout(()=>{
                //   this.showBotRecordingError=false;
                //   this.$store.commit('setBotStatus',{ id:null, status:false, statusName:null});
                // },3000)
            }
        },
       
    }

}
</script>

<style scoped>
    .start-recording-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .open-notetaking-btn{
        color: white;
        background: #5B7EDB;
        border: 1px solid #EDEDED;
        padding: 8px 16px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        margin-right: 20px;
        height: 44px;
        width: auto;
        
    }
    .recording-input{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 0px 0px 16px;
        width: 360px;
        height: 64px;
        /* Neutrals/200 */
        border-left: 1px solid #EDEDED;
        /* Inside auto layout */
        /* flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0; */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #666666;
        padding-left: 1rem;

    }

    .recording-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #B2B2B2;
    }

    .recording-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #B2B2B2;
    }

     .recording-input::-ms-input-placeholder { /* Microsoft Edge */
        color: #B2B2B2;
    }

    .recording-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        border-radius: 0px;
        gap: 4px;
        width: 161px;
        height: 64px;
        /* Primary/500 */
        background: #F8F8F8;
        /* Inside auto layout */
        /* flex: none; */
        /* order: 1; */
        /* align-self: stretch; */
        /* flex-grow: 0; */
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        letter-spacing: -0.01em;
        text-transform: capitalize;
        /* Neutrals/White */
        border: 1px solid #EDEDED;
        border-bottom: none;
    }
    
    /* MEDIA QUERIES */
    /* 
        Various breakpoints for adjusting the width of the recording input and recording button CTA
        #TODO: Refactor these queries into simpler media queries
    */
    @media only screen and (min-width:1355) and (max-width: 1400px) {
        .recording-input-responsive{
            width: 370px;
        }
        .recording-btn-responsive{
            width:137px !important;
        }
    }

    @media only screen and (min-width:1200px) and (max-width: 1350px) {
        .recording-input-responsive{
            width: 300px;
        }
        .recording-btn-responsive{
            width:117px !important;
        }
    }

    @media only screen and (min-width:1181px) and (max-width: 1259px) {
        .recording-input-responsive{
            width: 250px;
        }
        .recording-btn-responsive{
            width:110px !important;
        }
    }

    @media only screen and (min-width:1121px) and (max-width: 1180px) {
        .recording-input-responsive{
            width: 220px;
        }
        .recording-btn-responsive{
            width:100px !important;
        }
    }

    @media only screen and (min-width:1061px) and (max-width: 1120px) {
        .recording-input-responsive{
            width: 200px;
        }
        .recording-btn-responsive{
            width:90px !important;
        }
    }

    @media only screen and (min-width:961px) and (max-width: 1060px) {
        .recording-input-responsive{
            width: 200px;
        }
        .recording-btn-responsive{
            width:90px !important;
        }
    }

    @media only screen and (min-width:891px) and (max-width: 960px) {
        .recording-input-responsive{
            width: 260px;
        }
        .recording-btn-responsive{
            width:110px !important;
        }
    }

    @media only screen and (min-width:800px) and (max-width: 890px) {
        .recording-input-responsive{
            width: 230px;
        }
        .recording-btn-responsive{
            width: 100px !important;
        }
    }

    @media only screen and (max-width: 799px) {
        .recording-input-responsive{
            width: 160px;
        }
        .recording-btn-responsive{
            width: 80px !important;
        }
    }

    /* --------------- End of Media Queries -------------- */

    .remove-recorder-btn{
        margin-right: 10px;
    }

    .start-tooltip {
        background-color: #fff3dc;
        border-radius: 8px;
        border: 1px solid #b7791f;
        opacity: 1 !important;
        padding: 8px;
    }

    .start-tooltip-content {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 150%;
        color: #b7791f;
        background-color: #fff3dc;
        padding-right: 8px;
    }

    .script-name-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 400px;
    }

    .script-name-container i{
        color: #D0D0D0;
    }

</style>