<template>
  <div>
    <ConfirmMoveCallModal
      v-if="showMoveCallConfirmation"
      :showMoveCallConfirmation="showMoveCallConfirmation"
      :movedFromDefault="moveFromProject.name && moveFromProject.name=='Unassigned Calls'"
      :project="moveToProject"
      v-on:toggle-move-call-confirmation="toggleMoveCallConfirmation"
      v-on:confirm-move-call="moveCall"
    />
   <div :key="projectId" v-if="projectId && projectOptions && defaultProject" class="session-project-container" @click.stop="handleProjectDropdownContainerClick">
        <template v-if="readOnly">
          <div class="regular session-project-selector" >
              <span class="project-list-dropdown">
                  {{projectIdToProjectNameMap[projectId]}}
              </span>
          </div>
        </template>
        <template v-else>
          <div v-if="moveCallInProgress && moveToProject" class="session-project-name" >
              <span>
                  {{ moveToProject.name}}
                  <!-- <v-progress-circular
                    style="height:10px;width:10px; margin-bottom:3px; margin-left:2px"
                    indeterminate
                    color="#5b7edb"
                  ></v-progress-circular> -->
              </span>
          </div>
          <div v-else-if="moveCallInProgress &&!moveToProject" class="session-project-name" >
              <span>
                  Moving...
                  <!-- <v-progress-circular
                    style="height:10px;width:10px; margin-bottom:3px; margin-left:2px"
                    indeterminate
                    color="#5b7edb"
                  ></v-progress-circular> -->
              </span>
          </div>
          <div v-else :class="projectId==defaultProject.id?'default session-project-selector':'regular session-project-selector'">
              <span v-click-outside="onClickOutside" @click.stop="handleProjectDropdownClick" :id="sessionId" class="project-list-dropdown">
                <template v-if="currentProject && currentProject==projectId">Move to</template> 
                <template v-else>
                  <template v-if="projectId"> {{projectIdToProjectNameMap[projectId]}}</template>
                  <!-- <template v-if="projectId"> {{projectId}}</template> -->
                  <template v-else>Move to Project</template>
                </template>
                  <i class="ri-arrow-down-s-line"></i>
              </span>
              <div v-if="projectDropdownOpened && projectDropdownOpened===sessionId && projectOptions.length>0" :class="`session-project-selector__main ${(projectId && projectId!==defaultProject.id)?' short':''}`">
                <h4>Move to project:</h4>
                <!-- <template v-if="projectId && projectId===defaultProject.id">
                  <div class="project-select-options">
                    <ul>
                      <li @click.stop="assignToProject(defaultProject)" >
                            {{defaultProject.name}}
                      </li>
                    </ul>
                  </div>
                </template> -->
                <template>
                    <div class="project-select-top">
                      <input 
                        @click.stop="()=>{}"
                        v-model="projectSearched"
                        placeholder="Search for a project" 
                        type="text" class="project-select-search"
                      />
                  </div>
                  <div class="project-select-options">
                    <div class="your-projects">
                      <span>Your projects</span>
                      <ul>
                          <li @click.stop="assignToProject(project)" v-for="project in searchedYourProjects" :key="project.id">
                            {{ project.emoji }}     
                            {{project.name | formatProjectName}} 
                          </li>
                      </ul>
                    </div>
                    <div class="team-projects">
                      <span>Team projects</span>
                      <ul>
                          <li @click.stop="assignToProject(project)" v-for="project in searchedTeamProjects" :key="project.id">
                            {{ project.emoji }}     
                            {{project.name | formatProjectName}} 
                          </li>
                      </ul>
                    </div>
  
                  </div>
                </template>

                </div>
          </div>
        </template>

        <Snackbar
          v-if="moveToProject"
          :message="`Your call was moved to ${moveToProject.name} successfully!`"
          :showSnackbar="showProjectMovedSnackbar"
          :snackbarColor="'#33B273'"    
        /> 

    </div>
  </div>
</template>

<script>
import projectsApi from '../../api/projects/projects';
import Snackbar from '../../components/notifications/Snackbar.vue';
import { auth } from "@/firebase";
import ConfirmMoveCallModal from '@/components/modals/ConfirmMoveCallModal.vue';


export default {
    components: { Snackbar, ConfirmMoveCallModal },
    name:'ProjectAssignment',
    props:{
        projectId:{
          required:true
        },
        sessionId:{
          required:true
        },
        allProjects:{
          required:true
        },
        projects:{

        },
        projectDropdownOpened:{},
        readOnly:{},
        moveCallInProgress:{},
        currentProject:{},
        renderedIn:{}
    },
    filters: {
        formatProjectName(val) {
          if (val.length < 20) {
                return val;
            }
            return `${val.substring(0, 20)}...`;
        },
    },
    data(){
        return{
            projectOptions:this.allProjects.projects,
            projectIdToProjectNameMap:{},
            showProjectOptions:false,
            projectSearched:'',
            defaultProject:null,
            showProjectMovedSnackbar:false,
            showLoader:false,
            currentUser: auth.currentUser.uid,
            showMoveCallConfirmation: false,
            moveToProject: null,
            moveFromProject: null
        }
    },
    computed: {
      searchedYourProjects(){
        return this.projects.filter(
                        project=>
                        !project.default && project.userId==this.currentUser && project.name.toLowerCase()
                              .includes(this.projectSearched.toLowerCase()
                  )
              )
      },
      searchedTeamProjects(){
        return this.projects.filter(
          project=>
          !project.default && project.userId!=this.currentUser && project.name.toLowerCase()
                              .includes(this.projectSearched.toLowerCase()
                  )
              )
      }
    },
    mounted(){
      this.getAllProjects()
    },
    methods:{
      handleProjectDropdownContainerClick(){
        console.log("handleProjectDropdownContainerClick")
      },
      onClickOutside(){
        // console.log('onClickOutside')
      },
      handleProjectDropdownClick(){
        if(this.moveCallInProgress) return;
        this.$emit('show-project-dropdown',this.sessionId);
        // this.showProjectOptions=!this.showProjectOptions
      },
      async getAllProjects(){
        // const response=await projectsApi.getAllProjectsWithoutLimit();
        const {projects}=this.allProjects; 
        projects.forEach(project=>this.projectIdToProjectNameMap[project.id]=project.emoji ? `${project.emoji} ${project.name}` : project.name)
        projects.forEach(project=>{
          if(project.default){
            this.defaultProject=project;
            return;
          }
        });
        this.projectOptions=projects.filter(project=>!project.default);
        
      },
      toggleMoveCallConfirmation(){
        this.showMoveCallConfirmation = !this.showMoveCallConfirmation;
        if(this.showMoveCallConfirmation==false)  this.$emit('hide-project-dropdown',this.sessionId);
      },
      async assignToProject(project){
        const originalProject={
          name: this.projectIdToProjectNameMap[this.projectId],
          id:this.projectId
        }
        const newProject={
          name:project.emoji ? `${project.emoji} ${project.name}` : project.name,
          id:project.id
        }
        this.moveToProject = newProject
        this.moveFromProject = originalProject
        console.log('moving call from project',originalProject, ' to ',newProject)
        this.showMoveCallConfirmation = true;
        
      }, 
      async moveCall(){
        this.$emit('hide-project-dropdown',this.sessionId);
        this.$emit('disable-session',this.sessionId);
        if(this.renderedIn == 'ProjectSessions') this.$emit('show-move-call-notification',this.moveToProject)
        this.showLoader=true;
        const response=await projectsApi.assignSessionToProject(
          this.sessionId,
          this.moveFromProject.id,
          this.moveFromProject.name,
          this.moveToProject.id,
          this.moveToProject.name,
        )
        if(response.status===200){
          console.log(response)
          const newProjectId=response.data.projectId;
          const data={
            projectId:newProjectId,
            sessionId:this.sessionId
          }
          this.$emit('enable-session',this.sessionId)
          this.showProjectMovedSnackbar=true;
          setTimeout(() => {
              this.closeNotification()
              this.$emit('refresh-page',JSON.stringify(data))
              this.showLoader=false;
              this.moveToProject = null
              this.moveFromProject = null
          },1500)
         
        }else{
          console.log(response)
        }
      },
      closeNotification(){
        this.showProjectMovedSnackbar=false;
      }

    },

}
</script>

<style scoped>
  .session-project-container{
  position: absolute;
  right: 8px; 
  z-index: 1;
  top: 8px;
}

.session-project-name{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;

  width: auto;
  height: 24px;

  background: #FFFFFF;
  /* Neutrals/200 */

  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.session-project-name span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;

  /* Primary/500 */

  color: #5B7EDB;
}

.session-project-selector.default{
  /* Auto layout */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    width: 142px;
    height: 24px;
    /* Primary/500 */
    background: #5B7EDB;
    color: #FFFFFF;
    border-radius: 8px;
    position:relative;
}

.session-project-selector.regular{
  /* Auto layout */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    /* width: 142px; */
    width:auto;
    height: 24px;
    /* Primary/500 */
    background: #FFFFFF;
    color: var(--Primary-500, #5B7EDB);

    border-radius: 36px;
    position:relative;
}

.project-list-dropdown{
    position: static;

    width:100%;
    padding: 0 3px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    color: inherit;
    display:flex;
    align-items: center;
    justify-content:space-between;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
}

.session-project-selector__main{
  cursor:auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 225px;
  /* max-height: 220px; */
  height:auto;
  background: #FFFFFF;
  /* Shadow- L1 */
  box-shadow: 1px 1px 10px rgba(102, 102, 102, 0.15);
  border-radius: 16px;
  position:absolute;
  right:0%;
  top:120%;
  overflow-x:hidden;
  /* overflow-y:scroll; */
}

.session-project-selector__main h4{
  color: var(--Neutrals-600, #4C4C4C);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
  text-transform: none;
  margin-top: 12px;
  margin-left: 16px;
  margin-bottom: 12px;
}

.session-project-selector__main.short{
  height: auto;
  overflow-y: hidden;
}

/* .project-select-top{
  height:45px;
  display: flex;
  align-items:center;
  width:100%;
  padding:16px;
} */


.project-select-top input{
  padding: 6px 12px;
  border-radius: 8px;
  border: 1.5px solid var(--Neutrals-200, #EDEDED);
  background: var(--Neutrals-White, #FFF);
  color: var(--Neutrals-400, #999);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  margin-left: 16px;

}

.project-select-options{
  background: #fff;
  overflow-y: scroll;
  max-height: 200px;
}

.project-select-options span{
  display: inline-block;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 10px;
  color: var(--Neutrals-400, #999);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 18px */
}

.project-select-options ul{
  padding-left:16px;
  margin:0;
}
.project-select-options li{
  cursor: pointer;
  list-style-type: none;
  color: var(--Neutrals-600, #4C4C4C);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
  /* padding:5px; */
  margin-bottom:12px;
}
</style>