<template>
  <button class="help-button" @click="openSupportArticle()">
      <i class="ri-question-line" style="color:#666666"></i>
      <span>Help</span>
  </button>
</template>

<script>

import { auth } from '@/firebase';

export default {
    data() {
        return{
            supportArticleLinks: {
                "Dashboard": "https://intercom.help/looppanel/en/",
                "Projects": "https://intercom.help/looppanel/en/articles/8185104-how-to-create-a-project",
                "calendar": "https://intercom.help/looppanel/en/articles/8129374-recording-user-interviews-taking-time-stamped-notes",
                "settings": "https://intercom.help/looppanel/en/",
                "Project Script": "https://intercom.help/looppanel/en/articles/8146331-how-to-add-a-discussion-guide-to-a-project-on-looppanel",
                "Project Sessions" : "https://intercom.help/looppanel/en/articles/8129374-recording-user-interviews-taking-time-stamped-notes",
                "Project Analysis" : "https://intercom.help/looppanel/en/collections/5526216-analyzing-creating-insights",
                "other" : "https://intercom.help/looppanel/en/"
            },
        }
    },

    methods:{
        openSupportArticle(){
            
            window.analytics.track(`clicked on help CTA from ${this.$route.name} view`, {
                email: auth.currentUser.email,
            });

            let currentPage = this.$route.name
            if(Object.keys(this.supportArticleLinks).includes(currentPage) === false) currentPage = "other"
            console.log("open support docs: ", currentPage,Object.keys(this.supportArticleLinks).includes(currentPage),Object.keys(this.supportArticleLinks),currentPage)
            window.open(`${this.supportArticleLinks[`${currentPage}`]}`,'_blank')
        }
    }
}
</script>

<style scoped>
.help-button{
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    text-transform: capitalize;
    /* Neutrals/300 */
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 12px 16px;
    /* width: 96px; */
    height: 37px; 
    min-width: 75px;
    /* Primary/200 */
    /* background: #F6F8FE; */
    border-radius: 33px;
    margin-right: 12px;
}


.help-button span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    text-transform: capitalize;
    /* Neutrals/300 */
    color: #666666;
}

.help-button:hover {
    background: #F8F8F8;
}

.help-button i{
    margin-right: 5px;
}



</style>