<!-- Custom upgrade plan tooltip, can be wrapped around a UI component -->

<template>
  <div class="tooltip-container">
    <span :style="{...this.style, opacity: showTooltip ?'0.5' : 1, display:'flex', alignItems:'flex-start', justifyContent:'flex-start'}">
          <slot/>
    </span>
    <template v-if="showTooltip">
        <div :class="type=='error' ? 'tooltip tooltip-error':'tooltip'">
            <p class="tooltip-text looppanel-typography-small">{{message}}</p>
            <button :class="type=='error' ? 'upgrade-cta upgrade-cta-error': 'upgrade-cta'" @click="toggleUpgradePlanModal">Upgrade</button>
        </div>
    </template>
  </div>
</template>

<script>
import { auth } from "@/firebase";

    export default {
        props:{
            type:{
              default:'error'
            },
            style: {},
            showTooltip: {
                default: false,
                type: Boolean
            },
            message: {
              default: "Your Free Trial has ended. Please upgrade to enable this action",
              type: String
            },
            renderedIn:{
              type:String
            }
        },
        methods: {
            toggleUpgradePlanModal(){
                window.analytics.track(`click on Upgrade from ${this.renderedIn}`, {
                    email: auth.currentUser.email,
                });
                this.$store.dispatch('toggleUpgradePlanModal',true)
            }
        }
    }
</script>

<style scoped>

.tooltip-container { 
  position: relative;
  display: inline-block;
  /* margin:100px; */
}

.tooltip-container:hover .tooltip{
  opacity: 1;

}

.tooltip { 
  text-align: center;
  padding: 5px 0;
  border-radius: 2px;
  width: 228px;
  height: auto;
  top: 100%;
  left: 50%;
  margin-left: -114px;
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  z-index: 1;
  background: #FAFBFE;
  border: 1px solid #D2DCFB;
  box-shadow: 0px 0px 8px 0px #66666626;
  border-radius: 8px;
  padding: 10px;
  text-align: left;
  margin-top: 3px;
}

.tooltip-error{
  background: #FFF5F5;
  border: 1px solid #C530301A;

}

.tooltip-text{
    text-align: left;
    color: var(--dark-grey-font-color);
}

.upgrade-cta {
    width: 75px;
    height: 26px;
    padding: 4px 12px 4px 12px;
    border-radius: 32px;
    background: #5B7EDB;
    color: #FFFFFF;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

.upgrade-cta-error{
  background: #C53030;
}

.text::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color:  transparent transparent #FFF5F5 transparent ;

}
</style>

