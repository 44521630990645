<template>
  <div class="beacon"></div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .beacon{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #5B7EDB;
        box-shadow: 0 0 0 #5f8bfaa6;
        animation: pulsing 2s infinite;
        transition: all 0.2s;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 8px;
    }
    .beacon:active {
    transform: scale(1.5);
    }

    @keyframes pulsing {
    from {
        box-shadow: 0 0 0 0 #5f8bfaa6;
    }
    70% {
        box-shadow: 0 0 0 6px #5f8bfa00;
    }
    to {
        box-shadow: 0 0 0 0 #5f8bfa00;
    }
    }

</style>