<template>
    <div>
        <v-dialog
            width="672"
            v-model="connectCalendarDialog"
            @click:outside="closeConnectCalendarDialog"
            content-class="connect-calendar-modal"
        >
            <!-- <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" width="230" class="modal-btn my-1 justify-start pl-0 text-capitalize" text color="#585858">
                        Upload Video or Audio
                </v-btn>
            </template> -->

            <v-card class="connect-calendar-modal__container" style="border-radius:16px">
                <v-card-title class="connect-calendar-title-container">
                    <h3>Connect your calendar to auto-record upcoming calls</h3>
                    <i class="ri-close-fill" @click="closeConnectCalendarDialog"></i>
                </v-card-title>
                <template v-if="calendarPageLoading">
                    <div class="calendar-loader">
                    <CalendarLoader
                        loadingMessage="Setting up your calendar"
                        :showDescription="true"
                        />
                    </div>
                </template>
                <template v-else>
                        <Auth
                            :showPermissionsGIF="true"
                            v-on:authenticated="calendarAuthenticated"
                            :fetchNewCalendarAuthToken="fetchNewCalendarAuthToken"
                            :calendarAuthToken="calendarAuthToken"
                            :message="''"
                            :showBorder="false"
                        />
                </template>
            </v-card>   
        </v-dialog>
    </div>
</template>

<script>
import {auth} from "../../firebase";
import Auth from "../../views/calendar/Auth.vue"
import CalendarAPI from '../../api/calendar/calendar';
import CalendarLoader from "../../views/calendar/CalendarLoader.vue"

export default {
    props: {
        connectCalendarDialog:{}, // To show popup
    },
    data() {
        return {
            //connectCalendarDialog: false,
            calendarAuthToken: null,
            fetchNewCalendarAuthToken: false,
            calendarPageLoading: false,
            isAuthenticated: false
        };
    },
     watch:{
        connectCalendarDialog:function(newValue, old){
        if(newValue == true){
            this.calendarPageLoading=true;
            this.getCalendarAuthToken()
            window.analytics.track(`open connect calendar modal from home view`, {
                email: auth.currentUser.email
            });
        } else {
            window.analytics.track(`close connect calendar modal from home view`, {
                email: auth.currentUser.email
            });
            }
        }
    },
    methods: {
        closeConnectCalendarDialog() {
            this.connectCalendarDialog = false;
            this.$emit("close-connect-calendar-dialog");
        },
        
        async getCalendarAuthToken(){
            try{
                const calendarAuthResponse=await CalendarAPI.getCalendarAuthToken(auth.currentUser.uid);
            
                if( calendarAuthResponse.data && 
                    calendarAuthResponse.data.status==='error' && 
                    calendarAuthResponse.data.type==='GET'
                ){
                    this.calendarPageLoading=false;
                    this.fetchNewCalendarAuthToken=true;
                    console.log('will fetch new calendar token')
                    const calendarAuthTokenResponse=await CalendarAPI.getNewCalendarAuthToken(auth.currentUser.uid);
                    this.calendarAuthToken=calendarAuthTokenResponse.data.token;
                    await this.getCalendarUser();
                }
                else{ 
                this.calendarAuthToken=calendarAuthResponse.data;
                await this.getCalendarUser();
                }
            }catch(error){
                console.log({error})
                this.calendarPageLoading=false;
            }
        },
        async getCalendarUser(){
            const calendarUserResponse=await CalendarAPI.getCalendarUser(this.calendarAuthToken)
            if(calendarUserResponse.status==='error'){
                console.log({calendarUserResponse})
                this.fetchNewCalendarAuthToken=true;
                if(calendarUserResponse.statusCode && calendarUserResponse.statusCode===403){
                console.log('will fetch new calendar token')
                const calendarAuthTokenResponse=await CalendarAPI.getNewCalendarAuthToken(auth.currentUser.uid);
                this.calendarAuthToken=calendarAuthTokenResponse.data.token;
                await this.getCalendarUser();
                }else{
                this.calendarPageLoading=false;
                }
                
            }else{
                const { calendarUser }= calendarUserResponse;
                if(calendarUser.data.connections[0].connected){
                //Already connected to google, fetch calendar meetings
                this.calendarPageLoading=false;
                this.calendarAuthenticated();
                this.calendarPreferences=calendarUser.data.preferences
                console.log(this.calendarPreferences)
                }else{
                this.calendarPageLoading=false;
                }
            }
     
        },
        calendarAuthenticated(){
            this.isAuthenticated =true;
        },
    },
    components: {Auth,CalendarLoader}
}
</script>

<style scoped>


    .connect-calendar-modal,.connect-calendar-modal__container{
        border-radius: 16px !important;
        margin: 0px;
      }
    .modal-btn{
        outline: none;
        cursor: pointer;
    }
    .connect-calendar-title-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EDEDED;
    }

    .connect-calendar-title-container h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #4C4C4C;
    }

    .connect-calendar-title-container i{
        color:#666666;
        cursor: pointer;
    }


</style>