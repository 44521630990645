<template>
  <LoopActionModal
    :showModal="showMoveCallConfirmation"
    :title="`Move this call to ${project.name}?`"
    v-on:skip="toggleMoveCallConfirmation"
    v-on:toggle-modal="toggleMoveCallConfirmation"
    :width="600"
    :showBorder="true"
    :isSmallTitle="true"
    :showCancelButton="true"
    :ctaBtnColor="'#5B7EDB'"
    :ctaBtnTextColor="'#FFF'"
    ctaText="Yes, Move call"
    ctaEvent="confirm-move-call"
    v-on:confirm-move-call="confirmMoveCall"
  >
    <template v-slot:content>
        <div>
            <p class="message mt-3">
              <template v-if="movedFromDefault">
                Once moved, any AI notes in this call will be rearranged according to the guide in the new project
              </template>
              <template v-else>
                Once moved, all existing notes in this call will be moved to the Unstructured Notes section.
              </template>
            </p>
        </div>
    </template>
  </LoopActionModal>
</template>

<script>
import LoopActionModal from './LoopActionModal.vue'
export default {
  components: { LoopActionModal },
  props:{
    movedFromDefault:{
      default: false
    },
    project:{},
    showMoveCallConfirmation:{}
  },
  methods:{
    toggleMoveCallConfirmation(){
        this.$emit('toggle-move-call-confirmation')
    },
    confirmMoveCall(){
      this.$emit('confirm-move-call')
    }

  }

}
</script>

<style scoped>
.message{
    color: var(--Neutrals-500, #666);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
}

.confirmMoveCall{

}

</style>