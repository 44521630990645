import axios from "axios"
import {getFunctionUrl} from '@/functions/functionsConfig'

const triggerTransactionalEmail = async(receiverEmail, emailType, continueUrl) => {
    // const URL="http://127.0.0.1:8000/trigger_transactional_emails";    
    // BACKEND_API
    const URL= getFunctionUrl('trigger_transactional_emails');

    const postParams={
        "email_type": emailType,
        "receiver_email": receiverEmail,
    }
    //only exists in case where emailType="verify_email" type email
    if(continueUrl !== ''){
        postParams['continue_url'] = continueUrl
    }
    try{
        const response=await axios.post(URL, postParams);
        //console.log(response);
        return { status: 'success', data: response}
    }catch(error){
        return { status: 'error', data: error }
    }
}

export default {
    triggerTransactionalEmail
}