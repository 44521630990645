var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.renderedIn == 'editor' ? 
    'ai-tags-loading-state-editor ai-tags-loading-state' :
        _vm.renderedIn == 'analysis' ? 'ai-tags-loading-state-analysis ai-tags-loading-state'
        : 'ai-tags-loading-state-projects ai-tags-loading-state'},[_c('img',{style:({
      'height': (_vm.renderedIn == 'editor' || _vm.renderedIn == 'analysis') ? '36px' : '18px',
      'width': (_vm.renderedIn == 'editor' || _vm.renderedIn == 'analysis') ? '36px' : '18px'
    }),attrs:{"src":require("../../../public/Animated-Sparkles1.gif")}}),_c('span',[_vm._v("Auto-tagging notes..")]),_c('p',[_vm._v(" This can take a few minutes. You won't be able to make changes to the project until notes are auto-tagged. ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }