<template>
      <v-snackbar
      :color="snackbarColor"
      content-class="snackbar"
      v-model="showSnackbar"
      :timeout="timeout"
      :rounded="rounded"
      @close="$emit('close-notification')"
    >
    <span class="snackbar-message">
      <!-- Show circular progress loader -->
      <v-progress-circular
        v-if="showProgressLoader"
        indeterminate
        color="white"
        class="mr-2"
        size="14"
        width="2"
        ></v-progress-circular>
      <img v-if="renderSuccessTick" src="../../../public/tick.svg" class="mr-2"/>
      {{message}}
    </span> 

      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="showRedirectCTA"
          color="white"
          class="ml-16"
          text
          dark
          v-bind="attrs"
          @click="emitCustomRedirectEvent"
        > 
        <span class="redirect-cta">
          {{redirectCTAMessage}}
        </span>
          <i class="ri-arrow-right-line"></i>
        </v-btn>
        <v-btn
          v-if="showCta"
          color="white"
          text
          dark
          v-bind="attrs"
          @click="$emit('close-notification')"
        >
          <i class="ri-close-fill"></i>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
    name:'Snackbar',
    props:{
        snackbarColor:{
          default:'#5b7edb'
        },
        message:{
            type:String,
        },
        showSnackbar:{
            type:Boolean,
            required:true
        },
        timeout:{
          default:1500
        },
        rounded:{
          default:'pill'
        },
        showCta:{
          default:true
        },
        renderSuccessTick:{
          default:false
        },
        showProgressLoader:{
          default: false
        },
        showRedirectCTA:{
          default: false
        },
        redirectCTAEventName:{
          type:String,
        },
        redirectCTAMessage:{
          default:'View'
        }
    },
    methods: {
      emitCustomRedirectEvent(){
        this.$emit(this.redirectCTAEventName);
      }
    }

}
</script>

<style scoped>
    .snackbar{
        border-radius: 12px !important;
    }
    .snackbar-message{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #FFFFFF;
      display: flex;
      align-items: center;
    }
    .redirect-cta{
      color: var(--Neutrals-White, #FFF);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      text-decoration-line: underline;
      text-transform: capitalize;
    }
</style>