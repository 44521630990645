<template>
    <v-menu content-class="questions-menu"  :close-on-click="true" :close-on-content-click="false"
        :open-on-hover="true"   :nudge-right="100"
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="script-option-container" v-on="on" v-bind="attrs" >
                <slot name="custom-activator" >

                </slot>
            </div>
        </template>
        <div class="preview-container">
            <h3 class="preview-title">Preview</h3>
            <p class="preview-question" :key="question.id" v-for="(question,ind) in script.questions">
                <span class="preview-question-index">{{ind+1}}</span>
                 {{question.text}}
            </p>
        </div>
    </v-menu>
</template>

<script>
export default {
    props:{
        script:{
            required: true,
        }
    },
    mounted(){
        console.log("QuestionsPreviewMenu mounted")
    }
}
</script>

<style>
.questions-menu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
    width: 340px;
    height: 246px;
    /* Neutrals/White */
    background: #FFFFFF;
    /* Neutrals/200 */
    margin-left: 310px !important;
    border: 0px solid #EDEDED;
    /* Shadow- L1 */

    box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.15);
    border-radius: 0px 16px 16px 0px !important;
}

.preview-container{
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.preview-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    text-transform: uppercase;

    /* Neutrals/400 */

    color: #999999;
    margin-bottom: 10px;
}

.preview-question{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */
    display: flex;
    align-items: baseline;
    /* Neutrals/500 */

    color: #666666;
    margin-bottom: 10px;
}

.preview-question-index{
    margin-right: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    display: flex;
    align-items: center;

    /* Neutrals/300 */

    color: #B2B2B2;
}
</style>