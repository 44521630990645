import CALENDAR_CONSTANTS from '../constants/calendar';
import format from "date-fns/format";


function buildGoogleOAuthUrl({
    state,
    clientId,
    redirectUri,
    scopes = CALENDAR_CONSTANTS.GOOGLE_OAUTH_PERMISSION_SCOPES,
  }){
    return `https://accounts.google.com/o/oauth2/v2/auth?scope=${scopes.join(
      " "
    )}&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&state=${JSON.stringify(
      state
    )}&redirect_uri=${redirectUri}&client_id=${clientId}`;
  }



function extractDateFromMeeting(start_time,end_time) {
  const startTime = new Date(start_time);
  const endTime = new Date(end_time);
  const startDate = startTime.getDate();
  const endDate = startTime.getDate();
  const startMonth = startTime.getMonth();
  const endMonth = startTime.getMonth();
  const formattedStartTime = format(startTime, "p");
  const formattedEndTime = format(endTime, "p");
  let dateString;
  if (startDate === endDate && startMonth === endMonth) {
      dateString = `${CALENDAR_CONSTANTS.months[startMonth]} ${startDate} • ${formattedStartTime} -> ${formattedEndTime}`;
  }
  else if (startDate != endDate && startMonth == endMonth) {
      //same month, diff date
  }
  else {
      //diff month
  }
  return dateString;
}

export default {
  buildGoogleOAuthUrl,
  extractDateFromMeeting
};
