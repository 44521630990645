<template>
    <LoopActionModal
        :showModal="showChoseQuestionScriptModal"
        title="Select a discussion guide"
        ctaEvent="select-script"
        ctaBtnColor="#5B7EDB"
        ctaText="start taking notes"
        v-on:toggle-modal="toggleQuestionScriptModal"
        v-on:select-script="selectScript"
    >
        <template v-slot:content>
            <div class="guide-list-container">
                <div class="guide-search-container mb-2">
                    <i class="ri-search-line"></i>
                    <input type="search" v-model="searchedGuide" placeholder="Search guides accross your workspace..." />
                </div>
                <v-radio-group class="m-0" v-model="selectedScript">
                  <v-radio
                    v-for="(script,index) in searchedGuides"
                    :key="script.id"
                    :value="script"
                    color="#5b7edb"
                    :class="index<questionScripts.length-1 ? 'mb-5' : 'mb-0'"
                  >
                    <template v-slot:label>
                        <div class="script-name-container">
                            <span class="question-script-name">{{script.name}}</span>
                        </div>
                    </template>
                  </v-radio>
                </v-radio-group>
            </div>
        </template>
    </LoopActionModal>
</template>

<script>
import LoopActionModal from '@/components/modals/LoopActionModal.vue';

export default {
    components:{
        LoopActionModal
    },
    props:{
        questionScripts:{},
        showChoseQuestionScriptModal:{}
    },
    data(){
        return{
            searchedGuide:'',
            selectedScript:null
        }
    },
    methods:{
        toggleQuestionScriptModal(){
            this.$emit('toggle-question-script-modal')
        },
        selectScript(){
            this.$emit('select-script',this.selectedScript)
        }        

    },
    computed:{
        searchedGuides(){
         return this.questionScripts.filter(
                script=>
                script.name.toLowerCase().includes(
                    this.searchedGuide.toLowerCase()
                )
        )
      }
    }

}
</script>

<style scoped>
.guide-list-container{
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.guide-search-container{
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 15px; */
    padding: 10px;
    border: 1px solid rgb(230, 230, 230);
}

.guide-search-container input{
    width: 95%;
}

</style>