export const SearchPageEvents ={
    'OPEN_SEARCH' :{
        event:'click on Search from navbar',
        action:'Clicks on the "Search" button from navbar '
    },
    'SEARCH_SUGGESTION':{
        event:'click on search suggestion from search view',
        action:'Clicks on one of the search suggestion from the empty state'
    },
    'FILTER_ONLY_TAG':{
        event:'apply tag filter from search view',
        action:'Search performed with one or more tag filters from the tag dropdown without a search query'
    },
    'FILTER_ONLY_PROJECT':{
        event:'apply project filter from search view',
        action:'Search performed with one or more project filters from the project dropdown without a search query'
    },
    'FILTER_ONLY_TAG_PROJECT':{
        event:'apply tag and project filter from search view',
        action:'Search performed with one or more project filters and one or more tag filters without a search query'
    },
    'SEARCH_AND_TAG_FILTER':{
        event:'search with tag filter from search view',
        action:'Search performed with one or more tag filters from the tag dropdown with a search query'
    },
    'SEARCH_AND_PROJECT_FILTER':{
        event:'search with project filter from search view',
        action:'Search performed with one or more project filters from the project dropdown with a search query'
    },
    'SEARCH_AND_PROJECT_TAG_FILTER':{
        event:'search with project and tag filters from search view',
        action:'Search performed with one or more tag filters and one or more project filters with a search query'
    },
    'SEARCH_NO_FILTER':{
        event:'search without filters from search view',
        action:'Search performed no filters with a search query'
    },
    'LOAD_MORE_RESULTS':{
        event:'load more search results from search view',
        action:'Scrolled down to load more search results'
    },
    'CLEAR_FILTER':{
        event:'clear filters from search view',
        action:'Click on “Clear filters” button'
    },
    'OPEN_NOTE_CARD':{
        event:'click on note card from search view',
        action:'Click on any note card to open the note popup'
    },
    'JUMP_TO_CALL':{
        event:'jump to call from search view',
        action:'Click on jump to call button on the note popup to open the note popup'
    },
    
}