<template>
  <div class="rename-workspace-container">
    <h3 class="mb-4">Name your workspace to get started</h3>
    <input class="mb-1" type="text" v-model="workspaceName"/>
    <div>
       <input type="checkbox" :checked="autoJoinWorkspace" v-model="autoJoinWorkspace" class="mr-2"/> 
       Let anyone with @{{ workspace.emailDomain | joinEmailDomains }} join
    </div>
  </div>
</template>

<script>
export default {
    props:{
        workspace:{
            default: {}
        }
    },
    data(){
        return{
            autoJoinWorkspace: this.workspace.autoJoin ? true : false,
            workspaceName: this.workspace.name
        }
    },
    filters: {
      joinEmailDomains(emailDomains) {
          let joinedEmailDomains = ""
          for(let i=0; i<emailDomains.length; i++){
            joinedEmailDomains += emailDomains[i]
            if(i<emailDomains.length-1) joinedEmailDomains += ", "
          }
                
        return joinedEmailDomains;
        },
    },

}
</script>

<style scoped>

.rename-workspace-container{
    padding: 5px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rename-workspace-container h3{
    color: var(--neutrals-600, #4C4C4C);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}
.rename-workspace-container input{
    display: flex;
    height: 45px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1.5px solid var(--neutrals-200, #EDEDED);
    color: var(--neutrals-500, #666);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
}

.rename-workspace-container div{
    display: flex;
    align-items: center;
    width: 100%;
}
</style>