<template>
  <div class="verify-email-container">
    <template v-if="loading">
        <img
          src="../../../public/loop-loader-transparent.gif"
          width="200"
          height="200"
          class="mx-auto"
        />
        <h3>Loading...</h3>
    </template>
    <template v-else>
        <div class="top mb-10">
            <img src="../../../public/verify-email.svg" />
        </div>
        <div class="content mb-10">
            <template v-if="emailVerified">
                <h3 class="mb-5">Your email is verified. ✅</h3>
            </template>
            <template v-else>
                <h3 class="mb-5">Please verify your email</h3>
                <p>We’ve sent a verification link to {{ currentUser.email }}</p>
                <div class="open-btns-container">
                    <button @click="openGmail" class="open-gmail-btn">
                        <img style="marginRight:5px" src="../../../public/gmail-icon.svg" />
                        Open Gmail
                    </button>
                    <button @click="openOutlook" class="open-gmail-btn">
                        <img style="marginRight:5px" src="../../../public/outlook-icon.svg" />
                        Open Outlook
                    </button>
                </div>
            </template>
        </div>
        <template v-if="!emailVerified">
            <div class="divider mt-5 mb-10">
                <div class="divider-rule">
                    <div></div>
                    <span>Or</span>
                    <div></div>
                </div>
            </div>
            <div class="cta">
                <GoogleSignIn v-on:google-signin-click="clickedOnGoogleSignIn"/>
            </div>
            <div v-if="showSkipButton" class="skip-btn-container">
                <button @click="skipEmailVerification" class="skip-btn">
                    Skip
                </button>
            </div>
            <div class="mt-10">
                <LinearProgressBar :currentStep="'1'" :noOfSteps="'3'" />
            </div>
        </template>
    </template>
  </div>
</template>

<script>
import { auth, usersCollection } from '@/firebase'
import GoogleSignIn from '../buttons/GoogleSignIn.vue'
import LinearProgressBar from '../progress-bar/LinearProgressBar.vue'
import { mapState } from 'vuex';

export default {
  data(){
    return{
        currentUser:auth.currentUser
    }
  },
  props:{
    emailVerified:{
        default: false
    },
    loading:{

    },
    showSkipButton:{
        default: false
    }
  },
  components: { GoogleSignIn, LinearProgressBar },
  computed:{
    ...mapState([
        "userWorkspace",
    ]),
  },
  mounted(){
    // MISSING WORKSPACE_ID (Exception users collection)
    // Detach listener
    this.unsubscribeUserDoc = usersCollection
        .doc(auth.currentUser.uid)
        .onSnapshot(async (snapshot)=>{
        console.log('snapshot: ' + snapshot)
        const data = snapshot.data()
        if(this.emailVerified != data.emailVerified){
            // Update parent state and remove emailVerified update from here
            if(data.emailVerified == true){
                // Store's emailVerified field is different from the one in db, update the one in store
                this.$store.dispatch("updateUserProfileEmailVerifiedStatus",data.emailVerified)
            }
            this.emailVerified = data.emailVerified
            this.$emit('redirect-to-homepage')
        }

      })
  },
  methods:{
    navigate(href, newTab=true) {
        var a = document.createElement('a');
        a.href = href;
        if (newTab) {
            a.setAttribute('target', '_blank');
        }
        a.click();
    },
    clickedOnGoogleSignIn(){
        this.$emit('google-signin-click')
    },
    skipEmailVerification(){
        window.analytics.track("click on skip button from verify email page", {
          email: auth.currentUser.email,
        });
        this.$emit('skip-email-verification')
    },
    openGmail(){
        this.navigate('https://mail.google.com/')
    },
    openOutlook(){
        this.navigate('https://outlook.office365.com/')
    }
  }

}
</script>

<style scoped>

.verify-email-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 100%;
}
.content h3{
    color: var(--neutrals-600, #4C4C4C);
    text-align: center;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 31.5px */
}

.content p{
    color: var(--neutrals-600, #4C4C4C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.divider{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.divider-rule{
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divider-rule div{
    width: 45%;
    height: 1px;
    background: #EDEDED;
}

.skip-btn{
    display: flex;
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 36px;
    border: 1px solid var(--neutrals-200, #EDEDED);
    color: var(--primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    text-transform: capitalize;
}

.open-btns-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: auto;
}
.open-gmail-btn{
    margin: 10px;
    width: 230px;
    display: flex;
    height: 37px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    background: var(--neutrals-100, #F8F8F8);
    color: var(--neutrals-600, #4C4C4C);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
}

@media (max-width: 575.98px){
    .content p{
        word-wrap: break-word;
        white-space: normal;
        width: 300px;
        margin: 0 auto;
        padding: 10px;
    }
}
</style>