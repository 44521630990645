<template>
  <LoopMainCard
    :showImage="false"
    :showCardHeader="false"
    :showCta="true"
    :customHeight="customHeight"
    :customWidth="customWidth"
    :showCustorPointerOnHover="false"
  >
    <template v-slot:top>
        <div class="callStatus_header mt-5 mb-0">
            <div class="callStatus__upcoming" v-if="callStatus==='upcoming'">
                <span>{{callStatusText}}</span>
            </div>
            <div class="callStatus__ongoing" v-else-if="callStatus==='ongoing'">
                <span class="circle-red"></span>
                 <span> {{callStatusText}}</span>
            </div>
            <div class="callStatus__error ml-5" v-else-if="callStatus==='error'">
                 <i class="ri-error-warning-line"></i> {{callStatusText}}
            </div>
            <div class="callStatus__waiting ml-3" v-else-if="callStatus==='waiting'">
                <i style="font-size:20px" class="ri-error-warning-line"></i> {{callStatusText}}
            </div>
        </div>
    </template>
    <template v-slot:info>
        <div class="callStatus__content pl-2 pr-2">
            <h3 v-if="title" class="callStatus_title mb-2">
                {{title}}
            </h3>
            <p v-if="subtitle" class="callStatus_subtitle mb-2">
                <i class="ri-time-line"></i>{{subtitle}}
            </p>
            <div class="callStatus_helper" v-if="callStatus==='upcoming'">
                <span>•</span>
                
                This call will be recorded by looppanel
            </div>
        </div>
       
    </template>

    <template v-slot:cta>
       

        <slot v-if="customCtaTemplate" name="custom-cta">

        </slot>
        <template v-else>
             <button @click="$emit(ctaEvent)" class="callStatus_cta" 
                :style="{
                    background:ctaBtnColor,
                    marginTop:renderedInside==='Calendar'?'10px':callStatus==='error'?'5px':'30px',
                    color:ctaTextColor,
                    width:renderedInside==='Calendar'?'564px':'306.67px'
                    }"
                >
                    <template v-if="showIconInCTA">
                        <i v-if="callStatus==='upcoming'" class="ri-share-line"></i>
                    </template>
                    {{ctaText}}
                    <template v-if="showIconInCTA">
                        <i v-if="renderedInside==='Dashboard' && (callStatus==='error' || callStatus==='ongoing')" class="ri-arrow-right-line" ></i>
                        <i v-if="renderedInside==='Calendar'" class="ri-external-link-line"></i>
                    </template>
             </button>
        </template>
    </template>

    

  </LoopMainCard>
</template>

<script>
import LoopMainCard from './LoopMainCard.vue';
import { auth } from '@/firebase';

export default {
    components:{
        LoopMainCard
    },
    props:{
        callStatus:{
            type:String,
            required:true,
        },
        callStatusText:{
            type:String,
            required:true,
        },
        title:{
            type:String,
        },
        subtitle:{
            type:String,
        },
        showIconInCTA:{
            type:Boolean
        },
        ctaEvent:{
            type:String
        },
        ctaText:{
            type:String
        },
         ctaBtnColor: {
            type:String,
            default:'#5B7EDB'
        },
        ctaTextColor: {
            type:String,
            default:'#FFF'
        },
        customCtaTemplate:{
            type:Boolean,
            default:false
        },
        customHeight:{
            default:269
        },
        customWidth:{
            default:null
        },
        renderedInside:{
            default:'Dashboard'
        }

    }

}
</script>

<style scoped>
    .callStatus_header{
        width: 100%;

    }

    .callStatus_header span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        text-transform: capitalize;
        color: #F0FFF4;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .callStatus__ongoing{
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 4px ;
        gap: 4px;

        width: 159px;
        height: 34px;

        /* Accent/Red/Light */

        background: #FFF5F5;
        border-radius: 12px;
        margin-left: 25px;
    }

    .circle-red{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        /* Accent/Red/Default */

        background: #C53030;
    }
    .callStatus__upcoming{
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px 4px 4px;
        gap: 4px;

        width: 98px;
        height: 26px;

        /* Accent/Green/Light */

        background: #F0FFF4;
        border-radius: 8px;
    }

    .callStatus__ongoing span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */

        text-transform: uppercase;

        /* Accent/Red/Default */

        color: #C53030;
    }

    .callStatus__upcoming span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */

        text-transform: capitalize;

        /* Accent/Green/Default */

        color: #33B373;

    }

    .callStatus__error{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;

        width: 306.67px;
        height: 52px;

        /* Accent/Red/Default */

        background: #C53030;
        border-radius: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 150%;
        /* or 18px */

        text-transform: uppercase;

        /* Accent/Red/Light */

        color: #FFF5F5;
    }

    .callStatus__waiting{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 16px;
        gap: 8px;

        width: 330px;
        height: 130px;

        /* Accent/Yellow/Light */

        background: #FFF3DC;
        border-radius: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        text-align: center;

        /* Accent/Yellow/Default */

        color: #B7791F;
    }


    .callStatus__content{

    }

    .callStatus_title{
       font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        /* or 30px */


        /* Neutrals/600 */

        color: #4C4C4C;
    }

    .callStatus_subtitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        /* or 22px */

        display: flex;
        /* Neutrals/400 */

        color: #999999;
    }

    .callStatus_subtitle i{
        margin-right: 5px;
    }

    .callStatus_helper{
        display: flex;
        align-items: center;
    }
    .callStatus_helper,.callStatus_helper span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        text-transform: capitalize;

        /* Accent/Red/Default */

        color: #C53030;
    }

    .callStatus_helper span{
       font-size: 20px;
       margin-bottom: 2px;
       margin-right: 2px;
    }


    .callStatus_cta{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px 12px 12px;
        gap: 4px;

        /* width: 306.67px; */
        height: 45px;

        /* Primary/500 */

        /* background: #5B7EDB; */
        border-radius: 12px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        letter-spacing: -0.01em;
        text-transform: uppercase;
        border: 1px solid #EDEDED;


        /* Neutrals/White */

        /* color: #FFFFFF; */
    }

</style>