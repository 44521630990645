<template>
    <div class="text-center">
      <v-menu offset-y content-class="access-control-container" :disabled="(value=='view-only' && type=='link-control') || disableWorkspaceRoleMenu">
        <template v-slot:activator="{ on, attrs }">
          <button
            :class="type=='access-control' ? 'workspace-role-activator-btn access-control-btn':'workspace-role-activator-btn link-control-btn'"
            v-bind="attrs"
            v-on="on"
          >
          <template v-if="showTitle1">
            {{  roleTypeToLabelMapping[currentRole].title1 }}
         </template>
          <template v-else>
            {{  roleTypeToLabelMapping[currentRole].title2 }}
          </template>


            <template><i class="ri-arrow-down-s-line"></i></template>
        </button>
        </template>
            <div
                class="access-controls"
                v-for="(item, index) in items"
                :key="index"
            >
                <div @click="changeWorkspaceRole(item.type)" :class="currentRole == item.type ? 'access-control-selected':'access-control'">
                    <template v-if="currentRole == item.type"><i class="ri-check-fill"></i></template>
                    <template v-if="showTitle1">{{ item.title1 }}</template>
                    <template v-else>{{ item.title2 }}</template>
                    
                </div>
            </div>
            <slot name="remove-access-button">

            </slot>
      </v-menu>
    </div>
  </template>
<script>
import { auth } from '@/firebase'

export default {
    props:{
        // link-control or access-control
        type:{
            default:'access-control'
        },
        value:{
            required:true
        },
        showTitle1:{
            default: true
        },
        disableWorkspaceRoleMenu:{
            default: false
        }
    },

    data: () => ({
      currentRole:'',
      items: [
        { type:'view-only',title1: 'Workspace viewer',title2:'Can view workspace' },
        { type:'editor',title1: 'Workspace editor',title2:'Can edit workspace' },
      ],
      roleTypeToLabelMapping:{
        'view-only':{title1: 'Workspace viewer',title2: 'Can view workspace'},
        'editor':{title1: 'Workspace editor',title2: 'Can edit workspace'},
      }
    }),
    mounted(){
        this.currentRole = this.value
    },
    methods:{
        changeWorkspaceRole(newRole){
            this.currentRole = newRole
            this.$emit(this.type == 'access-control' ? 'update-workspace-role' : 'update-workspace-invite-link',newRole)
            if(this.type == 'access-control'){
                window.analytics.track(`Updated workspace member's role to ${newRole} from ${this.$route.name} view`, {
                    email: auth.currentUser.email
                });
            }else{
                window.analytics.track(`toggle workspace invite to ${newRole} from ${this.$route.name} view`, {
                    email: auth.currentUser.email
                });
            }
        }
    }
  }
</script>

<style scoped>
.workspace-role-activator-btn{
    color: var(--primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    
    height: 30px;
    outline-color: #fff;
    /* padding: 4px 16px; */
    padding: 4px 10px 4px 16px;
    align-items: center;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
}

.link-control-btn{
    width: 185px;
}

.access-control-btn{
    width: 170px;
}
.workspace-role-activator-btn i{
    color: #3E61C2;
    font-size: 20px;
}

.workspace-role-activator-btn:hover{
    background: #F6F8FE;
}

.access-control-container{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid var(--neutrals-200, #EDEDED);
    background: var(--neutrals-white, #FFF);
    /* Shadow- L1 */
    width: 198px;
    /* height: 78px; */
    box-shadow: 0px 0px 8px 0px rgba(102, 102, 102, 0.15);
}

.access-controls{
    width:100%;
    /* height: 41px; */
}

.access-control,.access-control-selected{
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: var(--neutrals-600, #4C4C4C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    height: 41px;
}


.access-control{
    padding-left: 20px;
}
.access-control-selected{
    /* font-weight: 600; */
    padding-left: 12px;
    color: #5B7EDB;
}

.access-control:hover,.access-control-selected:hover{
    background:#F6F8FE;
}
</style>