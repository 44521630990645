<template>
    <div class="data-search" :style="{'border': noBorder?'none':'1px solid rgb(230, 230, 230)'}">
        <v-autocomplete
            v-model="value"
            @change="handleChange"
            id="data-search"
            :items="data"
            :item-text="searchData==='sessions'?'fileName':'name'"
            :search-input.sync="search"
            class="data-search__autocomplete"
            color="#6666"
            dense
            append-icon=""
            prepend-inner-icon="mdi-magnify"
            rounded
            hiddenLabel
            :placeholder="customPlaceholder?customPlaceholder:'Search'"
            :allow-overflow="false"
            return-object
            
        >
            <template slot="item" slot-scope="{ item }">
                <div class="data-search-item" @click="handleClick(item)">
                    <p class='data-result mb-0' >{{searchData==='sessions'?item.fileName:item.name}}</p>
                    <i color="#5B7EDB" class="ri-external-link-line"></i>
                </div>
            </template>
        </v-autocomplete>
    </div>

</template>

<script>

import { auth } from "@/firebase";

export default {
    name:'DataSearch',
    props:{
        data:{
            required:true
        },
        searchData:{},
        noBorder:{
            default:false
        },
        customPlaceholder:{}
    },
    data(){
        return{ 
            value:null,
            select:'',
            search:'',
            event:''
        }
    },
    methods:{
        async handleClick(item){
            this.value=null
            // this.$router.push(`editor?transcriptId=${item.id}&projectId=${item.projectId}`)
            if(this.searchData==='sessions'){
                window.analytics.track(`search for and open call from home view`, {
                    email: auth.currentUser.email,
                });
                let routeData = this.$router.resolve({name: 'editor', query: {transcriptId:item.id, projectId:item.projectId}});
                window.open(routeData.href, '_blank');
            }else{
                //console.log('Open project',{item})
                window.analytics.track(`search for and open project from projects view`, {
                    email: auth.currentUser.email,
                });
                window.open(`/project/${item.id}/sessions`,'_blank');
            }

        },
        handleChange(){
            this.$nextTick(() => {
                this.value = null
            })
        },
        // checkElement(){
        //     const el=document.querySelector('.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content')
        //     console.log({el})
        // }

    },
    // watch:{
    //     '$store.state.isSidenavOpen': function() {
    //         const el=document.querySelector('.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content')
    //         console.log({el})
    //         if(this.$store.state.isSidenavOpen){
    //             el.style.left='241px !important'
    //         }else{
    //             console.log('fired')
    //             el.style.left='140px !important'
    //         }
    //     }
    // }

}
</script>

<style>
.data-search{
    width: 340px;
    height: 45px;
    border-radius: 40px;
    display:flex;
    align-items: center;
    /* padding: 0 15px; */
    padding-top:10px;
    justify-content: center;
    /* position:relative; */

}


.data-search i{
    /* margin-bottom: 4px !important; */
    /* margin-left: 20px;
    margin-right:10px; */
    margin-top:4px !important;
    /* font-size: 1px; */
    font-size: 20px !important;
    color: #666666 !important;
}

.data-search .v-input__prepend-inner{
    margin-left: 10px;
    margin-right:0px !important;
}

.data-search .v-input__control>div{
    padding-left: 0px !important;
}

.data-search.v-list-item{
    border-bottom: 1px solid #e6e6e6;
}

.v-select__slot{
    background-color: #fff !important;
}

.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content{
    max-height: 304px;
    min-width: 263px ;
    margin-top: 15px;
    /* top: 150px !important; */
    /* left: 241px !important;
    transform-origin: left top; */
    z-index: 10;
    position: absolute; 
    width: 340px !important;
    height: 234px !important;
    background: #FFFFFF !important;
    box-shadow: 1px 1px 10px rgba(102, 102, 102, 0.15) !important;
    border-radius: 16px !important;
}

.data-search-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10.5px 16px;
    border-bottom: 1px solid #e6e6e6;
}

.data-search-item i{
    color: #5B7EDB;
}
.data-search-item p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */
   

    /* Neutrals/500 */

    color: #666666;
}

.v-list-item.v-list-item--link.theme--light {
    /* border-bottom: 1px solid #e6e6e6; */
    padding: 0px;
}

</style>