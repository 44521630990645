<template>
  <div class="announcement-bar" :style="{background: this.color}">
      <slot></slot>
  </div>

  <!-- Usage example -->
  <!-- <AnnouncementBar>
  Take notes during user interviews effortlessly with Looppanel’s all new Chrome extension. 
  <a href="https://chrome.google.com/webstore/detail/looppanel-record-transcri/bbkpjihammbgidagedcmomadncfbofnb" target="_blank" rel="noopener noreferrer">Try the extension</a>
  </AnnouncementBar> -->
</template>

<script>
export default {
    props: {
    color: {},
  }
}
</script>

<style scoped>

.announcement-bar{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 8px;
  /* width: 1440px; */
  height: 32px;
  left: 0px;
  top: 79px;
  color: #fff;
  /* background: #222222; */
  background: var(--looppanel-primary-blue);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border-bottom: 1px solid #F8F8F8 ;
}
.announcement-bar a{
  text-decoration: underline;
  color: #F8F8F8;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
.announcement-bar h1{
  color: #F8F8F8;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
</style>