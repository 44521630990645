<template>
    <div class="unauthorized">
      <h1>Unauthorized Access</h1>
      <p>
        You do not have permission to view this page. You will be redirected to
        the home page in {{ counter }} second<span v-if="counter !== 1">s</span>.
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Unauthorized',
    data() {
      return {
        counter: 5, // Countdown starts from 5 seconds
      };
    },
    mounted() {
      this.startCountdown();
    },
    methods: {
      startCountdown() {
        const intervalId = setInterval(() => {
          if (this.counter > 1) {
            this.counter--;
          } else {
            clearInterval(intervalId);
            this.$router.push('/');
          }
        }, 1000);
      },
    },
  };
  </script>
  
  <style scoped>
  .unauthorized {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  