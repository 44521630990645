import store from "@/store/index.js";

function getWorkspaceIdForProjectQuery(projectId) {

    // Get your own workspace Id => Used for all regular queries
    const ownWorkspaceId = store.state.userWorkspace.id

    // Get project collaborator state to check if you're a collaborator on a project
    const projectCollaborator = store.state.projectCollaborator
    console.log('[getWorkspaceIdForProjectQuery]',projectCollaborator,ownWorkspaceId,projectId)

    // project collaborator state doesn't exist
    if(projectCollaborator==null){
        // Own workspace query
        return ownWorkspaceId;
    } 
    // project collaborator state exists
    else if(
        projectCollaborator!=null && 
            projectCollaborator['isCollaborator']==true && 
                projectCollaborator['projectId']==projectId
    ){
        // user is a collaborator on the given project => return resource workspace id to bypass security rules 
        return projectCollaborator['resourceWorkspaceId']
    }else{
        // in all other cases, use user's own workspace id
        return ownWorkspaceId
    }

}
  

export default getWorkspaceIdForProjectQuery;