<template>
  <div 
    :class="renderedIn == 'editor' ? 
      'ai-tags-loading-state-editor ai-tags-loading-state' :
          renderedIn == 'analysis' ? 'ai-tags-loading-state-analysis ai-tags-loading-state'
          : 'ai-tags-loading-state-projects ai-tags-loading-state'">
    <!-- <template v-if="renderedIn=='editor'">
      <v-progress-circular color="#5B7EDB" size="36" width="5.4" indeterminate/>
    </template>
    <template v-else>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M12.1667 4.19531C13.2885 4.19531 14.198 3.28589 14.198 2.16406H15.1355C15.1355 3.28589 16.0449 4.19531 17.1667 4.19531V5.13281C16.0449 5.13281 15.1355 6.04224 15.1355 7.16406H14.198C14.198 6.04224 13.2885 5.13281 12.1667 5.13281V4.19531ZM1.33337 9.66406C4.0948 9.66406 6.33337 7.42549 6.33337 4.66406H8.00004C8.00004 7.42549 10.2386 9.66406 13 9.66406V11.3307C10.2386 11.3307 8.00004 13.5693 8.00004 16.3307H6.33337C6.33337 13.5693 4.0948 11.3307 1.33337 11.3307V9.66406ZM4.56338 10.4974C5.65602 11.1037 6.5604 12.0081 7.16671 13.1007C7.77302 12.0081 8.6774 11.1037 9.77004 10.4974C8.6774 9.89106 7.77302 8.98673 7.16671 7.89407C6.5604 8.98673 5.65602 9.89106 4.56338 10.4974ZM14.875 12.1641C14.875 13.6598 13.6625 14.8724 12.1667 14.8724V16.1224C13.6625 16.1224 14.875 17.335 14.875 18.8307H16.125C16.125 17.335 17.3376 16.1224 18.8334 16.1224V14.8724C17.3376 14.8724 16.125 13.6598 16.125 12.1641H14.875Z" fill="#5B7EDB"/>
      </svg>
    </template> -->
    <img 
      :style="{
        'height': (renderedIn == 'editor' || renderedIn == 'analysis') ? '36px' : '18px',
        'width': (renderedIn == 'editor' || renderedIn == 'analysis') ? '36px' : '18px'
      }"
      src="../../../public/Animated-Sparkles1.gif"  />

    <span>Auto-tagging notes..</span>
    <p>
      This can take a few minutes. You won't be able to make changes to the project until notes are auto-tagged.
    </p>
  </div>
</template>

<script>
export default {
  props:{
    renderedIn:{
      default: 'projects'
    }
  }
}
</script>

<style scoped>

.ai-tags-loading-state-analysis{
  display: flex;
  width: 100%;
  padding: 36px 32px 28px 32px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  border: 1.5px solid var(--Primary-300, #E8EDFC);
  background: var(--Primary-200, #F6F8FE);
  margin-top: 0px;
  margin-bottom: 16px;
}
.ai-tags-loading-state-editor{
  display: flex;
  width: 100%;
  padding: 36px 32px 28px 32px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  border: 1.5px solid var(--Primary-300, #E8EDFC);
  background: var(--Primary-200, #F6F8FE);
  margin-top: 16px;
  /* Comment this to remove */
  position: sticky;
  top: 0;
  z-index: 10;
}
.ai-tags-loading-state-projects{
    position: fixed;
    top: 64px;
    left: 45px;
    border-radius: 0px 0px 12px 12px;
    border-right: 1.5px solid var(--Primary-300, #E8EDFC);
    border-bottom: 1.5px solid var(--Primary-300, #E8EDFC);
    border-left: 1.5px solid var(--Primary-300, #E8EDFC);
    background: var(--Primary-200, #F6F8FE);
    display: flex;
    flex-direction: row;
    width: 93.5%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.ai-tags-loading-state p{
  color: var(--Neutrals-500, #666);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  margin-bottom: 0;
}

.ai-tags-loading-state span{
  color: var(--Neutrals-600, #4C4C4C);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
}

</style>