import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import '@/assets/tailwind.css'
import '@/assets/scss/app.scss'
import vuetify from './plugins/vuetify'
import InstantSearch from 'vue-instantsearch';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { Replay } from "@sentry/browser";


// register jw pagination component globally
// @ts-ignore
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);

// register json-csv globally
// @ts-ignore
import JsonCSV from 'vue-json-csv';
Vue.component('downloadCsv', JsonCSV);

// register vue-select globally
// @ts-ignore
// import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";
// Vue.component("v-select2", vSelect);

// register vue-js-toggle-button globally
import { ToggleButton } from 'vue-js-toggle-button';
Vue.component('ToggleButton', ToggleButton);



Vue.config.productionTip = false

import VueMeta from 'vue-meta'

Vue.use(InstantSearch);
 
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
// , {
  // optional pluginOptions
  // refreshOnceOnNavigation: true
// })

// Workaround for [Vue warn]: The .native modifier for v-on is only valid on components but it was used on <span> </span>
// https://github.com/vuetifyjs/vuetify/issues/9999

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <span>.';
Vue.config.warnHandler = function (msg:any, vm:any, trace: any) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
}

Sentry.init({
  Vue,
  dsn: "https://752f40af588347ce8bf8e5b064a09310@o1342106.ingest.sentry.io/6618137",

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // @ts-ignore
  // replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,

  integrations: [
    //@ts-ignore
    // new Replay(),
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["*","localhost", "looppanel.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  debug:false,
  release:"0.44.0"
});

Sentry.setContext("user", {
  id: auth.currentUser?.uid,
  email: auth.currentUser?.email
})

let app: any
let logoutChannel:any = new BroadcastChannel('logout_channel') //A broadcast channel to communicate bw diff tabs
logoutChannel.onmessage=(even:any)=>{
  console.log(`Received event: `,event);
  // Programmatically invoking the refresh function
  window.location.reload()
}
// @ts-ignore
window.logoutChannel = logoutChannel
auth.onAuthStateChanged((user: any) => {
  if (!app) {
    app = new Vue({
      store,
      router,
      vuetify,
      created() {
        // Fetch user data on page load
        store.dispatch('fetchUserDataOnLoad')
      },
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    //For identifying users on Sentry
    Sentry.setUser({
      id: auth.currentUser?.uid,
      email: auth.currentUser?.email ? auth.currentUser.email : undefined
    })
  }
})