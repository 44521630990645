<template>
    <div v-if="transcriptionDuration != null">
        <div v-if="!subscriptionActive">
            <div @click="toggleUpgradePlanModal" class="plan-badge-container" :style='!trialEnded ? "background: #FAFBFE": "background: #FFF5F5"'>
                <v-progress-circular 
                    :value="!trialEnded ? Math.floor((trialDaysLeft/15)*100): 100" 
                    :color='!trialEnded ? "#5B7EDB" : "#C53030"'              
                    :size="20"
                    :width="3"
                    style="margin-left:10px"
                />
                <h1 v-if="!trialEnded" class="plan-text">{{trialDaysLeft}} days of Free Trial remaining</h1>
                <span v-if="trialEnded" style="display:flex; justify-content:center; align-items: center">
                    <h1 v-if="planEndDate == null" class="plan-text">Trial ended. Upgrade to a paid plan to continue making the most out of Looppanel</h1>
                    <h1 v-else class="plan-text">Subscription expired. Upgrade to continue making the most out of Looppanel</h1>
                    <button @click="toggleUpgradePlanModal" class="upgrade-button">Upgrade</button>
                </span>
            </div>
        </div>
        <div v-else>
            <div @click="toggleUpgradePlanModal" class="plan-badge-container" :style='transcriptionDuration.hours !== 0 ? "background: #FAFBFE": "background: #FFF5F5"'>
                
                <span  v-if="transcriptionDuration.hours !== 0" style="display:flex; justify-content:center; align-items:center">
                    <h1 class="plan-text">
                        <i class="ri-information-line" style="color:#5B7EDB; font-size:16px; font-weight:500"/>
                        <span v-if="transcriptionDuration.hours" class="plan-text" style="font-weight:700; margin-right: 3px; margin-left:5px">{{transcriptionDuration.hours}} hours</span> 
                        <span v-if="transcriptionDuration.minutes" class="plan-text" style="font-weight:700; margin-right: 3px; margin-left: 0px">{{transcriptionDuration.minutes}} mins</span> 

                        of transcription left {{this.planDuration == "monthly" ? "this month": ""}}
                    </h1>
                </span>

                <span v-if="transcriptionDuration.hours === 0" style="display:flex; justify-content:center; align-items:center">
                    <h1 v-if="transcriptionDuration.minutes >= 10" class="plan-text">
                        <i class="ri-information-line" style="color:#C53030; font-size:16px; font-weight:500"/>
                        <span class="plan-text" style="font-weight:700; margin-right: 3px; margin-left:5px">{{transcriptionDuration.minutes}} min</span> 
                        of transcription left {{this.planDuration == "monthly" ? "this month": ""}}
                    </h1>
                    <h1 v-else class="plan-text">
                        <i class="ri-information-line" style="color:#C53030; font-size:16px; font-weight:500; margin-right:5px"/>
                        You’re out of transcription hours {{this.planDuration == "monthly" ? "for this month": ""}}
                    </h1>
                    <button @click="toggleUpgradePlanModal" class="upgrade-button">Upgrade</button>
                </span>

            </div>
        </div>
    </div>
</template>


<script>
export default {
    computed: {
        trialEnded() {
            return this.$store.state.billingDetails.trialEnded;
        },
        trialDaysLeft(){
            return this.$store.state.billingDetails.trialDaysLeft
        },
        subscriptionActive(){
            return this.$store.state.billingDetails.subscriptionActive;
        },
        planDuration(){
            return this.$store.state.billingDetails.planDuration;
        },
        planEndDate(){
            return this.$store.state.billingDetails.planEndDate;
        },
        transcriptionDuration(){
            return this.$store.state.remainingTranscriptionDuration;
        },
        formattedHours(){
            let transcriptionDuration = this.$store.state.remainingTranscriptionDuration,
                hours = transcriptionDuration.hours,
                minutes = transcriptionDuration.minutes;
            hours = Number((hours + minutes/60).toFixed(1))
            return hours
        }
    },
    methods: {
        toggleUpgradePlanModal(){
            this.$store.dispatch('toggleUpgradePlanModal',true)
        }
    }
}
</script>

<style scoped>
    .plan-badge-container {
        position: fixed;
        bottom: 2%;
        left: 32px;
        z-index: 10;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #FAFBFE;
        border: 1px solid #E8EDFC;
        min-width: 262px;
        width: auto;
        height: 42px;
        padding: 12px, 16px, 10px, 14px;
        border-radius: 12px;
        box-shadow: 0px 0px 8px 0px #66666626;
        cursor: pointer;
    }

    .plan-badge-container:hover {
        background: #E8EDFC !important;
    }

    .plan-text {
        font-family: Inter;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        color: var(--dark-grey-font-color);
        margin-left: 10px;
        margin-right: 10px;
    }

    .upgrade-button {
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
        width:83px;
        height: 34pX;
        padding: 8px, 16px, 8px, 16px;
        border-radius: 32px;
        background: var(--looppanel-primary-red);
        margin-right: 5px;
    }
</style>

