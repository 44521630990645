<template>
    <div class="transcription-duration-card-conatiner" :style="transcriptionJustBelwoOneHour || noTranscriptionDuration ?'background: #FFF5F5':'background: #F8F8F8'" :class="upgradeButtonVisible ? 'space-between-content' : 'center-aligned-content'">

        <div v-if="noTranscriptionDuration">
            <h1 v-if="trialEnded" style="color:#C53030" >You’re out of transcription hours for this month<br/>
            New calls will be stored but not transcribed.</h1>    
            <h1 v-else style="color:#C53030" >You’re out of transcription hours<br/>
            New calls will be stored but not transcribed.</h1>             
        </div>   


        <div v-else style="display:flex; justify-content:center; align-items:center">
            <span v-if="transcriptionDuration.hours !== 0"  style="font-weight:700; color:#4C4C4C;">{{transcriptionDuration.hours}} hours</span> 
            <span v-if="transcriptionDuration.minutes !== 0" :style='transcriptionDuration.hours == 0 ? "color: #C53030": ""' style="font-weight:700; color:#4C4C4C; margin-left:5px">{{transcriptionDuration.minutes}} mins</span> 
            <span v-if="trialEnded" :style='transcriptionDuration.hours == 0 ? "color: #C53030": ""'> of transcription left this month.</span>
            <span v-if="!trialEnded" :style='transcriptionDuration.hours == 0 ? "color: #C53030": ""'> of transcription left in your trial.</span> 
            <br/>
            <h1 v-if="noTranscriptionDuration" style="color: #C53030">New calls will be stored but not transcribed.</h1> 
        </div>
        <button @click="toggleUpradePlanModal" v-if="upgradeButtonVisible" :style='transcriptionJustBelwoOneHour || noTranscriptionDuration ? "background: #C53030": ""'>Upgrade to get more hours</button>
    </div>
</template>

<script>

export default {
    props: {
        showUpgradeByDefault: {
            default: true
        }
    },
    computed: {
        trialEnded() {
            return this.$store.state.billingDetails.trialEnded;
        },
        transcriptionDuration(){
            return this.$store.state.remainingTranscriptionDuration;
        },
        noTranscriptionDuration(){
            let transcriptionDuration = this.$store.state.remainingTranscriptionDuration
            return transcriptionDuration.hours == 0 && transcriptionDuration.minutes < 10 
        },
        transcriptionJustBelwoOneHour(){
            let transcriptionDuration = this.$store.state.remainingTranscriptionDuration
            return transcriptionDuration.hours == 0 && transcriptionDuration.minutes >= 10 
        },
        upgradeButtonVisible(){
            if(this.transcriptionJustBelwoOneHour || this.noTranscriptionDuration) return true
            else if(this.showUpgradeByDefault) return true
            else return false
        },
    },
    methods: {
        toggleUpradePlanModal(){
            this.$store.dispatch('toggleUpgradePlanModal', true)
        }
    }
}

</script>


<style scoped>

.center-aligned-content {
    justify-content: space-around;
}

.space-between-content {
    justify-content: space-between;
}

.transcription-duration-card-conatiner {
    display: flex;    
    align-items: center;
    min-height: 46px;
    background: #F8F8F8;
    padding: 6px 8px;
    border-radius: 12px;
    position: relative;
    width: 100%;
    height: auto !important;
}
.transcription-duration-card-conatiner h1,
.transcription-duration-card-conatiner span {
    font-family: Inter;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    text-align: left;
    color: #4C4C4C;
    /* position: absolute;
    left: 20px; */
    height: auto;
    margin-left: 5px;
}
button {
    width: 202px;
    height: 34px;
    border-radius: 32px;
    background: var(--looppanel-primary-blue);
    color: #fff;
    padding: 8px 20px 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    /* position: absolute;
    right: 10px; */

}


</style>