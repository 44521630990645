<template>
    <div>
      <!-- Render the component only if the user has access and is not loading -->
      <slot v-if="hasAccess && !isLoading"></slot>
    </div>
  </template>
  
  <script>

  // imports
  import ProjectAPI from '@/api/projects/projects';
  import SessionAPI from '@/api/sessions/sessions';
  import { mapState } from "vuex";
  import {auth} from '@/firebase'

  // non-reactive data 
  const collaborationRoutes = [ 'Project', 'Projects', 'editor', 'Project Sessions', 'Project Script', 'Project Analysis']
  export default {
    name: 'AccessControlWrapper',
    data() {
      return {
        currentUser: auth.currentUser,    // Currently logged in user
        hasAccess: false,                 // Flag to check if the user has access to this page
        isLoading: true,                  // Flag to handle the loading state of this component 
        projectId: null,                  // Project Id of the project page user is visiting,
        transcriptId: null,               // Transcript Id of the call page user is visiting
        projectData: null,                // Project Data of the project page user is visiting
        sessionData: null,                // Transcript Data of the call page user is visiting
        isProjectOwner: false,            // Flag to indicate if currentlty logged in user is the project owner
        isWorkspaceMember: false,         // Flag to indicate if currentlty logged in user is a workspace member of the workspace project belongs to
        workspaceRole: null,              // if isWorkspaceMember == true, their role
        isProjectCollaborator: false,     // Flag to indicate if the currentlty logged in user is a collaborator on the project
        projectCollaborationRole: null,     // if isProjectCollaborationMember == true, their role
        resourceWorkspaceId: null,        // if isProjectCollaborationMember == true, this workspace_id is supposed to be used for all the queries
        invitationId:null,
      };
    },
    // Provide API allows a child component to access a parent component's data without passing them as props
    provide() {
        const accessControlData = {}

        Object.defineProperty(accessControlData, 
          "hasAccess", { enumerable: true, get: () => this.hasAccess, },
          "isProjectOwner", { enumerable: true, get: () => this.isProjectOwner, },
          "isWorkspaceMember", { enumerable: true, get: () => this.isWorkspaceMember, },
          "workspaceRole", { enumerable: true, get: () => this.workspaceRole, },
          "isProjectCollaborator", { enumerable: true, get: () => this.isProjectCollaborator, },
          "projectCollaborationRole", { enumerable: true, get: () => this.projectCollaborationRole, },
        )

        return {
          accessControlData,
        }
    },
    computed: {
      ...mapState([
        "userWorkspace",
        "userProfile"
      ]),
    },
    created() {
      this.isLoading = true;
      this.initializeState()
      this.checkAccess();
    },
    methods: {
      async checkInvitationStatus(){
        this.invitationId = this.$route.query.invitation;
        console.log("check invitation called ",this.invitationId,this.$route.query)
        //Invitation exists
        if(this.invitationId){
            console.log("invitation=>",this.invitationId)
            if(!auth.currentUser){
              const url = new URL(window.location.href)
              // console.log('Unauthenticated user accessing collaborative analysis page',url.pathname,this.$route)
              localStorage.setItem('redirect_url',JSON.stringify(this.$route.fullPath))
              this.$router.push('/')
            }else{
              // Make invitation accept API call here
              const acceptInvite = await ProjectAPI.acceptOrRejectSharedProjectInvite(this.invitationId,this.$route.params.projectId,'accepted')
              if(acceptInvite.status==='error') this.$router.push('/')
              window.analytics.track("accept collaboration invite from project calls page", {
                      email: auth.currentUser.email,
              });
              console.log({acceptInvite})
              if(acceptInvite.status=='success'){
                const queryParams = { ...this.$route.query };
                delete queryParams.invitation;
                this.$router.replace({ query: queryParams });
                this.invitationId=null;
                window.location.reload()
              }

            }
            

          }else{
            return
          }
      },
      initializeState(){
        this.hasAccess = false;
        this.isLoading = true;
        this.projectId = null;
        this.transcriptId = null;
        this.projectData = null;
        this.sessionData = null;
        this.isProjectOwner = false;
        this.isWorkspaceMember = false;
        this.workspaceRole = null;
        this.isProjectCollaborator = false;
        this.projectCollaborationRole = null;
      },
      // Handles state and redirection when someone doesnt have access to a collaborative page
      unauthorizeAccess(){
        this.isLoading = false;
        this.hasAccess = false;
        console.log("You don't have access to this page")
        this.$router.push('/')
      },
      // Handles state when someone doesnt have access to a collaborative page
      authorizeAccess(){
        this.isLoading = false;
        this.hasAccess = true;
        console.log("You have access to this page")
      },
      // Checks if the currently logged in user owns the call. Returns true/false
      async checkCallOwner(){
        console.log('[rbac] inside checkCallOwner ',this.transcriptId)
        const sessionResponse = await SessionAPI.getSessionDetailsByTranscriptId(this.transcriptId)
        console.log('[rbac] inside checkCallOwner ',sessionResponse)
        if(sessionResponse.status == 'success'){
          this.sessionData = sessionResponse.session
          console.log('[rbac] checking call ownership',this.sessionData,this.currentUser.uid)
          if(this.sessionData.userId == this.currentUser.uid)
            return true;
          else 
            return false
        }else{
          console.log('Error occurred while getting session details: ',sessionResponse)
          return false;
        }
      },
      // Checks if the currently logged in user owns the project. Returns true/false
      async checkProjectOwner(){
        try{
          const project = await ProjectAPI.getSingleProjectById(this.projectId)
          this.projectData = project;
          console.log('[rbac] project data: ', this.projectData)
          if(this.projectData.userId == this.currentUser.uid)
            return true;
          else 
            return false
        }catch(error){
          console.error('Error occurred while fetching project details ',error)
          return false
        }

      },
      // Checks if the currently logged in user is a workspace member, returns true/false
      checkWorkspaceMember(){
        console.log('[rbac] Checking if the currently logged in user is a workspace member ',this.sessionData)
        const projectWorkspaceId = this.currentPage == 'editor' ? this.sessionData['workspace_id'] : this.projectData['workspace_id']
        if(projectWorkspaceId == this.userWorkspace.id){
          this.isWorkspaceMember = true;
          this.workspaceRole = this.userWorkspace.role
          return true
        }else{
          return false
        }
      },
      // Checks if the currently logged in user is a project collaborator, returns true/false
      async checkProjectCollaborator(){
        const projectCollaborator = await ProjectAPI.validateCollaborator(this.projectId)
        if(projectCollaborator.status == 'success' && projectCollaborator.data.allowed){
          this.isProjectCollaborator = true
          this.projectCollaborationRole = projectCollaborator.data.data.access
          return true
        }else{
          return false
        }
      },
      async checkAccess(){
        if(this.currentPage=='Project Sessions' && this.$route.query.invitation){
          // console.log('[rbac] user is trying to access a shared project but hasnt signed up yet ',this.$route)
          if (!auth.currentUser) {
            localStorage.setItem('redirect_url',this.$route.fullPath)
            this.$router.push('/')
          } else {
            this.checkInvitationStatus()
          }
        }
        // Get the current page
        this.currentPage = this.$route.name
        console.log('[rbac] current page: ',this.currentPage)
        if(this.currentPage.includes('Project')){
          this.resourceType = 'projects'
          // For project pages, first get the project id
          this.projectId = this.$route.params.projectId
          this.resourceId = this.projectId
          console.log('[rbac] project id: ',this.projectId)
        }else if(this.currentPage == 'editor'){
          this.resourceType = 'transcripts'
          // For call page
          this.projectId = this.$route.query.projectId
          this.transcriptId = this.$route.query.transcriptId
          this.resourceId = this.transcriptId
          console.log('[rbac] transcript id: ',this.transcriptId)
        }
        const projectAccessControlResponse = await ProjectAPI.projectAccessControl(
          this.projectId,
          this.resourceType,
          this.resourceId
        )
        // console.log('[rbac] access control response: ',projectAccessControlResponse)
        this.projectAccessControl = projectAccessControlResponse.data
        if(this.projectAccessControl.resource_role == 'owner'){
          console.log('[rbac] access control : owner')
          this.isProjectOwner = true
          this.$store.dispatch("setProjectCollaborator", null);
          // User is the owner, user has access to the project
          this.authorizeAccess()
        }else if(this.projectAccessControl.resource_role == 'workspace_member'){
          console.log('[rbac] access control : workspace_member')
          this.isWorkspaceMember = true
          this.workspaceRole = (this.projectAccessControl.reads_allowed == true && this.projectAccessControl.writes_allowed == true) ? 'editor' : 'view-only'
          this.$store.dispatch("setProjectCollaborator", null);
          // User is workspace member
          this.authorizeAccess()  
        }else if(this.projectAccessControl.resource_role == 'collaborator'){
          console.log('[rbac] access control : collaborator')
          this.isProjectCollaborator = true
          this.projectCollaborationRole = (this.projectAccessControl.reads_allowed == true && this.projectAccessControl.writes_allowed == true) ? 'read-write-analysis' : 'read-all'
          this.resourceWorkspaceId = this.projectAccessControl.resource_workspace_id
          const projectCollaboratorData ={
            isCollaborator: true,      
            access: this.projectCollaborationRole,               
            projectId: this.projectId,            
            ownerUserId: null,          
            ownerUserName : null,       
            resourceWorkspaceId: this.resourceWorkspaceId,  
          }
          // Dispatch and store the data in the store
          this.$store.dispatch("setProjectCollaborator", projectCollaboratorData);
          
          // User is a collaborator
          this.authorizeAccess()
        }else{
          console.log('[rbac] access control : no access')
          this.$store.dispatch("setProjectCollaborator", null);
          // User is not a collaborator, redirect them to the homepage
          this.unauthorizeAccess()
        }
      },
      async checkAccessOld() {
        if(!auth.currentUser && this.currentPage=='Project Sessions' && this.$route.query.invitation){
          // console.log('[rbac] user is trying to access a shared project but hasnt signed up yet ',this.$route)
          localStorage.setItem('redirect_url',this.$route.fullPath)
          this.$router.push('/')
        }
        // Get the current page
        this.currentPage = this.$route.name
        console.log('[rbac] current page: ',this.currentPage)

        if(this.currentPage.includes('Project')){
          // For project pages, first get the project id
          this.projectId = this.$route.params.projectId
          console.log('[rbac] project id: ',this.projectId)

          //First check if the user is the owner of the project
          if(await this.checkProjectOwner()){
            console.log('[rbac] user is the project owner')
            // User is the owner, user has access to the project
            this.authorizeAccess()
          }else{
            // User is not the owner of the project, check for workspace member
            if(this.checkWorkspaceMember()){
              console.log('[rbac] user is a workspace member')
              // If user is a workspace member, check their role
              if(this.workspaceRole == 'view-only'){
                // Workspace member with role view-only
                this.authorizeAccess()
              }else{
                // Workspace member with role editor
                this.authorizeAccess()
              }
            }else{
              // User is not a workspace member, check if they're a collaborator
              if(await this.checkProjectCollaborator()){
                console.log('[rbac] user is a project collaborator')
                // User is a collaborator, check their role
                if(this.projectCollaborationRole == 'view-only'){
                  // User is a collaborator with role view-only
                  this.authorizeAccess()
                }else{
                  // User is a collaborator with role analysis-edit-access
                  this.authorizeAccess()
                }
              }else{
                // User is not a collaborator, redirect them to the homepage
                this.unauthorizeAccess()
              }

            }
          }
        }else if(this.currentPage == 'editor'){
        // For call page
        this.projectId = this.$route.query.projectId
        this.transcriptId = this.$route.query.transcriptId
        console.log('[rbac] transcript id: ',this.transcriptId)

          //First check if the user is the owner of the call
          if(await this.checkCallOwner()){
            console.log('[rbac] user is the call owner')
            // User is the owner, user has access to the call
            this.authorizeAccess()
          }else{
            // User is not the owner of the call, check for workspace member
            if(this.checkWorkspaceMember()){
              console.log('[rbac] user is a workspace member')
              // If user is a workspace member, check their role
              if(this.workspaceRole == 'view-only'){
                // Workspace member with role view-only
                this.authorizeAccess()
              }else{
                // Workspace member with role editor
                this.authorizeAccess()
              }
            }else{
              // User is not a workspace member, check if they're a collaborator
              if(await this.checkProjectCollaborator()){
                console.log('[rbac] user is a project collaborator')
                // User is a collaborator, check their role
                if(this.projectCollaborationRole == 'view-only'){
                  // User is a collaborator with role view-only
                  this.authorizeAccess()
                }else{
                  // User is a collaborator with role analysis-edit-access
                  this.authorizeAccess()
                }
              }else{
                // User is not a collaborator, redirect them to the homepage
                this.unauthorizeAccess()
              }
            }
          }

        }else{
          this.unauthorizeAccess()
        }
      },
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
          this.isLoading = true;
          this.initializeState()
          this.checkAccess();
        }
      }
    }
  };
  </script>
  