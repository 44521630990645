
import * as fb from '../firebase'
import axios from 'axios'
import {getFunctionUrl} from '@/functions/functionsConfig'

const getBillingDetails=async()=>{
    const userId = fb.auth.currentUser.uid
    // const URL="http://127.0.0.1:8000/get_subscription_details";
    // BACKEND_API
    const URL= getFunctionUrl('get_subscription_details_v2');
    const postParams={"user_id": userId}
    try{
        const response=await axios.post(URL, postParams);
        //console.log(response);
        return { status: 'success', data: response}
    }catch(error){
        return { status: 'error', data: error }
    }
}

export default {
    getBillingDetails,
}