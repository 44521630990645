import { render, staticRenderFns } from "./ZoomImport.vue?vue&type=template&id=5c956994&scoped=true"
import script from "./ZoomImport.vue?vue&type=script&lang=js"
export * from "./ZoomImport.vue?vue&type=script&lang=js"
import style0 from "./ZoomImport.vue?vue&type=style&index=0&id=5c956994&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c956994",
  null
  
)

export default component.exports