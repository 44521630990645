<template>
   <button  @click="googleSignIn" class="mx-auto mb-6 google-sign-in-btn">
          <img height="20" width="20" src="../../../public/google-logo.svg"/>
          Link Google Account To Verify
    </button>
</template>

<script>
export default {
    methods: {
        googleSignIn(){
            this.$store.dispatch("googleSignin");
            this.$emit('google-signin-click')
        },
    }
}
</script>

<style scoped>

.google-sign-in-btn{
  border: 1.5px solid #5B7EDB;
  background: #F6F8FE;
  display: flex;
  gap:8px;
  align-items: center;
  padding: 12px;
  border-radius: 44px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  width:360px;
  height: 45px;
  justify-content: center;
  color: #3C4043
}

</style>