<template>
<div>
  <v-menu v-if="workspace" offset-y content-class="workspace-menu-container" v-model="showWorkspaceMenu">
      <template v-slot:activator="{  attrs }">
        <button @click="handleWorkspaceMenuClick" v-bind="attrs"  class="workspace-menu-activator"
        >
        <template v-if="userWorkspace.name">{{ userWorkspace.name[0] }}</template>  
        <template v-else> W</template>
        </button>
      </template>
      <div>
        <div v-if="workspace">
            <template v-if="userWorkspace.name">Team {{ userWorkspace.name }}</template>
            <template v-else>Your Workspace</template>
            
        </div>
        <!-- <UpgradePlanTooltip 
            type="info"
            :showTooltip="disableInviteMembers" 
            :message="'To invite members to your workspace and collaborate, upgrade to the Teams Plan'"
        > -->
            <button @click="openInviteMembersModal" :disabled="disableInviteMembers"> 
                <i class="ri-group-line"></i>
                Invite Members
            </button>
        <!-- </UpgradePlanTooltip> -->
        <hr/>
        <button @click="openWorkspaceSettings">
            <i class="ri-settings-3-line"></i>
            Workspace Settings
        </button>
      </div>
    </v-menu>
    <LoopActionModal
        v-if="workspace"
        :showModal="showInviteMembersModal"
        :title="workspace.name ? `Invite members to Team ${workspace.name}` : 'Invite members to your workspace'"
        :showCta="false"
        v-on:skip="toggleInviteMembersModal"
        v-on:toggle-modal="toggleInviteMembersModal"
        :width="600"
        :showBorder="true"
        :isSmallTitle="true"
    >
        <template v-slot:content>
            <div class="invite-member-modal-container mb-3 mt-3">
                <p class="message">
                    Invite members to view and collaborate to your workspace. Store all your data in one place. Stay updated with your team’s work.
                </p>
                <InviteMember :workspaceId="workspace.id" :type="'minimal'" :showInvitations="true"/>

                <!-- <button @click="toggleInviteMembersModal" class="skip-btn">Skip</button> -->
            </div>
           
        </template>
    </LoopActionModal>
    <LoopActionModal
        :showModal="showWelcomeWorkspaceModal"
        :title="`Welcome to your team workspace 👯`"
        alignTitle="center"
        :hideCloseButton="true"
        :isPersistent="true"
        ctaEvent="rename"
        v-on:rename="renameWorkspaceAndMakeOwner"
        ctaBtnColor="#5B7EDB"
        ctaText="Create and join workspace"
        :smallCTA="true"
        v-on:skip="toggleWelcomeWorkspaceModal"
        v-on:toggle-modal="toggleWelcomeWorkspaceModal"
        :width="600"
    >
        <template v-slot:content>
            <div class="welcome-workspace-modal-container mb-5 p-4">
                <p class="message mt-2 mb-3">
                    Introducing team workspaces on Looppanel. Store all your data in <br/> one place. Stay updated with your team’s work.
                </p>

            </div>
            <div class="mt-3 workspace-rename-section">
                <RenameWorkspace ref="renameWorkspaceRef" :workspace="workspace"/>
            </div>
            
        </template>
    </LoopActionModal>
</div>
</template>

<script>
import WorkspaceAPI from '@/api/workspace/workspace';
import LoopActionModal from '../modals/LoopActionModal.vue';
import InviteMember from '@/views/workspace/InviteMember.vue';
import RenameWorkspace from '@/views/workspace/RenameWorkspace.vue';
import { auth } from '@/firebase';
import { mapState } from "vuex";
import UpgradePlanTooltip from '@/components/UpgradePlanTooltip.vue';

export default {
    components:{LoopActionModal, InviteMember, RenameWorkspace},
    data(){
        return{
            workspace:null,
            showInviteMembersModal: false,
            showWelcomeWorkspaceModal: false,
            showWorkspaceMenu: false
        }
    },
    mounted(){
        this.getWorkspace()
        
    },
    computed:{
        ...mapState([
            "userProfile",
            "userWorkspace",
            "subscriptionActive",
            "plan",
            "trialEnded"
        ]),
        trialEnded() {
            return this.$store.state.trialEnded;
        },
        subscriptionActive(){
            return this.$store.state.subscriptionActive;
        },
        plan(){
            return this.$store.state.plan;
        },
        disableInviteMembers(){
        const trialEnded = this.trialEnded;
        const subscriptionActive = this.subscriptionActive;
        const plan = this.plan;
        // User is on a trial
        if (trialEnded == false && subscriptionActive == false)
            return false

        // User's trial has expired
        if (trialEnded == true && subscriptionActive == false)
            return true

        // User is on an active subscription
        if (trialEnded == true && subscriptionActive == true){
            if (plan == "Solo") return true
            return false  
        }

        // User is on an expired subscription
        if (trialEnded == true && subscriptionActive == false)
            return true

        return false;
        },
    },
    methods:{
        openInviteMembersModal(){
            window.analytics.track('click on invite members from workspace menu', {
                email: auth.currentUser.email,
            });
            this.showInviteMembersModal=true
        },
        handleWorkspaceMenuClick(){
            
            window.analytics.track('click on workspace menu', {
                email: auth.currentUser.email,
            });
            if(this.$route.name == 'Dashboard'){
                this.showWorkspaceMenu = !this.showWorkspaceMenu
                 return;
            }
            console.log("handleWorkspaceMenuClick",this.$route)
            this.$router.push('/')
            
        },
        async renameWorkspaceAndMakeOwner(){
            const newName = this.$refs.renameWorkspaceRef.workspaceName;
            const newAutoJoinSetting = this.$refs.renameWorkspaceRef.autoJoinWorkspace
            console.log('new name',newName)
            console.log('new auto join',newAutoJoinSetting)
            const updatedWorkspace = await WorkspaceAPI.updateWorkspace(this.workspace.id,newName,newAutoJoinSetting)
            console.log('updated workspace',updatedWorkspace)
            this.showWelcomeWorkspaceModal = false;
        },
        toggleWelcomeWorkspaceModal(){
            return;
            // this.showWelcomeWorkspaceModal = !this.showWelcomeWorkspaceModal
        },
        toggleInviteMembersModal(){
            this.showInviteMembersModal = !this.showInviteMembersModal
        },
        async getWorkspace(){
            if (!this.userWorkspace.owner){
                console.log('no owner for this workspace yet')
                this.showWelcomeWorkspaceModal = true
            }
            this.workspace = this.userWorkspace
            // const workspaceResponse = await WorkspaceAPI.getUserWorkspace()
            // if(workspaceResponse.status == 'success'){
            //     this.workspace = workspaceResponse.data;
            //     this.$store.dispatch("setUserWorkspace", this.workspace)
            //     console.log('workspace owner',this.workspace.owner)
            //     if(!this.workspace.owner){
            //         console.log('no owner for this workspace yet')
            //         this.showWelcomeWorkspaceModal = true
            //     }
            // }
            
        },
        openWorkspaceSettings(){
            window.analytics.track('click on workspace settings from workspace menu', {
                email: auth.currentUser.email,
            });
            this.$router.push('/settings?tab=workspace')
        }
    }
}
</script>

<style scoped>
.workspace-menu-activator{
    display: flex;
    width: 36px;
    height: 36px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 2px solid var(--primary-300, #E8EDFC);
    background: var(--primary-100, #FAFBFE);
    margin-right: 12px;
    color: var(--primary-500, #5B7EDB);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    text-transform: capitalize;
    outline:none;
}

.workspace-menu-container{
    width: 240px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--neutrals-200, #EDEDED);
    background: var(--neutrals-white, #FFF);

    /* Shadow- L1 */
    box-shadow: 0px 0px 8px 0px rgba(102, 102, 102, 0.15);
}

.workspace-menu-container div{
    width: 100%;
    color: var(--neutrals-400, #999);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.workspace-menu-container hr{
    border-bottom: 1px solid var(--neutrals-200, #EDEDED);
    width: 100%;
    margin: 0;
    margin-top: 12px;
}

.workspace-menu-container button{
    color: var(--neutrals-600, #4C4C4C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    outline: none;
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.workspace-menu-container button>i{
    margin-right: 5px;
}

.invite-member-modal-container{
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
}

.welcome-workspace-modal-container{
    border-bottom: 1px solid #E8EDFC;
}
.message{
    color: var(--neutrals-500, #4c4c4c);
    /* text-align: center; */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 16px;
}


.skip-btn{
    color: var(--neutrals-400, #999);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    text-transform: capitalize;
    margin-top: 12px;
    margin-bottom: 12px;
}

button:disabled{
    opacity: 0.5;
}
</style>