import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import videoicon from '@/components/icons/videoicon.vue'
import settingsicon from '@/components/icons/settingsicon.vue'
import tagboardicon from '@/components/icons/tagboardicon.vue'
import highlightsicon from '@/components/icons/highlightsicon.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        loopone: '#5b7edb',
        looptwo: '#D2DCFB',
        loopthree: '#E8EDFC',
        loopfour: '#F6F8FE',
        loopfive: '#FAFBFE',
        loopsix: '#E8EDFC',
        loopdarkone: '#666666',
        loopdarktwo: '#4C4C4C',
        loopgrayone: '#999999',
        
      },
    },
  },
  icons: {
    values: {
      navVideo: {component:videoicon},
      navSettings: {component:settingsicon},
      navBoard: {component:tagboardicon},
      navHighlights: {component:highlightsicon},
    }
  },
});