<template>
           <v-menu
                v-model="showProjectsMenu"
                content-class="projects-menu"
                :close-on-click="true"
                :close-on-content-click="false"
                :nudge-width="100"
                :nudge-bottom="10"
                :nudge-left="30"
                :offset-y="true"
            >
               <template v-slot:activator="{ on, attrs }">
                    <div @click="clickedActivator" v-bind="attrs" v-on="on">
                        <slot name="custom-activator">
    
                        </slot>
                    </div>
                      
                     
                </template>

                 <div v-if="showProjectsMenu" class="projects-popup-container">

                      <div class="projects-popup-search">
                            <i class="ri-search-line"></i>
                             <input @keyup.enter="createNewProject(true)" v-model="projectSearched" placeholder="Search/Create a project" type="text"/>
                      </div>
                                   
                      
                                   
                    <div class="projects-popup-options">
                        <template v-if="!searchedProjects|| searchedProjects.length===0">
                            <button :disabled="createProjectInProgress" @click="createNewProject()" class="create-new-project-btn">
                                        + Create New Project "{{ projectSearched }}"
                             </button>
                        </template>
                            <template v-else>
                               <div
                                  @click="applyProject(project)"
                                   class="project-option-container" :key="project.id" v-for="project in searchedProjects" :value="project.name">
                                    <p class="project-option mb-0">{{project.name}}</p>
                                   
                               </div>
                            </template>

   
                    </div>
  
                </div>

       </v-menu>
</template>

<script>
import ProjectsAPI from '@/api/projects/projects'
import { mapState } from "vuex";

export default {
  components:{},
  props:{
   projects:{}

  },
  data(){
    return{
      projectSearched:'',
      showProjectsMenu:false,
      createProjectInProgress:false
    }
  },
  methods:{
    clickedActivator(){
      if(this.showProjectssMenu==true){
        setTimeout(()=>this.projectSearched='',1000)
      }
    },
    applyProject(project){
      // console.log('called applyProject',project)
      this.$emit('apply-project',project)
      this.projectSearched=''
      this.showProjectsMenu=false;
    },
    getRandomProjectEmoji() {
      const emojis = ['⚽️','🎉','✨','🧠','👋','✌️','👀','🎾','💻','👾','📱','💊','💡','🗝','💰']
      const randomIndex = Math.floor(Math.random() * emojis.length);
      return emojis[randomIndex];
    },
    async createNewProject(validate=false){
      this.createProjectInProgress=true;
      if(validate){
        if(this.searchedProjects && this.searchedProjects.length!==0)
        return;
      }
      // console.log('called createNewProject',this.projectSearched,this.getRandomProjectEmoji())
      const newProject=await ProjectsAPI.createNewProject(this.projectSearched,this.userProfile.workspace_id,this.userProfile.name,this.getRandomProjectEmoji());
      this.$emit('create-new-project',newProject)
      this.createProjectInProgress=false;
      this.applyProject(newProject);
    }
  },
  computed: {
    ...mapState([
      "userProfile",
    ]),
      searchedProjects(){
         return this.projects.filter(
                project=>
                project.name.toLowerCase().includes(
                    this.projectSearched.toLowerCase()
                )
        )
      }
},



}
</script>

<style scoped>
.projects-menu{
  box-shadow: none;
  min-width: 0px;
    background: #FFFFFF;
  /* Shadow- L1 */
  min-width: 0px !important;
  box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.15);
  border-radius: 12px;
  padding:0px !important;
  max-width: 229px !important;
}

.projects-popup-container{
  display:flex;
  flex-direction: column;
  align-items: center;
  /* width: 208px; */
  width:100%;
  height: 160px;
  overflow-y: scroll;
  background: #FFFFFF;
  /* Shadow- L1 */

  box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.15);
  border-radius: 12px;
}

.projects-popup-container::-webkit-scrollbar{
  display: none;
}

.projects-popup-search{
  border-bottom: 1px solid #ededed;
  padding:5px 12px;
  display:flex;
  flex-direction: row;
  width:100%;
}

.projects-popup-search i{
  color: #666666;
}

.projects-popup-search input{
  border:none;
  outline: none;
  margin-left: 10px;
}

.projects-popup-search input::placeholder{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;

  /* Neutrals/400 */

  color: #999999;
}
.projects-popup-options{
  display:flex;
  flex-direction: column;
  width:100%;
}

.projects-option-container{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.project-option-container{
  padding: 8px 16px;
  cursor: pointer;
}

.project-option-container:hover{
  background: #F8F8F8;
}

.projects-option-container i,.projects-option-chose i{
  color:#5B7EDB
}

.create-new-project-btn{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  text-align: left;
  outline:none;
  text-transform: capitalize;
  /* Primary/500 */
  color: #5B7EDB;
  margin:8px 8px;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.create-new-project-btn:hover{
  background: #F8F8F8;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.projects-helper{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    /* or 16px */

    text-transform: capitalize;

    /* Neutrals/400 */

    color: #999999;
}

.project-option{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-transform: capitalize;
    cursor:pointer;
    /* Neutrals/500 */

    color: #666666;
}

button:disabled{
  opacity: 0.5;
}

</style>