<template>
    <div class="plan-card-container" :style="plan.planName=='teams'?'border: 1.5px solid #5B7EDB':''">
        <p class="plan-name">{{plan.planName=='teams'? 'Pro': plan.planName}}</p>
        <p class="plan-description">{{plan.planDescription}}</p>
        <span class="plan-price-container">
            <p class="plan-price">{{plan.price}}</p>
            <span v-if="plan.planName == 'solo' && plan.duration == 'annual'" class="discount-badge">Save 10%</span>
            <span v-if="plan.planName == 'teams' && plan.duration == 'annual'" class="discount-badge">Save 7%</span>
        </span>
        <p v-if="plan.duration == 'monthly'" class="plan-description" style="margin-bottom: 0px">per month</p>
        <p v-else-if="plan.duration == 'annual'" class="plan-description" style="margin-bottom: 0px">per month</p>

        <!-- This isn't displayed but needed to maintain spacing-->
        <p v-else-if="plan.planName == 'enterprise'" class="plan-description" style="margin-bottom: 0px; opacity:0">per year</p>

        <span class="divider"></span>
        <p class="plan-features">{{plan.features}}</p>

        <span v-if="currentPlan == plan.planName && currentPlanDuration == plan.duration && trialEnded && subscriptionActive" style="display:flex; justify-content:center; align-items:center; width:100%">
            <i class="ri-checkbox-circle-line" style="color:#33B273; font-size:18px; margin-top:-10px; margin-right:5px"></i>
            <p class="plan-description">Current Plan</p>
        </span>   
        <span v-else>
        <button 
            v-if="plan.planName =='solo' || plan.planName =='teams'" 
            class="plan-upgrade-cta" :style="plan.planName=='teams'?'background:#5B7EDB; color: #FFFFFF':''"
            @click="openCheckout(plan.planId || '', plan.duration || '')"
        >
            Upgrade
        </button>
        <a v-else href="https://www.looppanel.com/demo-sign-up?upgrade-plan" target="_blank" class="plan-upgrade-cta">Contact Us</a>
        </span>

    </div>
</template>

<script>

const Paddle = window.Paddle;
import { auth } from "@/firebase";

export default {
    props:{
        plan: {}
    },
    computed:{
        currentPlan(){
            return this.$store.state.billingDetails.plan.toLowerCase();
        },
        trialEnded(){
            return this.$store.state.billingDetails.trialEnded;
        },
        subscriptionActive(){
            return this.$store.state.billingDetails.subscriptionActive;
        },
        currentPlanDuration(){
            if(this.$store.state.billingDetails.planDuration)
                return this.$store.state.billingDetails.planDuration.toLowerCase();
            else 
                return null
        }
    },
    data(){
        return{

        }
    },
    methods:{

        checkoutClosed(data) {
        //console.log(data);
        //console.log('Looppanel purchase was cancelled');
        this.$emit('open-checkout-cancelled-snackbar')
        },
        checkoutComplete(data) {
        //console.log(data);
        //console.log('Looppanel purchase was successful');
        this.$emit('open-checkout-success-snackbar')

        },
        openCheckout(plan_id, plan_duration){
            if(!plan_id) return;
            this.$store.dispatch('toggleUpgradePlanModal',false)
            
            //sandbox account
            Paddle.Checkout.open({ 
                product: plan_id,
                email: auth.currentUser.email,
                successCallback: (data)=>{this.checkoutComplete(data)},
                closeCallback: (data)=>{this.checkoutClosed(data)},
                customData: {user_id: auth.currentUser.uid, plan_duration: plan_duration}
            });

            //copied from codepen
            // Paddle.Checkout.open({ 
            //     product: 18285,
            //     successCallback: "checkoutComplete",
            //     closeCallback: "checkoutClosed"
            // });            
            
            // Looppanel product id
            // Paddle.Checkout.open({ 
            //     product: 857071,
            //     successCallback: "checkoutComplete",
            //     closeCallback: "checkoutClosed"
            // });
        },

    }
}
</script>


<style scoped>
.plan-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: flex-start !important;
    width: 300px;
    height: 440px;
    padding: 32px, 24px, 24px, 24px;
    border-radius: 16px;
    border: 1.5px solid #D2DCFB;
    background: #FFFFFF;
    padding: 24px;
    margin: 0px 8px;
}
.plan-name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: center;
    color: var(--looppanel-primary-blue);
    /* min-width: 85px; */
    /* width: auto; */
    height: 29px;
    padding: 4px 12px 4px 12px;
    border-radius: 42px;
    background: #E8EDFC;
    text-transform: uppercase;
    margin: 0px 0px 8px 0px;
}

.plan-description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4C4C4C;
    margin: 10px 0px 20px 0px;
}

.plan-price {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: left;
    color: #4C4C4C;
    margin-bottom: 0px;
}

.discount-badge {
    background: var(--success-green-color);
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: #FFFFFF;
    padding: 6px;
    border-radius: 8px;
    margin-left: 8px;
}

.plan-price-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider {
    width: 100%;
    border: 1px;
    color: #D2DCFB;
    border: 1px solid #D2DCFB;
    margin: 20px 0px 20px 0px
}

.plan-features {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4C4C4C;
    height: 100px;
    margin: 0px 0px 20px 0px;
}

.plan-upgrade-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    padding: 12px 16px 12px 16px;
    border-radius: 44px;
    border: 1px;
    background: #FFFFFF;
    border: 1px solid var(--looppanel-primary-blue);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: center;
    color: var(--looppanel-primary-blue);
    outline: none;
    cursor: pointer;
    font-style: normal;
    text-decoration: none;
    margin-bottom: 10px;
    margin-left: 0px;
}


</style>