import CALENDAR_CONSTANTS from '../../constants/calendar';
import axios from 'axios';
import * as Sentry from "@sentry/vue";
import { auth } from "@/firebase";

const getCalendarAuthToken = async (userId) => {
    const calendarAuthTokenResponse = await axios.post(CALENDAR_CONSTANTS.API_GetCalendarToken, { "user_id": userId, "type": "GET" })
    return calendarAuthTokenResponse;
    //return CALENDAR_CONSTANTS.dummy_auth_token;
}

const getNewCalendarAuthToken = async (userId) => {
    const calendarAuthTokenResponse = await axios.post(CALENDAR_CONSTANTS.API_GetCalendarToken, { "user_id": userId, "type": "NEW" })
    return calendarAuthTokenResponse;
    //return CALENDAR_CONSTANTS.dummy_auth_token;
}

const getCalendarUser = async (calendarAuthToken) => {
    try {
        const calendarUserResponse = await axios.get(CALENDAR_CONSTANTS.API_GetCalendarUser, {
            'headers': {
                'x-recallcalendarauthtoken': calendarAuthToken
            }
        })
        return { status: 'success', calendarUser: calendarUserResponse };
    } catch (error) {
        if (error.response.status == 403) {
            Sentry.setContext("user", {
                id: auth.currentUser.uid,
                email: auth.currentUser.email
            })

            Sentry.captureException(error);
            return {
                error: error,
                status: 'error',
                statusCode: error.response.status
            }
        }
    }

}

const getCalendarMeetings = async (calendarAuthToken) => {
    try {
        const calendarMeetingsResponse =  await axios.post(CALENDAR_CONSTANTS.API_GetCalendarMeetingsLoop, { "user_id": auth.currentUser.uid, "calendar_token": calendarAuthToken })
        return { status: 'success', calendarMeetings: calendarMeetingsResponse }
    } catch (error) {
        Sentry.setContext("user", {
            id: auth.currentUser.uid,
            email: auth.currentUser.email
        })

        Sentry.captureException(error);
        return {
            error: error,
            status: 'error'
        }
    }
}

const updateCalendarMeeting = async (calendarAuthToken, meetingId, newRecordingState) => {
    try {
        const updateCalendarMeetingsResponse = await axios({
            method: 'put',
            url: `${CALENDAR_CONSTANTS.API_UpdateCalendarMeeting}${meetingId}/`,
            data: {
                'override_should_record': newRecordingState

            },
            headers: {
                'x-recallcalendarauthtoken': calendarAuthToken
            },
        })
        return { status: 'success', updatedCalendarMeeting: updateCalendarMeetingsResponse }
    } catch (error) {
        Sentry.setContext("user", {
            id: auth.currentUser.uid,
            email: auth.currentUser.email
        })

        Sentry.captureException(error);

        return {
            error: error,
            status: 'error'
        }
    }
}

const refreshCalendarMeetings = async (calendarAuthToken) => {
    try {
        const body= { "user_id": auth.currentUser.uid, "calendar_token": calendarAuthToken }
        const refreshCalendarResponse =  await axios.post(CALENDAR_CONSTANTS.API_RefreshCalendarMeetingsLoop,body)
        // const refreshCalendarResponse = await axios({
        //     method: 'post',
        //     url: CALENDAR_CONSTANTS.API_RefreshCalendarMeetings,
        //     data: {},
        //     headers: {
        //         'x-recallcalendarauthtoken': calendarAuthToken
        //     },
        // })
        console.log(refreshCalendarResponse)
        return { status: 'success', refreshCalendarMeetings: refreshCalendarResponse }
    } catch (error) {
        Sentry.setContext("user", {
            id: auth.currentUser.uid,
            email: auth.currentUser.email
        })

        Sentry.captureException(error);
        return { status: 'error', error: error }
    }
}

const updateRecordingPreferences = async (calendarAuthToken, newRecordingState) => {
    try {
        const updateRecordingPreferencesResponse = await axios({
            method: 'put',
            url: CALENDAR_CONSTANTS.API_UpdateRecordingPreferences,
            data: {
                'preferences': {
                    'record_external': newRecordingState
                }
            },
            headers: {
                'x-recallcalendarauthtoken': calendarAuthToken
            },
        })
        return { status: 'success', updatedCalendarPreferences: updateRecordingPreferencesResponse }
    } catch (error) {
        Sentry.setContext("user", {
            id: auth.currentUser.uid,
            email: auth.currentUser.email
        })

        Sentry.captureException(error);
        console.log('Error from update recording preferences', error)
        return { status: 'error', error: error }
    }
}

const updateRecordingPreferencesBotName = async (calendarAuthToken, botName) => {
    try {
        const updateRecordingPreferencesResponse = await axios({
            method: 'put',
            url: CALENDAR_CONSTANTS.API_UpdateRecordingPreferences,
            data: {
                'preferences': {
                    'bot_name': botName
                }
            },
            headers: {
                'x-recallcalendarauthtoken': calendarAuthToken
            },
        })
        return { status: 'success', updatedCalendarPreferences: updateRecordingPreferencesResponse }
    } catch (error) {
        Sentry.setContext("user", {
            id: auth.currentUser.uid,
            email: auth.currentUser.email
        })

        Sentry.captureException(error);
        console.log('Error from update recording preferences bot name', error)
        return { status: 'error', error: error }
    }
}

const retrieveMeetingInformation = async (calendarAuthToken,meetingId) => {
    try {
        const body= { "user_id": auth.currentUser.uid, "calendar_token": calendarAuthToken, "meeting_id":meetingId }
        const retrieveMeetingInformationResponse =  await axios.post(CALENDAR_CONSTANTS.API_RetrieveMeetingInformationLoop,body)
        // const refreshCalendarResponse = await axios({
        //     method: 'post',
        //     url: CALENDAR_CONSTANTS.API_RefreshCalendarMeetings,
        //     data: {},
        //     headers: {
        //         'x-recallcalendarauthtoken': calendarAuthToken
        //     },
        // })
        console.log(retrieveMeetingInformationResponse)
        return { status: 'success', retrieveMeetingInformation: retrieveMeetingInformationResponse }
    } catch (error) {
        Sentry.setContext("user", {
            id: auth.currentUser.uid,
            email: auth.currentUser.email
        })

        Sentry.captureException(error);
        return { status: 'error', error: error }
    }
}
export default {
    getCalendarAuthToken,
    getNewCalendarAuthToken,
    getCalendarUser,
    getCalendarMeetings,
    updateCalendarMeeting,
    refreshCalendarMeetings,
    updateRecordingPreferences,
    retrieveMeetingInformation,
    updateRecordingPreferencesBotName
}