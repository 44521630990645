<template>
  <div class="calendarLoader-container">
      <div class="calendarLoader">
          <template v-if="showCircularLoader">
            <v-progress-circular
                :size="40"
                :width="5"
                color="#5b7edb"
                indeterminate
            ></v-progress-circular>
          </template>
          <template v-else>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/static%2Floop-animation.gif?alt=media&token=deddca1a-b6b8-4e68-a29a-5fd68af0ae1d"
                width="200"
                height="200"
                class="mx-auto"
                />
          </template>
         
        <h1 class="calendarLoader-title mt-0 mb-5">
            {{loadingMessage}}
        </h1>
        <p v-if="showDescription" class="calendarLoader-description">
            {{description}}
        </p>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        showCircularLoader:{
            type:Boolean,
            default:false,
        },
        loadingMessage:{
            type:String,
            default:'Fetching your meetings...'
        },
        showDescription:{
            type:Boolean,
            default:false
        },
        description:{
            type:String,
            default:'If your calls are not synced, you can press the refresh button to sync changes that you might have made in your schedule'
        }
    }

}
</script>

<style scoped>
    .calendarLoader-container{
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 70vh;
    }

    .calendarLoader{
        text-align: center;
        width: 636px;

    }

    .calendarLoader-title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        /* identical to box height, or 32px */
        text-align: center;

        /* Neutrals/600 */

        color: #4C4C4C;
    }

    .calendarLoader-description{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        /* or 27px */

        text-align: center;

        /* Neutrals/400 */

        color: #999999;

    }
</style>