<template>
    <v-dialog v-model="showZoomImportModal" width="600" @click:outside="dismissZoomImportModal">
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="modal-btn justify-start pl-0 text-capitalize" v-bind="attrs" v-on="on" width="230" text color="#585858">
                <v-avatar height="30px" width="30px" class="pr-1">
                    <img src="../../../public/zoom.svg" />
                </v-avatar>
                    Import from Zoom
            </v-btn>
        </template>
        
        <v-spacer></v-spacer>

        <v-card>
            <v-card-title class="text-h5 zoom-import-header">
                <div class="zoom-import-header__heading">
                    <template v-if="showUploadingLoader">
                        Importing Zoom calls..
                    </template>
                    <template v-else>
                        Choose Zoom calls to import
                    </template>
                    <v-btn v-if="zoomIntegration && !zoomRefreshInProgress" class="mx-4" color="#2D80E7" 
                        @click="refreshRecordingList" dark small
                    >
                        Refresh
                    </v-btn>
                </div>
                <i class="ri-close-fill" @click="dismissZoomImportModal"></i>
               
            </v-card-title>

            <v-card-text>
                <template v-if="!zoomIntegration">

                    <div
                        style="text-align: center" 
                    >
                        <v-btn
                            class="loopfive loopone--text elevation-1"
                            dark
                            rounded-xl
                            large
                            href="https://zoom.us/oauth/authorize?response_type=code&client_id=GzpMgXgBT7axOnNPbVTdTA&redirect_uri=https://app.looppanel.com/zoom_callback"
                        >
                            <v-avatar
                                height="30px"
                                width="30px"
                                class="pr-2"
                            >
                                <img src="../../../public/zoom.svg" />
                            </v-avatar>
                            <b>Connect Zoom account</b>
                        </v-btn>
                            <br />
                    </div>
                </template>
                <template v-else>
                <template v-if="showUploadingLoader==true && showSuccessUpload==false">
                    <div class="uploading-loader-container">
                        <!-- <img src="../../../public/loop-loader.svg"/> -->
                        <figure class="mx-auto">
                            <img
                            src="../../../public/Loop Logo.gif"
                            width="150"
                            height="150"
                            class="mx-auto"
                            />
                        </figure>
                        <p class="mt-5 mb-0">Hang in there, this will only take a few minutes</p>
                    </div>
                </template>
                <template v-else-if="showUploadingLoader==false && showSuccessUpload==true">
                    <div class="uploading-loader-container">
                        <img src="../../../public/success.svg"/>
                        <p class="mt-5 mb-0">Calls successfully imported!</p>
                    </div>
                </template>
                <template v-else>
                    <div style="text-align: center" v-if="zoomIntegration && !zoomRefreshInProgress">
                        <br />
                        <v-container>
                            <form id="recordingsListForm">
                                <v-row
                                    :class="selectedRecordings.map(r=>r.id).includes(recording.id)?'selected':''"
                                    @click="handleSelectRecording(recording)"
                                    v-for="(
                                        recording, index
                                        ) in zoomRecordingsList.slice(6*(page-1),6*(page-1)+6)"
                                        :key="index"
                                        justify="start"
                                >
                                    <div class="zoom-recording-container">
                                        <v-checkbox
                                            class="zoom-call-checkbox"
                                            :value="selectedRecordings.map(r=>r.id).includes(recording.id)"
                                            @click="handleSelectRecording(recording)"
                                            @change="handleSelectRecording(recording)"
                                            dense ripple="false" color="#5b7edb" hide-details
                                        ></v-checkbox>
                                        <div class="recording-data" @click.stop="handleSelectRecording(recording)">
                                            <p class="recording-name">
                                            {{recording["zoom_meeting_topic"]}}
                                            </p>
                                            <p class="recording-time">
                                                {{recording["recording_start_timestamp"] | formatTimestamp}}
                                            </p>
                                        </div>
                                        
                                    
                                    <!-- ({{recording["zoom_meeting_duration"]}}min) -->
                                    
                                    </div>
                                        <!-- -
                                    {{recording["recording_start_timestamp"] | formatTimestamp}} -->

                                    <!-- <v-icon class="mx-4" v-if="recording['processing_status'] ==='processing'" v color="#2D80E7">
                                        mdi-dots-horizontal
                                    </v-icon>
                                    <v-icon class="mx-4" v-if="recording['processing_status'] ==='processed'" v color="#2D80E7">
                                        mdi-check-bold
                                    </v-icon> -->

                                    <!-- <v-btn color="#2D80E7" class="mx-4" @click="triggerSessionCreationFromRecording(recording)"
                                        :disabled="recording['processing_status'] ==='processing' || recording['processing_status'] === 'processed' "
                                        dark icon small
                                    >
                                        <v-icon dark>
                                            mdi-cloud-download
                                        </v-icon>

                                    </v-btn> -->

                                </v-row>
                            </form>
                            <div class="recordings-pagination">
                                <p class="helper">{{zoomRecordingsList.length}} calls available</p>
                                <div v-if="zoomRecordingsList.length>=6" class="recordings-pagination-buttons">
                                    <button @click="paginate(-1)" class="mr-2"><i class="ri-arrow-left-s-line"></i></button>
                                    <button @click="paginate(1)"><i class="ri-arrow-right-s-line"></i></button>
                                </div>
                            </div>
                            <!-- <div class="project-assignment-container" v-if="selectedRecordings.length>0">
                                <p> assign calls to </p>
                                <ProjectAssignmentMenu
                                    :projects="allProjects.projects"
                                    v-on:apply-project="applyProject"
                                    v-on:create-new-project="createNewProject"
                                >
                                    <template v-slot:custom-activator>
                                        <div class="selected-project">
                                            {{selectedProject.name}}
                                            <i class="ri-arrow-down-s-line"></i>
                                        </div>
                                    </template>
                                </ProjectAssignmentMenu>
                            </div> -->
                        </v-container>
                    </div>

                    <div style="text-align: center" v-if="zoomIntegration && zoomRefreshInProgress">
                        <!-- <v-row class="my-5" justify="center">
                            <atom-spinner :size="60" :color="'#5b7edb'"/>
                        </v-row>
                        <p style="font-size: 16px">
                            <b>Refresh in progress</b>
                        </p> -->
                         <v-progress-circular
                            indeterminate
                            color="#5b7edb"
                            :size="25"
                            :width="3"
                          ></v-progress-circular>
                    </div>

                    <div style="text-align: center" v-if="!zoomIntegration">
                        <v-btn class="loopfive loopone--text elevation-1" dark rounded-xl large 
                            href="https://zoom.us/oauth/authorize?response_type=code&client_id=GzpMgXgBT7axOnNPbVTdTA&redirect_uri=https://app.looppanel.com/zoom_callback">
                            <v-avatar height="30px" width="30px" class="pr-2">
                                <img src="zoom.svg" />
                            </v-avatar>
                            <b>Connect Zoom account</b>
                        </v-btn>
                        <br />
                    </div>

                    <div class="zoom-import-actions mt-5 mb-2">
                        <template v-if="selectedRecordings && selectedRecordings.length > 0">
                            <button class="cancel-btn" @click="cancelFileUpload">
                                Cancel
                            </button>
                            <button @click="handleMediaFilesUpload" class="upload-btn">
                                Upload
                            </button>
                        </template>
                        <template v-else>
                            <button @click="dismissZoomImportModal" class="close-btn">
                                Close
                            </button>
                        </template>

                    </div>
            </template>
            </template>       
            </v-card-text>
        </v-card>
     </v-dialog>  
</template>

<script>
import format from "date-fns/format";
// import ProjectAssignmentMenu from "../menus/ProjectAssignmentMenu.vue";

export default {
    props: {
        zoomIntegration: {},
        zoomRefreshInProgress: {},
        zoomRecordingsList: {},
        allProjects:{},
        defaultProject:{}
    },
    data() {
        return {
            showZoomImportModal: false,
            selectedRecordings: [],
            selectedProject:this.defaultProject,
            page:1,
            showUploadingLoader:false,
            showSuccessUpload:false
        };
    },
    mounted(){
        // console.log('zoom import modal mounted')
        //this.refreshRecordingList();
    },
    methods: {
        paginate(direction){
            if(direction == -1){
                //back
                this.page=Math.max(this.page-1,1);
            }else{
                //forward
                this.page=Math.min(Math.ceil(this.zoomRecordingsList.length/6), this.page+1);
            }
        },
        cancelFileUpload(){
            this.dismissZoomImportModal()
        },
        handleMediaFilesUpload(){
            this.selectedRecordings.forEach((recording)=>{
                this.triggerSessionCreationFromRecording(recording);
            })
            // console.log('handleMediaFilesUpload')
        },
        refreshRecordingList() {
            // console.log("refresh recording list");
            this.$emit("refresh-recording-list");
        },
        triggerSessionCreationFromRecording(recording) {
            // console.log("triggerSessionCreationFromRecording");
            this.showUploadingLoader=true;
            this.$emit("trigger-session-creation-from-recording", recording);
        },
        dismissZoomImportModal() {
            this.selectedProject=this.defaultProject;
            this.page=1;
            this.selectedRecordings=[];
            this.showZoomImportModal = false;
        },
        handleCheckboxClick() {
            console.log("handleCheckboxClick");
        },
        handleSelectRecording(recording) {
            // console.log('handleSelectRecording',{recording:recording});
            if (this.selectedRecordings.map(r => r.id).includes(recording.id)) {
                this.selectedRecordings = this.selectedRecordings.filter(r => r.id !== recording.id);
            }
            else {
                this.selectedRecordings=[recording,...this.selectedRecordings]
            }
        },
        applyProject(project){
            this.selectedProject=project;
        },
        createNewProject(newProject){
            this.$emit('create-new-project',newProject)
        },
    },
    watch:{
        showZoomImportModal:function(newVal,oldVal){
            if(newVal!==oldVal && newVal==true){
                //this.refreshRecordingList();
            }
        },
        zoomRecordingsList:function (newVal,oldVal){
            if(newVal){
                if(this.showUploadingLoader){
                    let allRecordingsProcessed=true;
                    newVal.forEach(r=>{
                        if(r.processing_status==='processing'){
                             allRecordingsProcessed=false;
                             return;
                        }
                    })
                    if(allRecordingsProcessed){
                         this.showUploadingLoader=false;
                         this.showSuccessUpload=true;
                         setTimeout(()=>{
                            this.showSuccessUpload=false;
                            this.dismissZoomImportModal();
                         },1500)
                    }
                }
            }
        }
    },
    filters: {
        formatTimestamp(val) {
            let date = val.toDate();
            // return date.format("MMMM Do, hh:mm");
            return format(date, "MMMM do, hh:mm");
            // toString();
        },
    },
    components: { }
}
</script>

<style scoped>

.modal-btn{
        outline: none;
        cursor: pointer;
}
.zoom-import-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EDEDED;

}

.zoom-import-header__heading{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;

    /* Neutrals/600 */

    color: #4C4C4C;
}

.zoom-import-header i{
    color:#666666;
    cursor: pointer;
}

.close-btn{
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        height: 45px;
        /* Neutrals/200 */
        border: 1px solid #EDEDED;
        border-radius: 44px;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        letter-spacing: -0.01em;
        text-transform: capitalize;

        /* Neutrals/500 */
        width: 100%;
        color: #666666;
}

.zoom-recording-container{
    display: flex;
    align-items: center;
    border-top: 1px solid #EDEDED;
    border-left: 1px solid #EDEDED;
    border-right: 1px solid #EDEDED;
    border-radius: 8px;
    padding: 12px;
    width: 100%;

}

.selected{
    background: #FAFBFE;
}

form{
    margin:8px 15px;
}

.zoom-recording-container:last-child{
    border-bottom: 0.5px solid #EDEDED;
}

.zoom-recording-container p{
    margin-bottom: 0px;
    margin-top: 8px;
    cursor: pointer;
}

.uploading-loader-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 24px 20px;
    height: 244px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.uploading-loader-container p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */


    /* Neutrals/500 */

    color: #666666;

}

.zoom-call-checkbox{
    
}

.recording-data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.recording-data .recording-name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */


    /* Neutrals/500 */

    color: #666666;
}

.recording-data .recording-time{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-align: right;

    /* Neutrals/400 */

    color: #999999;

}

.recordings-pagination{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.recordings-pagination .helper{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
    margin-bottom: 0px;
    text-transform: uppercase;

    /* Neutrals/400 */

    color: #999999;
}

.recordings-pagination-buttons{
    display: flex;
    align-items: center;
}

.recordings-pagination-buttons button{
    width: 20px;
    height: 20px;
    outline:none;
    /* Neutrals/100 */
    color:#666666;
    background: #F8F8F8;
    border-radius: 4px;
    cursor: pointer;
}


.project-assignment-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 8px;
    /* width: 483px; */
    height: 53px;
    background: #EDEDED;
    /* Neutrals/200 */
    border: 1px solid #EDEDED;
    border-radius: 8px;
    margin-top: 40px;
}

.project-assignment-container p{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */


    /* Neutrals/400 */

    color: #999999;
    margin-bottom: 0px;
}

.selected-project{
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    gap: 4px;

    /* width: 187.99px; */
    height: 29px;

    background: #FFFFFF;
    /* Neutrals/200 */

    border: 1px solid #EDEDED;
    border-radius: 44px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* Neutrals/500 */

    color:
     #666666;
}

.selected-project i{
    color: #5b7edb;
}

.zoom-import-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    .cancel-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 4px;
        width: 45%;
        height: 45px;
        /* Neutrals/200 */
        border: 1px solid #EDEDED;
        border-radius: 44px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        letter-spacing: -0.01em;
        text-transform: capitalize;

        /* Neutrals/500 */

        color: #666666;
        margin-right: 5px;

    }

    .upload-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        width: 45%;
        height: 45px;
        /* Primary/500 */
        background: #5B7EDB;
        border-radius: 44px;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        letter-spacing: -0.01em;
        text-transform: capitalize;

        /* Neutrals/White */

        color: #FFFFFF;
    }

</style>