<template>
  <LoopActionModal
    :hideTitle="true"
    :showCta="false"
    :isPersistent="true"
    :hideCloseButton="true"
    :showCancelButton="false"
    :showModal="showMigratingWorkspaceLoader && workspaceMigrationFrom && workspaceMigrationTo"
    :width="680"
    :height="225"
  >
    <template v-slot:content>
        <div class="migrating-workspace-loader-container">
            <div class="migrating-workspace-loader-header">
                <div class="migrating-workspace-loader-from" v-if="workspaceMigrationFrom">
                    {{  workspaceMigrationFrom[0] }}
                </div>
                <div class="migrating-workspace-loader-loadingIndicator">
                    <looping-rhombuses-spinner :animation-duration="4000" :size="75" color="#5b7edb"/>
                </div>
                <div class="migrating-workspace-loader-to" v-if="workspaceMigrationTo">
                    {{  workspaceMigrationTo[0] }}
                </div>
            </div>
            <div class="migrating-workspace-loader-message">
                <h3>Moving your account to the new workspace</h3>
                <p>This can take a few minutes. Please keep the tab open.</p>
            </div>
        </div>
    </template>
  </LoopActionModal>
</template>

<script>
import LoopActionModal from './LoopActionModal.vue'
import { LoopingRhombusesSpinner } from "epic-spinners";
import { mapState } from "vuex";

export default {
    data() {
        return {
            workspaceMigrationFrom: null,
            workspaceMigrationTo: null,
            channel: null
        };
    },
    created() {
        // Generate a unique identifier for this tab
        this.tabId = Date.now() + Math.random().toString(36).substr(2, 9);

        // Initialize and subscribe to the BroadcastChannel
        this.channel = new BroadcastChannel('workspace_migration');
        this.channel.onmessage = this.handleDataReceived;
    },
    methods: {
        handlePostMessage(val){
            this.channel.postMessage(val);
        },
        handleDataReceived(event) {
            // console.log('received data in a different tab: ',event)
            // Assume the data is an object with 'from' and 'to' properties
            this.workspaceMigrationFrom = event.data.from;
            this.workspaceMigrationTo = event.data.to;
            
        }
    },
    beforeDestroy() {
        // Clean up: close the channel and remove the listener
        if (this.channel) {
            this.channel.close();
            this.channel = null;
        }
    },
    computed: {
        ...mapState([
            "workspaceMigrationNames"
        ]),
    },
    components: { LoopActionModal, LoopingRhombusesSpinner },
    props:{
        showMigratingWorkspaceLoader:{
            required: true,
            type: Boolean
        },
    },
    watch:{
        workspaceMigrationNames(newVal,oldVal){
            if(newVal.from && newVal.to){
                this.workspaceMigrationFrom = this.workspaceMigrationNames.from
                this.workspaceMigrationTo = this.workspaceMigrationNames.to
                console.log(newVal,this.workspaceMigrationFrom,this.workspaceMigrationTo)
                this.handlePostMessage({...newVal,tabId: this.tabId,})
            }
        }
    }

}
</script>

<style scoped>
.migrating-workspace-loader-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}

.migrating-workspace-loader-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 165px;
    margin-bottom:32px;
}

.migrating-workspace-loader-header .migrating-workspace-loader-from{
    display: flex;
    width: 48px;
    height: 48px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 2px solid var(--Primary-300, #E8EDFC);
    background: var(--Neutrals-White, #FFF);
    color: var(--Primary-500, #5B7EDB);
    text-align: center;
    leading-trim: both;
    opacity: 0.6;
    text-edge: cap;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 31.5px */
    text-transform: capitalize;
}

.migrating-workspace-loader-header .migrating-workspace-loader-to{
    display: flex;
    width: 52px;
    height: 52px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid var(--Primary-300, #E8EDFC);
    color: var(--Primary-500, #5B7EDB);
    text-align: center;
    leading-trim: both;

    text-edge: cap;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 31.5px */
    text-transform: capitalize;
}


.migrating-workspace-loader-message h3{
    color: var(--Neutrals-600, #4C4C4C);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    margin-bottom:4px;
}

.migrating-workspace-loader-message p{
    color: var(--Neutrals-500, #666);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
}

</style>