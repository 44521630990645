<template>
    <div>
        <v-dialog
            width="650"
            v-model="uploadDialog"
            @click:outside="clearSelectedFiles"
            @input="handleMenuChange"
            content-class="upload-video-modal"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="!(clickedFrom=='banner')" v-bind="attrs" v-on="on" width="230" class="modal-btn my-1 justify-start pl-0 text-capitalize" text color="#585858">
                    <i class="ri-video-on-line"></i>
                        Upload Video or Audio
                </v-btn>
            </template>

            <v-card class="upload-video-modal__container" style="border-radius:16px">
                <v-card-title class="upload-title-container">
                    <h3>
                        <template>
                            Upload video or audio files
                        </template>
                    </h3>
                    <i class="ri-close-fill" @click="clearSelectedFiles"></i>
                </v-card-title>

                <v-card-text>
                    <div>
                        <div>
                            <!-- <div v-if="showUploadAlertText" class="font-weight-bold">
                                <p>{{ uploadAlertText }}</p>
                            </div> -->
                            <!-- <v-file-input
                                color="loopone"
                                v-model="upfiles"
                                v-if="!mediaFiletoUpload.processing"
                                accept="audio/*,video/*"
                                filled
                                show-size
                                multiple
                                label="Click to add files"
                                ref="mediaFiles"
                                @change="handleMediaFilesUpload"
                                prepend-icon="ri-add-line"
                                append-inner-icon=""
                            >
                            </v-file-input> -->

                            <div v-if="selectedFiles.length != 0">

                                <div class="d-flex mb-4 ml-0 mt-5">
                                    <p class="mb-0 upload-info">
                                        Confirm selection
                                    </p>
                                    <!-- <v-btn class="align-self-center black--text pa-3"
                                        @click="clearSelectedFiles"
                                        x-small elevation="0" color="#ffffff"
                                    >
                                        Clear All
                                    </v-btn> -->
                                </div>

                                <div v-if="selectedFiles.length == 0">
                                    <p>No Files are selected</p>
                                </div>

                                <div class="selected-files-container">
                                    <div
                                        v-for="s in selectedFiles"
                                        :key="s.name"
                                        class="file-card"
                                        color="#E8EDFC"
                                    >
                                    <abbr class="file-card-name-abbr" :title="s.myFile.name">
                                        <span class="file-card-name">  {{ s.myFile.name | trimLength}} </span>
                                    </abbr>
                                    <abbr>
                                        <span class="file-card-size">  {{ getFormattedFileSize(s.myFile.size) }} </span>
                                    </abbr>

                                    <basic-dropdown-menu  v-on:dropdown-selection="handleSpeakerSelection($event, s)" class="ml-auto" label="Speakers" id="speaker-dropdown" :value="defaultSelectedSpeaker" :options="speakerOptions"></basic-dropdown-menu>
                                    <i class="ri-delete-bin-5-line" @click="removeSelectedFile(s)"></i>
                                    </div>
                                </div>


                            </div>
                             <div  class="project-assignment-container" v-if="selectedFiles && selectedFiles.length > 0 && !disableProjectSelection">
                                <p class="project-assignment-helper mr-5 mb-0">
                                    Upload to project
                                </p>
                                <template v-if="disableProjectSelection">
                                    <div class="selected-project-auto pulsating-border">
                                            {{selectedProject}}
                                            <!-- <i class="ri-arrow-down-s-line"></i> -->
                                    </div>
                                </template>
                                <template v-else>
                                    <ProjectAssignmentMenu
                                    :projects="allProjects.projects"
                                    v-on:apply-project="applyProject"
                                    v-on:create-new-project="createNewProject"
                                >
                                    <template v-slot:custom-activator>
                                        <div class="selected-project pulsating-border">
                                            <span v-if="selectedProject.default">
                                                <img src="../../../public/loop-logo-small.svg">
                                            </span>
                                            <span v-else>
                                                {{selectedProject.emoji}}
                                            </span>
                                            {{selectedProject.name}}
                                            <i class="ri-arrow-down-s-line"></i>
                                        </div>
                                    </template>
                                </ProjectAssignmentMenu>
                                </template>

                            </div>
                            <hr class="separator p-0"/>

                            <div class="upload-input-container mt-5"
                                id="upload-box"
                                v-if="selectedFiles.length < 4"
                                @dragover="dragover"
                                @dragleave="dragleave"
                                @drop="drop"
                            >
                                    <input accept="audio/*,video/*" multiple ref="uploadFileInputRef" 
                                            @change="handleFileSelection" placeholder="Browse files to upload" type="file"
                                    />
                                    <div style="display:flex; flex-direction:column; align-items:center; justify-content:flex-end"
                                     v-if="!isDragging"
                                    >
                                    <span class="upload-header">
                                    <p class="upload-input-cta" @click="openFileUpload">
                                        <i class="ri-upload-cloud-2-line" style="font-size:25px; margin-right:5px; color:#5B7EDB"></i>
                                        <template v-if="!isDragging && !(selectedFiles && selectedFiles.length) > 0">
                                           Click to upload
                                        </template>
                                        <template v-else>
                                            Upload more
                                        </template>
                                    </p>
                                    <p class="upload-input-header" style="margin-left: -18px; font-size:18px" v-if="!isDragging">or drag & drop a recording </p>
                                    </span>
                                    <p class="upload-input-subheader" style="color:#666; margin-top:-5px">Your recordings are private. No other human sees them.</p>
                                </div>
                                <p class="upload-input-header" v-if="isDragging">Drop files to upload.</p>
                            </div>
                            
                            
                            <p class="mb-0 mt-2 error-text" v-if="showUploadAlertText">
                                Please select and upload maximum 4 files at a time
                            </p>

                            <br />

                            <div class="upload-rule-container" v-if="selectedFiles.length < 4"> 

                                <div style="display:flex; flex-direction:row; margin-bottom:0px" v-if="!showFileSelectionError">
                                    <span class="upload-rule-text">
                                        <i class="ri-time-line" style="margin-right:5px; color: #666666;"></i>
                                        <p class="upload-input-subheader mb-0">Less than 2hr long</p>
                                    </span>
                                    <span class="upload-rule-text">
                                        <i class="ri-folder-line" style="margin-right:5px; color: #666666;"></i>
                                        <p class="upload-input-subheader mb-0">Upto 4 GB</p>
                                    </span>
                                    <span class="upload-rule-text">
                                    <i class="ri-vidicon-line" style="margin-right:5px; color: #666666;"></i>
                                    <p class="upload-input-subheader mb-0">Audio & video formats: mov, mp4, mp3, m4a, wav or aac</p>
                                    </span>
                                </div>
                                <div v-else>
                                    <span class="file-selection-error-message">
                                        {{ fileSelectionErrorMessage }}
                                    </span>
                                </div>
        
                            </div>

                        </div>
                    </div>
                    <TranscriptionDurationCard  v-if="!trialEnded || subscriptionActive" :showUpgradeByDefault="false"/>
                </v-card-text>
                <v-card-actions>

                    <template v-if="selectedFiles && selectedFiles.length > 0">
                        <button class="cancel-btn" @click="cancelFileUpload">
                            Cancel
                        </button>
                        <button @click="handleMediaFilesUpload" class="upload-btn">
                            Upload
                        </button>
                    </template>
                    <template v-else>
                        <button @click="closeUploadDialog" class="close-btn">
                            Close
                        </button>
                    </template>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ProjectAssignmentMenu from '../menus/ProjectAssignmentMenu.vue';
import {auth} from "./../../firebase";
import { mapState } from "vuex";
import BasicDropdownMenu from '../menus/BasicDropdownMenu.vue';
import TranscriptionDurationCard from '@/components/cards/TranscriptionDurationCard.vue';
import fileUtils from '@/utils/fileUtils'

export default {
    props: {
        // uploadDialog:{}, // To show popup
        uploadAlertText: {},
        upfiles: {},
        mediaFiletoUpload: {},
        // selectedFiles:{},
        isUploadingMediaFiles: {},
        allProjects: {},
        defaultProject:{},
        disableProjectSelection:{
            default:false
        },
        project:{},
        clickedFrom:{
            default:'Homepage'
        }
    },
    data() {
        return {
            uploadDialog: false,
            selectedFiles: [],
            progresslist: [],
            selectedProject: this.disableProjectSelection? this.project: this.defaultProject,
            showUploadAlertText:false,
            isDragging: false,
            defaultSelectedSpeaker: 'Auto-detect', // Default selected value for speakers
            speakerOptions: ['Auto-detect','1', '2', '3','4','5','6','7','8','9'],
            showFileSelectionError:false, // Used to show file selection error message
            fileSelectionErrorMessage:'', // Error message for unsupported file type or file size
            supportedFileTypes: ['mov','mp4','mp3','m4a','wav','aac'], // Supported media file type extensions
            defaultFileSizeLimit: 4294967296 // 4GB in bytes  4 * 1024 * 1024 * 1024
        };
    },
    computed: {
        ...mapState([
            "showUploadModal"
        ]),
        trialEnded() {
            return this.$store.state.billingDetails.trialEnded;
        },
        subscriptionActive(){
            return this.$store.state.billingDetails.subscriptionActive;
        },
    },
    watch:{
        showUploadModal:function(newVal,oldVal){
            // console.log('showUploadModal changed',this.showUploadModal)
            if(newVal== true) this.uploadDialog = true;
        },
        uploadDialog:function(newValue, old){
        let currentPage = "home";
        if (this.$route.path.includes("/session")){
            currentPage = "project"
        }else if(this.$route.path.includes("/editor")){
            currentPage = "editor"
        } else {
            currentPage = "home"
        }
        console.log("current page:", currentPage)
        if(newValue == true){
            window.analytics.track(`open upload call modal from ${currentPage} view`, {
                email: auth.currentUser.email
            });
        } else {
            window.analytics.track(`close upload call modal from ${currentPage} view`, {
                email: auth.currentUser.email
            });
            }
        }
    },
    methods: {
        // Format bytes to human readable size
        getFormattedFileSize(s){
            return fileUtils.formatBytes(s)
        },
        verifySelectedFiles() {
            // Initialize variables
            const _verified_selected_files = [];
            let _has_unsupported_fileFormat = false;
            let _exceeds_file_size_limit = false;

            // Loop through each selected file
            for (const file of this.selectedFiles) {
                // Extract file size and file extension
                const _filesize = file.myFile.size;
                const _file_ext = file.myFile.name.split('.').pop().toLowerCase();

                // Check if the file is supported and within size limits
                const isSupported = this.supportedFileTypes.indexOf(_file_ext) > -1;
                const isSizeValid = _filesize < this.defaultFileSizeLimit;

                // Determine whether to include the file in the verified list
                if (isSupported && isSizeValid) {
                    _verified_selected_files.push(file);
                } else if (!isSupported) {
                    _has_unsupported_fileFormat = true;
                } else {
                    _exceeds_file_size_limit = true;
                }
            }

            // Update the selected files with the verified list
            this.selectedFiles = _verified_selected_files;

            // Check for unsupported file formats or exceeded file size limits
            if (_has_unsupported_fileFormat || _exceeds_file_size_limit) {
                // Set error message
                this.showFileSelectionError = true

                if (_verified_selected_files.length === 0) {
                    const fileSizeErrorMessage = 'File is too large. Please upload files that are each 4 GB or less.'
                    const formatErrorMessage = 'File format not supported. Please upload files in these formats: .mov, .mp4, .mp3, .m4a, .wav or .aac'
                    if (_has_unsupported_fileFormat && _exceeds_file_size_limit){
                        this.fileSelectionErrorMessage =  `${fileSizeErrorMessage} ${formatErrorMessage}`.trim()
                    } else if (_has_unsupported_fileFormat) {
                        this.fileSelectionErrorMessage =  `${formatErrorMessage}`.trim()
                    } else if (_exceeds_file_size_limit) {
                        this.fileSelectionErrorMessage =  `${fileSizeErrorMessage}`.trim()
                    }
                } else {
                    const fileSizeErrorMessage = 'Some selected files are too large. Please upload files that are each 4 GB or less.'
                    const formatErrorMessage = 'Some selected files are in unsupported formats. Please upload files in these formats: .mov, .mp4, .mp3, .m4a, .wav or .aac'
                    if (_has_unsupported_fileFormat) {
                        this.fileSelectionErrorMessage =  `${formatErrorMessage}`.trim()
                    } else if (_exceeds_file_size_limit) {
                        this.fileSelectionErrorMessage =  `${fileSizeErrorMessage}`.trim()
                    } else {
                        this.fileSelectionErrorMessage =  `${fileSizeErrorMessage} ${formatErrorMessage}`.trim()
                    }
                }

                // Highlight upload box
                let upload_box = document.getElementById("upload-box")
                upload_box.style["background"] = "#FFF5F5";
                upload_box.style["border"] = "2px dashed #F3D6D6";

                // Clear the error message after a delay
                setTimeout(() => {
                    this.fileSelectionErrorMessage = '';
                    this.showFileSelectionError = false
                    upload_box.style["background"] = "#F6F8FE";
                    upload_box.style["border"] = "2px dashed #D2DCFB";
                }, 7000);
            }
        },
        // Updates the default speaker selection number for a file
        handleSpeakerSelection(selection,file){
            const fileName = file.myFile.name
            this.selectedFiles.forEach((f)=>{
                if (f.myFile.name == fileName) {
                    f.myFile.defaultSpeakerSelection = selection
                }
            })
            window.analytics.track(`select speaker input from upload call modal`, {
                email: auth.currentUser.email
            });
        },
        dragover(e) {
        e.preventDefault();
        console.log("dragging true", this.isDragging)
        this.isDragging = true;
        },
        dragleave() {
        console.log("dragging false", this.isDragging)
        this.isDragging = false;
        },
        drop(e) {
        e.preventDefault();
        this.$refs.uploadFileInputRef.files = e.dataTransfer.files;
        this.handleFileSelection();
        this.isDragging = false;
        console.log("dropped",e.dataTransfer.files)
        },
        openFileUpload(){
            this.$refs.uploadFileInputRef.click();
        },
        cancelFileUpload(){
            // console.log('cancel file upload');
            this.uploadDialog=false;
            this.selectedFiles=[];
        },
        clearSelectedFiles() {
            this.uploadDialog=false;
            this.selectedFiles=[]
            this.$emit("clear-selected-files");
            this.selectedProject=null;
            this.$store.dispatch('toggleUploadModal',false)
        },
        removeSelectedFile(s) {
            this.$emit("remove-selected-file", s);
            this.selectedFiles = this.selectedFiles.filter(f => f !== s);
        },
        closeUploadDialog() {
            this.uploadDialog = false;
            this.selectedProject=null;
            this.selectedFiles=[]
            this.$emit("close-upload-dialog");
        },
        handleMediaFilesUpload() {
            console.log("handleMediaFilesUpload -1 running..", this.selectedFiles,this.selectedProject)
            this.$emit("start-media-files-upload", this.selectedFiles,this.selectedProject);
            this.clearSelectedFiles();
        },
        handleFileSelection(e) {
            // const files=e.target.files;
            console.log("initial selected files", this.selectedFiles)
            const files = [...this.$refs.uploadFileInputRef.files]
            if(files.length>=5 || this.selectedFiles.length>=5){
                console.log('upload alert limit reached')
                this.showUploadAlertText=true;
                return;
            }else{
                if(this.showUploadAlertText) this.showUploadAlertText=false;
                files.forEach(file=>{
                    const currentFile =file;
                    console.log("test",{ currentFile }, this.selectedFiles);
                    currentFile.defaultSpeakerSelection = 'Auto-detect'
                    this.selectedFiles.push({ myFile: currentFile });
                    console.log("reached-1")
                    console.log("selected file count",this.selectedFiles, this.selectedFiles.length)
                    console.log("reached-2",this.allProjects)
                })
                this.$refs.uploadFileInputRef.value=''
            }

            // Check if selected files are supported and within file size limit
            this.verifySelectedFiles()
        },
        applyProject(project){
            this.selectedProject=project;
        },
        createNewProject(newProject){
            this.$emit('create-new-project',newProject)
        },
        handleMenuChange(e){
            this.selectedProject=this.disableProjectSelection? this.project: this.defaultProject;
        }

    },
    updated(){
        if(this.clickedFrom === 'banner' && !this.selectedProject){
            console.log("mounted inside banner")
            this.selectedProject=this.disableProjectSelection? this.project: this.defaultProject;
        }
    },
    filters:{
        trimLength(val) {
            if (val.length < 35) {
                return val;
            }
            return `${val.substring(0, 35)}...`;
        },
    },
    components: { ProjectAssignmentMenu, BasicDropdownMenu,TranscriptionDurationCard }
}
</script>

<style scoped>
    .error-text{
        color: var(--Accent-Red-Default, #C53030);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 18px */
    }
    .file-card-size{
        color: #999;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-left: 8px
    }

    .file-selection-error-message{
        color: var(--Accent-Red-Default, #C53030);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 18px */
        margin-bottom: 0px;
    }
  .speaker-select {
    display: flex;
    max-width: 60px;
    font-size: 12px;
    height:13px !important;
    align-items: center;
    justify-content: center;
  }
  .speaker-select-item{
    display: flex;
    align-items: center;
    max-width: 60px;
    font-size: 12px;
  }

    .upload-video-modal,.upload-video-modal__container{
        border-radius: 16px !important;
        margin: 0px;
      }

    .modal-btn{
        outline: none;
        cursor: pointer;
    }
    .modal-btn i{
        font-size: 25px; margin-right: 10px; margin-left: 5px;
    }
    .upload-title-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EDEDED;
    }

    .upload-title-container h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;

        /* Neutrals/600 */

        color: #4C4C4C;
    }

    .upload-title-container i{
        color:#666666;
        cursor: pointer;
    }

    .upload-input-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        height: 132px; 
        background: #F6F8FE;
        border: 1px solid #D2DCFB;
        border-radius: 8px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        position: relative;
        border: 2px dashed #D2DCFB;
        border-radius: 12px;
        background: white;
    }

    .upload-input-container input[type=file]::file-selector-button{
        display: none;
    }

    .upload-input-container input[type=file]::before{
        content:"Browse files to upload";
    }

     .upload-input-container input[type=file]{
        color: rgba(0, 0, 0, 0)
     }

    .upload-input-container input{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        /* Primary/500 */
        color: #5B7EDB;
        width: 100%;
    }

    .upload-input-container i{
        color: #5B7EDB;
        margin-right: 10px;
    }

    .upload-input-container .upload-header{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upload-input-container .upload-header  .upload-input-cta{
        /* position: absolute;
        top: 27%;
        left: 10%; */
        margin-left: -35px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        /* identical to box height, or 21px */
        /* Primary/500 */
        color: #5B7EDB;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .upload-helper{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */


        /* Neutrals/400 */

        color: #999999;
    }

    .upload-helper span{
        color: #4C4C4C;
        font-weight: 600;

    }

    .upload-rule-container {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap:wrap;
        width: auto;
        margin-bottom: 24px;
   }

    .upload-rule-text-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .upload-rule-text {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0px 10px 0px 0px;
    }
       .upload-input-container .upload-input-header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #4C4C4C;
   }

    .upload-input-subheader {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
        color: #666666;
        
   }

    .close-btn{
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        height: 45px;
        /* Neutrals/200 */
        border: 1px solid #EDEDED;
        border-radius: 44px;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        letter-spacing: -0.01em;
        text-transform: capitalize;

        /* Neutrals/500 */

        color: #666666;

    }

    .upload-info{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #666666;
    }

    .selected-files-container{
        display: flex;
        flex-flow: wrap;
    }

    .file-card {
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: stretch;
        justify-content: space-between;
        height: 48px;
        padding: 8px 12px 8px 16px;
        /* Neutrals/100 */
        background: #F8F8F8;
        /* Neutrals/200 */
        border-radius: 12px;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-right: 5px;
        margin-bottom: 8px;
    }
    .file-card-name-abbr {
        border-bottom: none !important;
        cursor: inherit !important;
        text-decoration: none !important;
    }
    .file-card i{
        color:#999999;
        margin-left: 12px;
        cursor: pointer;
    }
    .file-card i:hover{
        color:#4C4C4C;
        margin-left: 12px;
    }

    .file-card-name {
        color: #4C4C4C;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }

    .cancel-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        gap: 4px;
        width: 45%;
        height: 45px;
        /* Neutrals/200 */
        border: 1px solid #EDEDED;
        border-radius: 44px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        letter-spacing: -0.01em;
        text-transform: capitalize;

        /* Neutrals/500 */

        color: #666666;
        margin-right: 5px;

    }

    .upload-btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        width: 45%;
        height: 45px;
        /* Primary/500 */
        background: #5B7EDB;
        border-radius: 44px;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */
        letter-spacing: -0.01em;
        text-transform: capitalize;

        /* Neutrals/White */

        color: #FFFFFF;
    }

    .project-assignment-helper{
        color: #4C4C4C;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 21px */
    }

    .project-assignment-container{
        display: flex;
        align-items: center;
        margin: 20px 0px 50px 0px
    }

    .selected-project{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 12px;
        gap: 4px;
        width: auto;
        height: 29px;
        background: #FFFFFF;
        border: 1px solid #D2DCFB;
        border-radius: 12px;
        cursor: pointer;
        color: var(--neutrals-500, #666);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .selected-project-auto{
        color: #666666;
        font-weight: 600;
    }
    .selected-project i{
        color: #5B7EDB;
    }

    .separator{
        width: 100%;
        height: 1px;
        margin: 0;
        margin-bottom: 16px;
        /* Neutrals/200 */
        background: #EDEDED;
    }
    
</style>