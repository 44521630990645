import {
    auth, questionsCollection, scriptsCollection
} from "@/firebase";
import axios from "axios";
import {getFunctionUrl} from '@/functions/functionsConfig'
import store from "@/store";
import getWorkspaceIdForProjectQuery from '@/utils/apiUtils.js'

const getAllScriptsAndQuestions=async(workspace_id=null)=>{
    // BACKEND_API
    const GET_SCRIPTS_AND_QUESTIONS_ENDPOINT= getFunctionUrl('get_scripts_and_questions');
    try{
        let requestParams = {
            "user_id": auth.currentUser.uid,
        }
        if(workspace_id) requestParams['workspace_id'] = workspace_id;
        const response=await axios.post(GET_SCRIPTS_AND_QUESTIONS_ENDPOINT,requestParams);
        console.log('response: ',response)
        return {status:'success',data:response.data.scripts}
    }catch(error){
        return { status:'error',error:error}
    }
}

// Get all scripts for a user or workspace
const getAllScripts=async(workspace_id=null)=>{
    // BACKEND_API
    const GET_SCRIPTS_ENDPOINT=getFunctionUrl('get_scripts_and_questions') + '/scripts';
    try{
        let requestParams = {
            "user_id": auth.currentUser.uid,
        }
        if(workspace_id) requestParams['workspace_id'] = workspace_id;
        const response=await axios.post(GET_SCRIPTS_ENDPOINT,requestParams);
        // console.log('response: ',response)
        return {status:'success',data:response.data.scripts}
    }catch(error){
        return { status:'error',error:error}
    }
}

// Get all questions for a script
const getQuestionsByScriptId=async(script_id=null)=>{
    // BACKEND_API
    const GET_QUESTIONS_ENDPOINT=getFunctionUrl('get_scripts_and_questions') + '/questions';
    try{
        let requestParams = {
            "user_id": auth.currentUser.uid,
            "script_id": script_id
        }
        const response=await axios.post(GET_QUESTIONS_ENDPOINT,requestParams);
        // console.log('response: ',response)
        return {status:'success',data:response.data.questions}
    }catch(error){
        return { status:'error',error:error}
    }
}

const getQuestionScripts_beta=async()=>{
    return [{id:'1',none:true,name:'None'},{id:'3Ues4spomyXBp09fFNdm',name:'Usability Test Script'}]
}

// [ADDED_WORKSPACE_ID]
const getScript_beta=async(userId=null,workspaceId)=>{
    try{
        let scriptData=await scriptsCollection.where("userId", "==", userId?userId:auth.currentUser.uid).where("workspace_id","==",workspaceId).get();
        let scripts=[];
        for(const script of await scriptData.docs){
            // console.log(project.id,'-->',project.data().name)
            scripts.push({
                scriptId:script.id,
                ...script.data()
            })
        }
        console.log({scripts})
        // return scriptData;
        return [{id:'1',none:true,name:'None'},...scripts]
    }catch(error){
        console.error(error)
    }


}

const getScriptByProjectId=async(projectId)=>{
    try{
        // MISSING WORKSPACE_ID ERROR
        const workspaceId = getWorkspaceIdForProjectQuery(projectId)
        console.log('called getScriptByProjectId',projectId,workspaceId)
        let script= await scriptsCollection.where("workspace_id",'==',workspaceId).where("projectId", "==", projectId).get() 
        console.log('script response',script,projectId)
        if(!script.empty){
            console.log(script)
            script=script.docs[0]
            // return scriptData;
            return {scriptId:script.id,...script.data()};
        }else{
            return null;
        }
        
    }catch(error){
        console.error(error)
    }
}

const getScriptByScriptId=async(scriptId)=>{
    try{
        let script=await scriptsCollection.doc(scriptId).get();
        console.log('scriptById',script)
        if(!script.empty){
            console.log(script)
            // return scriptData;
            return {scriptId:script.id,...script.data()};
        }
        
    }catch(error){
        console.error(error)
    }
}
const getAllQuestions_beta=async(scriptId,projectId)=>{
    try{
        // MISSING WORKSPACE_ID ERROR
        const workspaceId = getWorkspaceIdForProjectQuery(projectId)
        console.log('calling getAllQuestions_beta',workspaceId)
        const questionsData=await questionsCollection.where("workspace_id","==",workspaceId).where("scriptId", "==", scriptId).get();
        let questions=[]
        for(const question of await questionsData.docs){
            questions.push({
                id:question.id,
                ...question.data()
            })
            
        }
        // questions.forEach(question=>console.log(question.text))
        return questions;
    }catch(error){
        console.log("Error: " + error)
    }
}
const getQuestion_beta=async(questionId)=>{
    try{
        const question=await questionsCollection.doc(questionId).get();
        const questionData=question.data();
        return questionData;
    }catch(error){
        console.log({error})
    }

}

const createQuestionScript=async(questions,project_id,script_name,workspaceId,type="CREATE_NEW")=>{
    // BACKEND_API
    const requestUrl=getFunctionUrl('manage_question_script')
    const body=JSON.stringify({
        user_id: auth.currentUser.uid,
        questions: questions,
        project_id: project_id,
        type: type,
        script_name: script_name,
        workspace_id: workspaceId
    })
    console.log('Sending this request',body)
    var config = {
        method: "post",
        url: requestUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
    };
   
    try{
        const response=await axios(config)
        return{ status:'success',response:response}
    }catch (error){
        console.log({error})
        return{ status:'error',error:error}
    }
}
const updateQuestionScript=async(questions,project_id,script_name,type="RESET")=>{
    // BACKEND_API
    const requestUrl=getFunctionUrl('manage_question_script')
    // const requestUrl="http://127..0.1:8081"
    const body=JSON.stringify({
        user_id: auth.currentUser.uid,
        questions: questions,
        project_id: project_id,
        type: type,
        script_name: script_name
    })
    console.log('Sending this request',body)
    var config = {
        method: "post",
        url: requestUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
    };
   
    try{
        const response=await axios(config)
        return{ status:'success',response:response}
    }catch (error){
        console.log({error})
        return{ status:'error',error:error}
    }
}

const updateQuestion=async(questionId,newQuestion,updateQuestionHTML=false,newQuestionHTML=null)=>{
    console.log('to update in db',{newQuestion})
    try{
        const question=await questionsCollection.doc(questionId);
        let updatedQuestion;
        if(updateQuestionHTML && !newQuestionHTML)
        updatedQuestion=await question.update({ html: newQuestion, updatedOn: new Date() })
        else if(updateQuestionHTML && newQuestionHTML)
        updatedQuestion=await question.update({ text: newQuestion, html:newQuestionHTML, updatedOn: new Date() })
        else
        updatedQuestion=await question.update({ text: newQuestion, updatedOn: new Date() })
        return { status:'success',data:updatedQuestion}

    }catch (error){
        console.log({error})
        return{ status:'failure',error:error}
    }
}

// [ADDED_WORKSPACE_ID_PASSED]
const addNewQuestion = async(workspaceId,questionHTML,questionText,scriptId,orderIndex)=>{
    try{
        const newQuestionResponse=await questionsCollection.add({ 
            createdOn: new Date(),
            orderIndex:orderIndex,
            scriptId:scriptId,
            text:questionText,
            html:questionHTML,
            updatedOn: new Date(),
            userId: auth.currentUser.uid,
            workspace_id : workspaceId
        })
        console.log({newQuestionResponse});
        return{status:'success',data:newQuestionResponse}
    }catch (error){
        console.log({error})
        return{ status:'failure',error:error}
    }
}

const getQuestionById=async(questionId)=>{
    try{
        const question=await questionsCollection.doc(questionId).get();
        if(!question.empty){
            console.log({question})
            // return scriptData;
            return {status:'success',data:{id:question.id,...question.data()}};

        }
    }catch(error){
        console.log({error})
        return{status:'failure',error}
    }
}

const reorderQuestions=async(newOrderedQuestionsIds,script_id,type="REORDER")=>{
    try{
        // BACKEND_API
        const requestUrl=getFunctionUrl('manage_question_script')
        const body=JSON.stringify({
            user_id: auth.currentUser.uid,
            reordered_questions: newOrderedQuestionsIds,
            script_id: script_id,
            type: type,
        })
        var config = {
            method: "post",
            url: requestUrl,
            headers: {
              "Content-Type": "application/json",
            },
            data: body,
        };
        const response=await axios(config)
        return{ status:'success',response:response}
    }catch(error){
        console.log({error})
    }
}

const preassignScriptToCall=async(bot_id,script_id,project_id,project_name,project_emoji,meeting_id,meeting_name,meeting_at)=>{
    // BACKEND_API
    const PREASSIGN_SCRIPT_ENDPOINT=getFunctionUrl('recall_calendar') + '/assign-script';
    try{
        const body={
            "user_id":auth.currentUser.uid,
            "bot_id":bot_id,
            "project_id":project_id,
            "project_name":project_name,
            "script_id":script_id,
            "emoji":project_emoji,
            "meeting_id":meeting_id,
            "meeting_name":meeting_name,
            "meeting_at":meeting_at
        }
        const response=await axios.post(PREASSIGN_SCRIPT_ENDPOINT,body);
        console.log(response)
        return {
            status:'success',data:response
        }
    }catch(error){
        return{
            status:'error',error:error
        }
    }
}

const getNotesCountForQuestions = async(question_id)=>{
    try{
        // BACKEND_API
        const requestUrl = getFunctionUrl('get_question_notes_count');
        const body={
            'user_id':auth.currentUser.uid,
            'question_id':question_id
        }
        const response=await axios.post(requestUrl,body);
        console.log(response)
        return{
            status:'success',data:response.data.notes_count
        }

    }catch(error){
        return{
            status:'error',error:error
        }
    }
}

const deleteQuestionAndReordeScript=async(question_id,script_id,unstructured_id)=>{
    try{
        // BACKEND_API
        const requestUrl = getFunctionUrl('delete_question');
        const body={
            "user_id" : auth.currentUser.uid,
            "question_id" : question_id,
            "script_id" : script_id,
            "unstructured_id" : unstructured_id
        }
        const response = await axios.post(requestUrl,body);
        console.log(response)
        return{
            status:'success',data:response.data
        }
    }catch(error){
        return{
            status:'error',error:error
        }
    }
}

const getDiscussionGuideSuggestions=async(questions)=>{
    try{
        // BACKEND_API
        const requestUrl = getFunctionUrl('get_guide_suggestions')
        const body={
            "questions" : questions
        }
        const response = await axios.post(requestUrl,body)
        console.log('get guide suggestions response: ',response)
        return{
            status:'success',data:response.data
        }
    }catch(error){
        return{
            status:'error',error:error
        }
    }
}
export default{
    getAllScriptsAndQuestions,
    getQuestionScripts_beta,
    getScript_beta,
    getQuestion_beta,
    getAllQuestions_beta,
    getScriptByProjectId,
    getScriptByScriptId,
    createQuestionScript,
    updateQuestion,
    addNewQuestion,
    getQuestionById,
    reorderQuestions,
    preassignScriptToCall,
    getNotesCountForQuestions,
    deleteQuestionAndReordeScript,
    updateQuestionScript,
    getDiscussionGuideSuggestions,
    getAllScripts,
    getQuestionsByScriptId
}