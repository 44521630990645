<!-- Main card used in sessions, projects etc -->

<template>
  <!-- The actual card layout  -->
  <div class="card-container" @click="redirect" 
  :style="{height:customHeight?`${customHeight}px`:'313px',
  width:customWidth?`${customWidth}px`:'354.67px',
  cursor:showCustorPointerOnHover?'pointer':'auto'}"
  >
    <!-- Card Thumbnail -->
    <div v-if="showImage" class="card-image">
      <img :src="image" alt="" />
    </div>
    <div v-else class="card-top">
      <slot name="top"></slot>
    </div>

    <!-- Card UI after the thumbnail -->
    <div class="card-content">
      <!-- Container for topmost UI element of the card UI  -->
      <div v-if="showCardHeader" class="card-content__header">
        <!-- Fixates this template to the left of the card content header -->
        <div class="card-content__headerLeft">
          <h4>{{ title }}</h4>
        </div>

        <!-- Fixates this template to the right of the card content header -->
        <div class="card-content__headerRight">
          <slot name="actions"> </slot>
        </div>
      </div>

      <!-- Text/Description/Information UI on the card -->
      <div class="card-content__info">
        <slot name="info"> </slot>
      </div>

      <div v-if="showCta" class="card-content__cta">
        <slot name="cta"></slot>
      </div>

    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase';

export default {
  props: {
    to: {
      type: String,
    },
    title: {
      type: String,
      // required: true,
    },
    showImage:{
      type: Boolean,
      default: true,
    },
    showCardHeader: {
      type:Boolean,
      default: true,
    },
    showCta:{
      type:Boolean,
      default: false,
    },
    image: {
      type: String,
      // required: true,
    },
    customHeight: {
      default:313
    },
    customWidth:{
      default:354.67
    },
    showCustorPointerOnHover: {
      default:true
    }
  },
  methods: {
    redirect() {
      window.analytics.track("open a project from projects page", {
        email: auth.currentUser.email,
      });
      if (!this.to) return;
      this.$router.push(`${this.to}`);
    },
  },
};
</script>

<style scoped>
.card-container {
  /* Session Card */
  /* Auto layout */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 354.67px;
  height: 313px;
  background: #ffffff;
  /* Shadow- L1 */
  box-shadow: 1px 1px 10px rgba(102, 102, 102, 0.15);
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  /* margin: 0px 24px; */
  margin-right: 20.67px;
  margin-bottom: 21px;
  position: relative;
}

.card-top{
  width:100%;
  display: flex;
  justify-content: center;
}
.card-image img {
  cursor: pointer;
  width: 354px;
  height: 183px;
  /* Primary/200 */
  background: #f6f8fe;
  /* Inside auto layout */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-content {
  padding: 16px;
  width: 100%;
}

.card-content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-content__headerLeft h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  color: #4c4c4c;
  text-transform: none;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-content__headerRight {
  padding-bottom: 10px;
}

.card-content__info {
  margin-top: 5px;
}

.card-content__cta{
  padding-top: 0px;
  width: 100%;
  display: flex;
  justify-content:center;
  position: absolute;
  bottom:20px;
  left:0px;
  text-align: center;
}
</style>