<template>
<LoopActionModal
    v-if="showModal"
    :showModal="showModal"
    v-on:toggle-modal="toggleModal"
    :width="626" :height="526" :borderRadius="'12px'"
    :showCta="false" :hideTitle="true"
  >
    <template v-slot:content>
      <div class="mt-10">
        <VerifyEmailUI/>
      </div>
    </template>
  </LoopActionModal>
</template>

<script>
import VerifyEmailUI from '../misc/VerifyEmailUI.vue'
import LoopActionModal from './LoopActionModal.vue'
export default {
  components: { LoopActionModal, VerifyEmailUI },
  data(){
    return{
        showModal: false,
    }
  },
  methods:{
    toggleModal(){
        this.showModal = !this.showModal
        if(this.showModal == false)
          this.$emit('close-modal')
    }
  }

}
</script>

<style>

</style>