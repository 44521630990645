import {
    auth,
    projectsCollection,
    workspaceCollection
  } from "@/firebase";
import CONSTANTS from '../../constants/miscellaneous.js';
import axios from "axios"
import {getFunctionUrl} from '@/functions/functionsConfig'

// BACKEND_API
const WORKSPACE_ENDPOINT = getFunctionUrl('workspace')
// const WORKSPACE_ENDPOINT = "http://127.0.0.1:8081"

const getWorkspaceDataById = async(workspaceId)=>{
    try{
        const workspace = await workspaceCollection.doc(workspaceId).get();
        const workspaceData = await workspace.data()
        return { status: 'success', data : workspaceData}
    }catch(error){
        return{status:'error',error:error}
    }
}

const getUserWorkspace=async()=>{
    try{
        const requestBody ={
            'type':'READ_WORKSPACE',
            'user_id': auth.currentUser.uid
        }
        const workspace = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log('Workspac data: ',workspace)
        return workspace.data
    }catch(err){
        console.log('Error getting user workspace',err)
        return err;
    }
                      
}

const getWorkspaceToMigrate=async(workspaceId)=>{
    try{
        const requestBody ={
            'type':'READ_MIGRATION_WORKSPACE',
            'user_id': auth.currentUser.uid,
            'workspace_id': workspaceId
        }
        const workspaceToMigrateResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log('Workspace to migrate data: ',workspaceToMigrateResponse)
        return workspaceToMigrateResponse.data
    }catch(err){
        console.log('Error getting user workspace to migrate',err)
        return err;
    }
                      
}

const getAutoSuggestedCompanyWorkspace=async(emailDomain)=>{
    try{
        const requestBody ={
            'type':'SUGGEST_WORKSPACE',
            'email_domain': emailDomain
        }
        const workspace = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log('Workspac data: ',workspace)
        return workspace.data
    }catch(err){
        console.log('Error getting user workspace',err)
        return err;
    }
                      
}

const getWorkspaceMembers=async(workspaceId)=>{
    try{
        const requestBody ={
            'type':'LIST_WORKSPACE_MEMBERS',
            'workspace_id': workspaceId
        }
        const workspace_members = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log('Workspae members data: ',workspace_members)
        return workspace_members.data
    }catch(err){
        console.log('Error getting workspace members',err)
        return err;
    }
                      
}

const validateWorkspaceAccess = async(workspaceId)=>{
    try{
        const requestBody={
            "user_id":auth.currentUser.uid,
            "type":"VALIDATE_WORSPACE_ACCESS",
            "workspace_id":workspaceId
        }
        const validateWorkspaceResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({validateWorkspaceResponse})
        return { status:'success',data:validateWorkspaceResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}

const updateWorkspace=async(workspaceId,newName,newAutoJoinSetting)=>{
    try{
        let requestBody={
            "user_id":auth.currentUser.uid,
            "type":"UPDATE_WORKSPACE",
            "workspace_id":workspaceId,
            
        }
        if(newName) requestBody['name']=newName;
        if(newAutoJoinSetting==true || newAutoJoinSetting==false) requestBody['auto_join']=`${newAutoJoinSetting}`;
        const renameWorkspaceResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({renameWorkspaceResponse})
        return { status:'success',data:renameWorkspaceResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}

const updateWorkspaceRole=async(workspaceId,newRole,workspaceMember)=>{
    try{
        let requestBody={
            "user_id":auth.currentUser.uid,
            "type":"UPDATE_WORKSPACE_ROLE",
            "workspace_id":workspaceId,
            "workspace_member":workspaceMember,
            "role":newRole
            
        }
        const updateWorkspaceRoleResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({updateWorkspaceRoleResponse})
        return { status:'success',data:updateWorkspaceRoleResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}

const joinWorkspace=async(workspaceId,role,migrateWorkspace=false,invitee=null)=>{
    try{
        let requestBody={
            "user_id":auth.currentUser.uid,
            "type":"UPDATE_WORKSPACE_ACCESS",
            "workspace_id":workspaceId,
            "status":"accepted",
            "role":role
        }

        if (migrateWorkspace) requestBody['migrate_workspace'] = migrateWorkspace

        if (invitee) requestBody["invitee_user_id"] = invitee
        console.log('sending payload ',requestBody)
        const joinWorkspaceResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({joinWorkspaceResponse})
        return { status:'success',data:joinWorkspaceResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}

const requestWorkspaceAccess=async(workspaceId)=>{
    try{
        let requestBody={
            "user_id":auth.currentUser.uid,
            "type":"REQUEST_WORKSPACE_ACCESS",
            "workspace_id":workspaceId,            
        }

        const requestWorkspaceAccessResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({requestWorkspaceAccessResponse})
        return { status:'success',data:requestWorkspaceAccessResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}

const migrateWorkspace=async(workspaceId)=>{
    try{
        let requestBody={
            "user_id":auth.currentUser.uid,
            "type":"MIGRATE_WORKSPACE",
            "workspace_id":workspaceId,
            
        }

        const migratedWorkspaceResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({migratedWorkspaceResponse})
        return { status:'success',data:migratedWorkspaceResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}


const createNewWorkspace=async(workspace_type,emailDomain,workspaceName,autoJoin)=>{

    try{
        let requestBody={
            "user_id":auth.currentUser.uid,
            "owner":auth.currentUser.uid,
            "type":"CREATE_WORKSPACE",
            "name":workspaceName,
            "company_id":"",
            "emailDomain":[emailDomain],
            "autoJoin":autoJoin,
            "workspace_type":workspace_type
        }

        const newCreatedWorkspaceResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({newCreatedWorkspaceResponse})
        return newCreatedWorkspaceResponse.data

    }catch(error){
        return{ status:'error',error}
    }   
}

const sendWorkspaceInvite=async(inviteeEmail,workspaceId,role)=>{
    try{
        const requestBody={
            "type":"INVITE_WORKSPACE",
            "invitee_email":inviteeEmail,
            "workspace_id":workspaceId,
            "sender_user_id":auth.currentUser.uid,
            "user_id":auth.currentUser.uid,
            "role" : role
        }
        const sendWorkspaceInviteResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({sendWorkspaceInviteResponse})
        return { status:'success',data:sendWorkspaceInviteResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}

const fetchWorkspaceInvites = async(workspaceId)=>{
    try{
        const requestBody={
            "type":"READ_WORKSPACE_INVITES",
            "workspace_id":workspaceId,
            "user_id":auth.currentUser.uid,
        }
        const sendWorkspaceInviteResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({sendWorkspaceInviteResponse})
        return { status:'success',data:sendWorkspaceInviteResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}

const fetchPendingWorkspaceInvites = async(workspaceId)=>{
    try{
        const requestBody={
            "type":"READ_PENDING_WORKSPACE_INVITES",
            "workspace_id":workspaceId,
            "user_id":auth.currentUser.uid,
        }
        const sendWorkspaceInviteResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({sendWorkspaceInviteResponse})
        return { status:'success',data:sendWorkspaceInviteResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}


const acceptOrRejectWorkspaceInvite = async(workspaceId,memberId,status)=>{
    
    try{
        let requestBody;
        if(status == 'admitted' || status == 'denied')
        requestBody={
            "type":"UPDATE_WORKSPACE_ACCESS",
            "workspace_id":workspaceId,
            "requested_user":memberId,
            "user_id" :auth.currentUser.uid,
            "status":status
        }
        else
        requestBody={
            "type":"UPDATE_WORKSPACE_ACCESS",
            "workspace_id":workspaceId,
            "user_id":memberId,
            "status":status
        }
        const sendWorkspaceInviteResponse = await axios.post(WORKSPACE_ENDPOINT,requestBody)
        console.log({sendWorkspaceInviteResponse})
        // directly send data
        return { status:'success',data:sendWorkspaceInviteResponse.data}

    }catch(error){
        return{ status:'error',error}
    }
}


export default { 
        getWorkspaceDataById,
        getUserWorkspace,
        getAutoSuggestedCompanyWorkspace,
        getWorkspaceMembers,
        fetchWorkspaceInvites,
        updateWorkspace,
        updateWorkspaceRole,
        sendWorkspaceInvite,
        requestWorkspaceAccess,
        createNewWorkspace,
        getWorkspaceToMigrate,
        migrateWorkspace,
        validateWorkspaceAccess,
        joinWorkspace,
        acceptOrRejectWorkspaceInvite,
        fetchPendingWorkspaceInvites
}