<template>
    <div class="status-container">
        <!-- Template & Logic for Rendering Session Status Card -->
        <slot name="status">

        </slot>
        <h3 :style="{'color':textColor?textColor:'#666666'}">
            {{title}}
        </h3>
        <template v-if="hideCTA">
            <div class="cta-empty">

            </div>
        </template>
        <template v-else>
            <slot name="cta">
        
            </slot>
        </template>

            
    </div>
</template>

<script>
export default {
    props:{
        type:{},
        title:{},
        textColor:{},
        hideCTA:{
            default:false
        }
        
    }
}
</script>

<style scoped>
    .status-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px 8px 24px 16px;
        /* width:558px; */
        height: 45px;
        /* Neutrals/White */
        background: #FFFFFF;
        /* Neutrals/200 */

        border: 1px solid #EDEDED;
        border-radius: 32px;
        /* Inside auto layout */
        flex: none;
        /* order: 1; */
        flex-grow: 0;
    }

    .status-container h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */


        /* Neutrals/500 */

        /* color: #666666; */

    }

    .cta-empty{
        width: 80px;
    }
</style>