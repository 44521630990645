<template>
  <div>
  <div :class="type=='basic'?'invite-member-container invite-member-basic':'invite-member-container invite-member-minimal'">
    <div class="invite-member-input-container">
        
            <input :disabled="disableInviteMembers" v-model="inviteeEmail" type="text" placeholder="Enter member’s email ID" />
            <WorkspaceRoleMenu :disableWorkspaceRoleMenu="disableInviteMembers" :value="userProfile.workspace_role" type="access-control" ref="workspaceRoleMenuRef" />
       
    </div>
    <UpgradePlanTooltip 
            type="info"
            :showTooltip="disableInviteMembers" 
            :message="'To invite members to your workspace and collaborate, upgrade to the Teams Plan'"
            renderedIn="Invite Members Button"
        >
        <button :disabled="sendingInvite || !inviteeEmail || disableInviteMembers" @click="inviteMember">
            <template v-if="sendingInvite">
                <template v-if="type=='basic'">Sending Invite...</template>
                <template v-else>Inviting...</template>
                <v-progress-circular indeterminate color="white" :size="25" :width="3"/>
            </template>
            <template v-else>
                <template>Send Email Invite</template>
                <!-- <template v-else>Invite members</template> -->
            </template>
        </button>
    </UpgradePlanTooltip>

  </div>
  <div v-if="showInvitations && workspaceInvites && workspaceInvites.length>0" class="mt-5 mb-5 workspace-invites-container" >
        <p>Invites Sent</p>
        <Member v-for="invite in workspaceInvites" :key="invite.inviteeEmail" :member="invite" :type="'invite'" :disableWorkspaceRoleMenu="disableInviteMembers"/>
    </div>
    <Snackbar
        message="⎷ Invite Sent "
        :showSnackbar="showInviteSentSnackbar"
        snackbarColor="#33B273"
      />
  </div>
</template>

<script>
import WorkspaceAPI from '@/api/workspace/workspace';
import Snackbar from '@/components/notifications/Snackbar.vue';
import Member from './Member.vue';
import { auth} from '@/firebase';
import WorkspaceRoleMenu from '@/components/menus/WorkspaceRoleMenu.vue';
import { mapState } from "vuex";
import UpgradePlanTooltip from '@/components/UpgradePlanTooltip.vue';

export default {
    props:{
        type:{
            default:'basic'
        },
        workspaceId:{
            required: true
        },
        showInvitations:{
            default: false
        },
        disableInviteMembers:{
            default: false
        }
    },
    components:{ Snackbar, Member, WorkspaceRoleMenu, UpgradePlanTooltip },
    data(){
        return{
            inviteeEmail:'',
            sendingInvite:false,
            showInviteSentSnackbar:false,
            workspaceInvites:[],
        }
    },
    mounted(){
        this.fetchWorkspaceInvites()
    },
    computed: {
      ...mapState(["userProfile"]),
    },
    methods:{
        async fetchWorkspaceInvites(){
            const invites = await WorkspaceAPI.fetchPendingWorkspaceInvites(this.workspaceId)
            console.log('invites: ',invites)
            if(invites.status=='success') this.workspaceInvites = invites.data;
        },
        async inviteMember(){
            this.sendingInvite = true;
            console.log('role: ',this.$refs.workspaceRoleMenuRef.currentRole)
            const role = this.$refs.workspaceRoleMenuRef.currentRole
            const inviteMemberResponse = await WorkspaceAPI.sendWorkspaceInvite(this.inviteeEmail,this.workspaceId,role)
            console.log('inviteMemberResponse',inviteMemberResponse)
            if(inviteMemberResponse.status == 'success' && inviteMemberResponse.data.status =='success'){
                window.analytics.track("invite workspace member via email", {
                    email: auth.currentUser.email,
                });
                console.log('inviteMemberResponse',inviteMemberResponse)
                this.inviteeEmail=''
                this.$emit('invite-sent')
                this.fetchWorkspaceInvites()
                this.sendingInvite = false
                this.showInviteSentSnackbar = true;
                setTimeout(()=>{
                    this.showInviteSentSnackbar = false;
                },1500)
            }else{
                alert("Oops! There was an error sending the invite")
                this.inviteeEmail=''
                this.sendingInvite = false
            }

        }
    }
}
</script>

<style scoped>

.invite-member-container{
    width: 100%;
}
.invite-membe-basic{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.invite-member-minimal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.invite-member-minimal input{
    margin-right: 0px;
    font-size:14px;
}

.invite-member-basic .invite-member-input-container{
    margin-bottom: 12px;
}

.invite-member-input-container p{
    color: var(--primary-500, #5B7EDB);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
}
.invite-member-container .invite-member-input-container{
    width: 100%;
    display: flex;
    height: 45px;
    padding: 8px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1.5px solid var(--neutrals-200, #EDEDED);
    margin-right: 8px;
}

.invite-member-input-container input{
    width: 80%;
}

.invite-member-container button{
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 44px;
    background: var(--primary-500, #5B7EDB);
    color: var(--neutrals-white, #FFF);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    text-transform: capitalize;
}

.invite-member-basic button{
    width: 100%;
}

.invite-member-container button:disabled{
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.workspace-invites-container{
    border-top: 1px solid #EDEDED;
    padding-top: 20px;
    width: 100%;
}

.workspace-invites-container p{
    color: var(--neutrals-400, #999);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom:12px !important;
}
</style>