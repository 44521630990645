<template>
  <LoopMainCard
    :showImage="false"
    :showCardHeader="false"
    :showCta="true"
    :customHeight="269"
    :showCustorPointerOnHover="false"
  >
    <template v-slot:top>
        <div class="newFeature_header mt-5 mb-0">
            <span>
                New Launch
            </span>
        </div>
    </template>
    <template v-slot:info>
        <div class="pl-2 pr-2">
            <h3 class="newFeature_title mb-2">
                {{title}}
            </h3>
            <p class="newFeature_subtitle">
                {{subtitle}}
            </p>
        </div>
       
    </template>
    <template v-slot:cta>
        <button @click="$emit(ctaEvent)" class="newFeature_cta mt-5" :style="{background:ctaBtnColor}">
            <template v-if="showIconInCTA">
                <i v-if="feature==='calendar'" class="ri-calendar-event-line"></i>
            </template>
            {{ctaText}}
        </button>
    </template>
  </LoopMainCard>
</template>

<script>
import LoopMainCard from './LoopMainCard.vue';

export default {
    components:{
        LoopMainCard
    },
    props:{
        feature:{
            type:String,
            required:true,
        },
        title:{
            type:String,
            required:true,
        },
        subtitle:{
            type:String,
        },
        ctaBtnColor: {
            type:String,
            default:'#5B7EDB'
        },
         showIconInCTA:{
            type:Boolean,
            default:true,
        },
        ctaText:{
            type:String,
        },
        ctaEvent: {
            type:String,
        }
    }

}
</script>

<style scoped>
    .newFeature_header{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        gap: 8px;

        width: 89px;
        height: 34px;

        /* Accent/Green/Default */

        background: #33B373;
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .newFeature_header span{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 150%;
        /* identical to box height, or 18px */
        text-transform: capitalize;
        /* Accent/Green/Light */
        color: #F0FFF4;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .newFeature_title{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 150%;
        /* or 32px */

        text-align: center;

        /* Neutrals/600 */

        color: #4C4C4C;


        /* Inside auto layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }

    .newFeature_subtitle{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        text-align: center;

        /* Neutrals/400 */

        color: #999999;


        /* Inside auto layout */

        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;
    }

    .newFeature_cta{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px 12px 12px;
        gap: 4px;

        width: 306.67px;
        height: 45px;

        /* Primary/500 */

        /* background: #5B7EDB; */
        border-radius: 12px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        /* identical to box height, or 21px */

        letter-spacing: -0.01em;
        text-transform: uppercase;

        /* Neutrals/White */

        color: #FFFFFF;
    }

</style>