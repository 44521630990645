// Format bytes to human readable size
function formatBytes(sizeInBytes, decimalPlaces = 1, base = 1000, unit = 'KB') {
    if (sizeInBytes < 1) {
        return '0 B';
    }
    
    const exponent = Math.floor(Math.log(sizeInBytes) / Math.log(base));
    const sizeUnits = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
    const suffix = [unit === 'KB' ? sizeUnits[exponent].toLowerCase() : sizeUnits[exponent], 'B'];

    if (unit === 'KiB') {
        suffix.splice(1, 0, 'i');
    }

    const formattedSize = parseFloat((sizeInBytes / Math.pow(base, exponent)).toFixed(decimalPlaces));
    return `${formattedSize} ${suffix.join('')}`.toUpperCase();
}

export default {
    formatBytes
}