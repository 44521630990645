<template>
    <div class="recent-calls-container mt-10">
        <div class="call-deletion">
            <LoopActionModal
            :isSmallTitle="true"
            :showModal="showDeleteConfirmationModal"
            title="Are you sure you want to delete this file?"
            ctaEvent="confirm-delete"
            ctaBtnColor="#C53030"
            ctaText="delete call"
            v-on:confirm-delete="deleteCall"
            v-on:toggle-modal="toggleDeleteModal"
            >
            <template v-slot:content>
                <p class="call-deletion-text mb-5 mt-5">
                   All data for this file will be permanently deleted.
                </p>
            </template>
            </LoopActionModal>
      </div>
        <div class="recent-calls-header">
            <div>
                <h3 class="mr-5">Recent files</h3>
                <SessionSearch
                    :data="sessions"
                    searchData="sessions"
                    :noBorder="true"
                    customPlaceholder="Search files across projects.."
                />
            </div>
            <div class="recent-calls-header-actions">
                <button
                class="pagination-btn prev-btn mr-5"
                :disabled="prevBtnDisabled || pageNumber <= 1"
                @click="prev"
              >
               <i class="ri-arrow-left-s-line"></i>
              </button>

              <!-- <span>{{this.pageNumber}}</span> -->
              <button
                class="pagination-btn next-btn"
                :disabled="nextBtnDisabled"
                @click="next()"
                
              >
              View more files
              <i class="ri-arrow-right-s-line"></i>
            </button>
            </div>

              

        </div>
        <div class="calls-list-container mt-5 mb-5">
            <template v-if="loadingStatus">
                <v-skeleton-loader height="270" width="312" class="recent-project-loader mr-10" type="card"/>
                <v-skeleton-loader height="270" width="312" class="recent-project-loader mr-10" type="card"/>
                <v-skeleton-loader height="270" width="312" class="recent-project-loader mr-10" type="card"/>
                <v-skeleton-loader height="270" width="312" class="recent-project-loader" type="card"/>
            </template>
            <template v-else>
                <div
                    data-intercom-target="Sample User Call"  
                    @click="trackOpenTranscriptEvent(call)" 
                    :id="call.fileName == '[Sample] Participant 3 | User Researcher' ? 'onboarding-call':''"
                    v-show="!loadingStatus && !call.recordingStatus" :class="call.numAutoGenNotes?'call-container call-container-animation': 'call-container'" 
                    :key="call.id" v-for="(call,ind) in calls"
                    :style="{'margin-right':(ind==0 || ind%3!==0)?'20px':'0px'}"
                >
                <!-- <router-link
                     
                      :to="{
                       path: '/editor',
                        query: {
                          transcriptId: call.id,
                          projectId: call.projectId,
                        },
                      }"
                > -->
                    <div class="call-thumbnail" :style="{'opacity':(disabledSessions.includes(call.id) || call.moveCallInProgress )?0.5:1}">
                            <img v-if="call.type" src="../../../../public/text-call-banner.png">
                            <img v-else :src="call.posterUrl" />
                            <ProjectAssignment
                                v-if="!call.autoGenNotesProcessing && !call.assigningAiNotesToGuide && !call.type"
                                :allProjects="allProjects"
                                :projects="allProjects.projects"
                                :key="call.id"
                                :projectId="call.projectId"
                                :moveCallInProgress="call.moveCallInProgress"
                                :readOnly="false"
                                :sessionId="call.id"
                                v-on:refresh-page="refreshSession"
                                v-on:disable-session="disableSession"
                                v-on:enable-session="enableSession"
                                v-on:show-project-dropdown="showProjectDropdown"
                                v-on:hide-project-dropdown="hideProjectDropdown"
                                :projectDropdownOpened="projectDropdownOpened"
                                renderedIn="RecentCallsDashboard"
                            />
                    

                    </div>
                    <div class="call-details mt-1 pl-3 pt-2" :style="{'opacity':disabledSessions.includes(call.id)?0.5:1}">
                        <div class="call-details-top">
                            <h4>{{call.fileName | trimLength}}</h4>
                            <i @click.stop="deleteTranscriptConfirmation(call,ind)" class="ri-delete-bin-6-line"></i>
                        </div>
                        <div class="moving-call-message-container mb-3 mt-1" v-if="call.moveCallInProgress">
                            <v-progress-circular indeterminate color="#5B7EDB" :size="12" :width="2" ></v-progress-circular>
                            <span class="moving-call-message">
                                Moving call...
                            </span>
                        </div>
                        <template v-else>
                            <p :class="(call.processed && !call.autoGenNotesProcessing && !call.assigningAiNotesToGuide )  ? 'processed' : 'processing'">  
                                <template  v-if="!call.processed && !call.transcriptionDisabled">
                                        <v-progress-circular 
                                            color='#B7791F'              
                                            :size="15"
                                            :width="1.5"
                                            style="margin-right:5px"
                                            indeterminate
                                        />
                                        Generating transcript & AI notes..
                                    </template>
                                    <template v-else-if="!call.processed && call.transcriptionDisabled">
                                        <div class="upgrade-plan-for-transcript-text">
                                            <v-icon size="16" color="#B7791F" class="mr-0.5">ri-error-warning-line</v-icon>
                                            Upgrade your plan for transcript
                                        </div>
                                    </template>
                                    <template v-else-if="call.autoGenNotesProcessing == true">
                                        <!-- <div class="mb-0 auto-gen-details"> -->
                                            <v-progress-circular 
                                            color='#B7791F'              
                                            :size="15"
                                            :width="1.5"
                                            style="margin-right:5px"
                                            indeterminate
                                        />
                                        Generating AI notes...
                                        <!-- </div> -->
                                    </template>
                                    <template v-else-if="call.assigningAiNotesToGuide == true">
                                    
                                        <v-progress-circular 
                                            color='#B7791F'              
                                            :size="15"
                                            :width="1.5"
                                            style="margin-right:5px"
                                            indeterminate
                                        />
                                            Assigning AI notes to your guide...
                                    
                                    </template>
                                    <template v-else-if="call.numAutoGenNotes">
                                        <div class="auto-gen-details">
                                            <!-- <div class="beacon"></div> -->
                                            <img data-v-be0d0c44="" src="/img/ai-note-blue.c86b63aa.svg" class="mr-1">
                                            {{ call.numAutoGenNotes }} AI notes
                                            <span><i class="ri-time-line"></i> {{call.createdOn | formatMessageProcessed}}</span>
                                        </div>
                                        
                                    </template>
                                    <template v-else-if="call.moveCallInProgress">
                                        <div class="auto-gen-details">
                                            <v-progress-circular indeterminate color="#5B7EDB" :size="16" :width="2" ></v-progress-circular>
                                            Moving call...
                                        </div>
                                    </template>
                                    <template v-else>
                                        <p :style="{
                                                'color':'#99999',
                                                'font-size':'12px'
                                            }">
                                        Uploaded {{call.createdOn | formatMessageProcessed}}
                                        </p>
                                    </template>
                            </p>
                        </template>

                    </div>
                <!-- </router-link> -->
            </div>
            </template>

        </div>
    </div>
</template>

<script>
import formatDistance from "date-fns/formatDistance";
import { auth } from '@/firebase';
import ProjectAssignment from "@/views/projects/ProjectAssignment.vue";
import differenceInMinutes from "date-fns/differenceInMinutes";
import SessionSearch from "./SessionSearch.vue";
import LoopActionModal from '@/components/modals/LoopActionModal.vue';

export default {
    data(){
        return{
            showDeleteConfirmationModal: false,
            transcriptToBeDeleted:null,
            callIndexToBeDeleted:null,
        }
    },
    props: {
        calls: {},
        allProjects: {},
        projectDropdownOpened: {},
        loadingStatus:{},
        disabledSessions:{},
        sessions:{},
        prevBtnDisabled:{},
        nextBtnDisabled:{}
    },
    methods: {
        prev(){
            this.$emit('prev');
        },
        next(){
            this.$emit('next');
        },
        toggleDeleteModal() {
            this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal;
        },
        deleteTranscriptConfirmation(transcriptId,index){
            this.transcriptToBeDeleted = transcriptId;
            this.callIndexToBeDeleted =index;
            this.toggleDeleteModal()
        },
        deleteCall(){
            const call = this.transcriptToBeDeleted;
            const ind =  this.callIndexToBeDeleted;
            console.log('delete event emitting',call,ind)
            this.$emit('delete-call',call.id,ind)
            this.transcriptToBeDeleted = null
            this.callIndexToBeDeleted = null;
        },
        refreshSession(data){
            console.log("refreshSession");
            this.$emit('refresh-page',data)
        },
        disableSession(sessionId){
            console.log("disableSession");
            this.$emit('disable-session',sessionId);
        },
        enableSession(sessionId){
            console.log("enableSession");
            this.$emit('enable-session',sessionId)
        },
        showProjectDropdown(sessionId){
            console.log("showProjectDropdown");
            this.$emit('show-project-dropdown',sessionId)
        },
        hideProjectDropdown(sessionId){
            console.log("hideProjectDropdown");
            this.$emit('hide-project-dropdown',sessionId)
        },
        trackOpenTranscriptEvent(call) {
            window.analytics.track("open transcript", {
                email: auth.currentUser.email,
                transcriptId: call.id,
            });
            this.$router.push(`/editor?projectId=${call.projectId}&transcriptId=${call.id}`)
        },
    },
    filters: {
        trimLength(val) {
            if (val.length < 30) {
                return val;
            }
            return `${val.substring(0, 27)}...`;
        },
        formatMessageProcessed(val) {
            if (!val) {
                return "-";
            }
            let date = new Date(val.seconds * 1000);
            // return "uploaded " + moment(date).fromNow();
            return formatDistance(date, new Date(), { addSuffix: true }).replace("about", "");
        },
        formatMessageUnprocessed(val) {
            if (!val) {
                return "-";
            }

            let date = new Date(val.seconds * 1000);
            if (differenceInMinutes(date, new Date()) < 15) {
                return (
                "Processing - uploaded " +
                formatDistance(date, new Date(), { addSuffix: true })
                ).replace("about", "");
            } else {
                return "error - please upload again or contact support!";
            }
        },
    },
    components: { ProjectAssignment, SessionSearch, LoopActionModal }
}
</script>

<style scoped>
    @keyframes border-pulsate {
        0%   { border-color: rgba(91, 126, 219, 0.4) }
        50% { border-color: rgba(91, 126, 219, 0)}
        100%   { border-color: rgba(91, 126, 219, 0.4) }
    }
    .beacon{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #5B7EDB;
        box-shadow: 0 0 0 #5f8bfaa6;
        animation: pulsing 2s infinite;
        transition: all 0.2s;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 8px;
    }
    .beacon:active {
    transform: scale(1.5);
    }

    @keyframes pulsing {
    from {
        box-shadow: 0 0 0 0 #5f8bfaa6;
    }
    70% {
        box-shadow: 0 0 0 6px #5f8bfa00;
    }
    to {
        box-shadow: 0 0 0 0 #5f8bfa00;
    }
    }
    .auto-gen-details{
        display: flex;
        align-items: center;
        color: var(--Primary-500, #5B7EDB);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
    }

    .auto-gen-details span{
        display: flex;
        align-items: center;
        color: var(--Neutrals-400, #999);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
        margin-left: 10px;
    }
     .auto-gen-details span i{
        margin-right: 3px;
     }

    .upgrade-plan-for-transcript-text {
        display: flex;
        align-items: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: #B7791F;
    }
    .call-container-animation{
        animation: border-pulsate 2s infinite;
        border: 1.5px solid #D2DCFB;
        box-shadow: 0px 0px 10px rgba(91, 126, 219, 0.4);
    }
    .recent-calls-container h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        /* Neutrals/600 */
        color: #4C4C4C;
    }

    .recent-calls-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .recent-calls-header div{
        display: flex;
        align-items: center;
    }

    .v-menu__content.theme--light.menuable__content__active.v-autocomplete__content{
        top:400px !important
    }
    .calls-list-container{
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
    }

    .call-container{
        border: 1px solid #EDEDED;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        cursor: pointer;

    }

    .call-thumbnail{
        position: relative;
        cursor: pointer;
    }

    .call-thumbnail img{
        width: 312px;
        height: 188px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        cursor: pointer;
    }

    .call-thumbnail .placeholder{
        width: 312px;
        height: 188px;
        border-radius: 16px;
        background: #999999;
    }
    .call-details h4{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        /* identical to box height, or 26px */
        text-transform: capitalize;
        /* Neutrals/600 */

        color: #4C4C4C;
    }

    .call-details p{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: #999999;
    }

    .call-details-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
    }

    .call-details-top i{
        color:#B2B2B2;
        cursor: pointer;
    }

    .pagination-btn{
    display: flex;  
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    height: 34px;
    border: 1px solid #EDEDED;
    /* Neutrals/100 */
    background: #F8F8F8;
    border-radius: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-transform: capitalize;

    /* Neutrals/500 */

    color: #5B7EDB;
    }


    .pagination-btn:disabled{
        opacity: 0.7;
        cursor: not-allowed;
        color: #6666;
    }
    .prev-btn{
    width: 40px;

    }

    .next-btn{
    width: 146px;

    }

    .call-deletion-text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        /* or 24px */
        /* Neutrals/400 */
        color: #666;
    }

.call-deletion-text__Strong {
        font-size: 16px;
        color: #4c4c4c;
        font-weight: 600;
    }
    .processed,.processing{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }
    .call-details p.processing{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        color: #B7791F;
    }

    .processed{
        color: #999;
    }

    .moving-call-message-container{
        display: flex;
        align-items: center;
    }

    .moving-call-message{
        color: var(--Primary-500, #5B7EDB);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 19.2px */
        margin-left: 5px;
        margin-bottom: 0;
    }

</style>