<template>
  <div>
    <template v-if="showTooltip">
        <span>
             <v-tooltip content-class="tooltip-collaborator" bottom>
                <template v-slot:activator="{ on, attrs }">
                     <div v-bind="attrs" v-on="on" class="mr-1">
                        <div class="avatar"
                        :style="{
                            'background-color':backgroundColor,
                            'color':color,
                            'border':`1.5px solid ${strokeColor}`,
                            'height':size == 'small' ? '24px' : '30px',
                            'width':size == 'small' ? '24px' : '30px',
                        }"
                        >
                            {{  avatarText[0] | upperCase }}
                        </div>
                    </div>
                </template>
                <span class="tooltip-text">{{ tooltipText }}</span>
            </v-tooltip>
         </span>
    </template>
    <template v-else>
        <div class="avatar"
                        :style="{
                            'background-color':backgroundColor,
                            'color':color,
                            'border':`1.5px solid ${strokeColor}`,
                            'height':size == 'small' ? '24px' : '30px',
                            'width':size == 'small' ? '24px' : '30px',
                        }"
                        >
            {{  avatarText[0] | upperCase}}
        </div>
    </template>

  </div>
</template>

<script>
import randomPick from '@/utils/random';
import hexToRgb from '@/utils/colors';

const colorPalette = [
    '#C53030',
    '#2C7A7B',
    '#7D44DB',
    '#B83280',
    '#EF6C00',
    '#491287',
    '#B7791F',
    '#2B6CB0',
    '#0A369D'
]
export default {
    props:{
        showTooltip:{
            default: false
        },
        tooltipText:{
            default:null
        },
        avatarText:{
            default:'Default'
        },
        size:{
            default:'small'
        }
    },
    filters: {
        upperCase(val) {
            return val.toUpperCase();
        },
    },
    data(){
        return{
            color:null,
            backgroundColor:null,
            stroke:null
        }
    },
    mounted(){
        const randomColor = randomPick(colorPalette);
        console.log('random color: ' + randomColor);
        this.color = randomColor;
        const {r,g,b} = hexToRgb(this.color);
        this.backgroundColor = `rgb(${r},${g},${b},0.05)`
        this.strokeColor = `rgb(${r},${g},${b},0.2)`
    }
}
</script>

<style scoped>
.avatar{
    /* width: 24px; */
    /* height: 24px; */
    flex-shrink: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.tooltip-collaborator{
    background: #4C4C4C;
    color: #fff !important;
    z-index: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
}

.tooltip-text{
    color: var(--neutrals-200, #EDEDED);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
}
</style>