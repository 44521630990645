<template>
  <!-- <header>
    <section>
      <div class="col1">
        <router-link to="/">
          <h3>Looppanel</h3>
        </router-link>
        <ul class="inline">
          <li>
            <router-link to="/">Dashboard</router-link>
          </li>
          <li>
            <router-link to="/highlights">Highlights</router-link>
          </li>
          <li>
            <router-link to="/tagboard">Tagboard</router-link>
          </li>
          <li><a @click="logout()">Logout</a></li>
        </ul>
      </div>
    </section>
  </header> -->
  <!-- <v-navigation-drawer permanent expand-on-hover> -->
  <!-- 5b7edb -->
  <!-- v-model="drawer" -->

  <v-navigation-drawer
    app
    color="#ffffff"
    overlay-opacity="50"
    overlay-color="#ffffff"
    mini-variant-width="80"
    :mini-variant="mini"
    permanent
    width="192"
  >
    <!-- <v-list>
      <v-list-item class="px-2">
        <v-img max-width="256" :src="navLogo"></v-img>
      </v-list-item>
    </v-list> -->

    <router-link to="/">
      <v-img
        class="mt-8 mb-4 ml-4"
        width="110px"
        max-width="256"
        max-height="120px"
        margin="0px 0px 0px 0px"
        :src="navLogo"
      ></v-img>
    </router-link>

    <!-- <v-divider></v-divider> -->

    <!-- <v-list nav dense class="mx-0">
      <router-link style="color: #5b7edb" to="/">
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-video</v-icon>
          </v-list-item-icon>
          <router-link style="color: #5b7edb" to="/">Sessions</router-link>
        </v-list-item>
      </router-link>

      <router-link
        style="color: #5b7edb"
        to="/highlights"
        @click.native="trackOpenHighlights"
      >
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-bookmark</v-icon>
          </v-list-item-icon>
          <router-link
            style="color: #5b7edb"
            to="/highlights"
            @click.native="trackOpenHighlights"
            >Highlights</router-link
          >
        </v-list-item>
      </router-link>

      <router-link
        style="color: #5b7edb"
        to="/tagboard"
        @click.native="trackOpenTagboard"
      >
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-label</v-icon>
          </v-list-item-icon>
          <router-link
            style="color: #5b7edb"
            to="/tagboard"
            @click.native="trackOpenTagboard"
            >Tagboard</router-link
          >
        </v-list-item>
      </router-link>

      <router-link
        style="color: #5b7edb"
        to="/settings"
        @click.native="trackOpenSettings"
      >
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <router-link
            style="color: #5b7edb"
            to="/settings"
            @click.native="trackOpenSettings"
            >Settings</router-link
          >
        </v-list-item>
      </router-link>
    </v-list> -->

    <v-list nav dense>
      <v-list-item-group
        class="nav-links-container"
        color="#5b7edb"
        active-class="itemnavactive"
      >
        <v-tooltip right content-class="navtooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item to="/" link v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>ri-vidicon-line</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sessions</v-list-item-title>
            </v-list-item>
          </template>
          <span v-if="mini" class="navtooltiptext">Sessions</span>
        </v-tooltip>

        <v-tooltip right content-class="navtooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              to="/projects"
              @click.native="trackOpenProjects"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>mdi-folder-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Projects</v-list-item-title>
            </v-list-item>
          </template>
          <span v-if="mini" class="navtooltiptext">Projects</span>
        </v-tooltip>

        <!-- <v-tooltip right content-class="navtooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              to="/highlights"
              @click.native="trackOpenHighlights"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>ri-bookmark-line</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Highlights</v-list-item-title>
            </v-list-item>
          </template>
          <span v-if="mini" class="navtooltiptext">Highlights</span>
        </v-tooltip> -->

        <!-- <v-tooltip right content-class="navtooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              to="/analysis"
              @click.native="trackOpenHighlights"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>ri-bookmark-line</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Analysis</v-list-item-title>
            </v-list-item>
          </template>
          <span v-if="mini" class="navtooltiptext">Analysis</span>
        </v-tooltip> -->

        <v-tooltip right content-class="navtooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              to="/calendar"
              @click.native="trackOpenCalendar"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>ri-calendar-event-line</v-icon>
                <!-- <v-icon >$navHighlights</v-icon> -->
              </v-list-item-icon>
              <v-list-item-title>Calendar</v-list-item-title>
            </v-list-item>
          </template>
          <span v-if="mini" class="navtooltiptext">Calendar</span>
        </v-tooltip>

        <!-- <v-tooltip right content-class="navtooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              to="/tagboard"
              @click.native="trackOpenTagboard"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon color="#5b7edb">$navBoard</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Tagboard</v-list-item-title>
            </v-list-item>
          </template>
          <span v-if="mini" class="navtooltiptext">Tagboard</span>
        </v-tooltip> -->

        <!-- <v-tooltip right content-class="navtooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              to="/settings"
              @click.native="trackOpenSettings"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon >ri-settings-3-line</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
          </template>
          <span class="navtooltiptext" v-if="mini">Settings</span>
        </v-tooltip> -->
      </v-list-item-group>
    </v-list>

    <div v-if="mini" class="pa-2 mr-2 ml-3 mb-4 d-flex">
      <v-btn
        icon
        @click.stop="toggleSidenav"
        elevation="2"
        large
        class="loopone--text loopfive"
      >
        <v-icon>ri-arrow-right-s-line</v-icon>
      </v-btn>
    </div>

    <div v-else class="pa-2 mr-2 ml-3 mb-4 d-flex">
      <v-btn
        rounded
        @click.stop="toggleSidenav"
        large
        depressed
        class="font-weight-bold loopone--text loopfive collapse-btn"
      >
        <span class="collapse-text">
          Collapse
        </span>
        <v-icon style="margin-left: 10px" left>ri-arrow-left-s-line</v-icon>
      </v-btn>
    </div>

    <template v-slot:append>
      <v-tooltip right content-class="navtooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            active-class="settings-active"
            class="settings-link-container"
            to="/settings"
            @click.native="trackOpenSettings"
            link
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-icon>
              <v-icon>ri-settings-3-line</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </template>
        <span class="navtooltiptext" v-if="mini">Settings</span>
      </v-tooltip>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

import { auth } from "@/firebase";

export default {
  data() {
    return {
      mini: false,
      navLogo: "LoopLogoNavBar.svg",
    };
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    toggleSidenav(){
      this.mini=!this.mini; 
      this.$store.dispatch('toggleSidenavOpen',!this.mini)
    },
    logout() {
      this.$store.dispatch("logout", this.userProfile);
    },
    trackOpenHighlights() {
      window.analytics.track("open highlights page", {
        email: auth.currentUser.email,
      });
    },
    trackOpenCalendar() {
      window.analytics.track("open calendar page", {
        email: auth.currentUser.email,
      });
    },
    trackOpenProjects() {
      window.analytics.track("open projects page", {
        email: auth.currentUser.email,
      });
    },
    trackOpenTagboard() {
      window.analytics.track("open tagboard page", {
        email: auth.currentUser.email,
      });
    },
    trackOpenSettings() {
      window.analytics.track("open settings page", {
        email: auth.currentUser.email,
      });
    },
  },
  mounted() {
    window.analytics.identify(auth.currentUser.email, {
      email: auth.currentUser.email,
    });

    // window.analytics.group(this.userProfile["company_id"], {
    //   name: this.userProfile["company_name"],
    // });
  },
   watch: {
    '$route' (to, from) {
      // react to route changes...
       console.log(to.name,from.name)
      if(to.name==='Notetaking' || to.name==='editor' || to.name==='session_share' || to.name==='analysis'){
        this.mini=true;
        this.$store.dispatch('toggleSidenavOpen',true)
      }
    }
  }
};
</script>

<style scoped lang="scss">

.settings-link-container {
  padding: 2px 25px !important;
}
// .router-link-exact-active {
//   font-weight: bold;
//   font-size: large;
//   color: rgb(91 126 219);
//   background-color:#D2DCFB ;

// }

.settings-link-container .v-list-item--active{
  background: #E8EDFC !important;
  color: #5B7EDB !important;
}

.settings-active{
  background: #E8EDFC !important;
  color: #5B7EDB !important;
}

.settings-active i,.settings-active div{
  color: #5B7EDB !important;
  // font-size:18px;
}

.settings-active .v-list-item__title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  // font-size: 14px;
}
.v-navigation-drawer {
  width: 192px;
}

.theme--light.v-icon {
  color: #b2b2b2;
}

.v-list-item__icon {
  font-weight: 100;
}
.itemnavactive {
  background-color: #f6f8fe;
  color: #5b7edb;
  font-size: 14px;
  font-weight: bold;
}

.itemnavactive i {
  color: #5b7edb !important;
}

.collapse-btn{
  box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.08) !important;
}

.collapse-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  margin-bottom: 1px;
  text-transform: uppercase;

  /* Primary/500 */

  color: #5B7EDB;
}

.v-list--dense .v-list-item .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list-item--dense .v-list-item__title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.8rem;
  border-radius: 12px;
  margin-left: 10px;
}

.v-list--nav .v-list-item {
  color: #b2b2b2 !important;
  margin: 0px;
}

// .v-list .v-list-item i{
//   color: #B2B2B2 !important;
// }

.v-list--nav .v-list-item:before {
  border-radius: 12px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 14px;
  margin-left: 12px;
}

.nav-links-container {
  margin-top: 25px !important;
}
.v-list-item {
  margin-bottom: 21px !important;
}
.v-list-item__title {
  // color: #5b7edb;
  color: #b2b2b2 !important;
}

.v-list-item .v-list-item__icon {
  color: #b2b2b2 !important;
}

.v-list .v-list-item--active .v-list-item__title {
  color: #5b7edb !important;
}
.navtooltip {
  background-color: #fafbfe;
  font-size: 14px;
  font-weight: 600;
}
.navtooltiptext {
  color: #5b7edb;
  font-weight: 600;
}

// .loopfive{
//   box-shadow: -1px 1px 11px rgb(0 0 0 / 8%) !important;
// }
/* #v-list-item-icon { */
/* color: #5b7edb; */
/* font-size: larger; */
/* } */
</style>
