<template>
  <div class="linear-progress-bar-container">
    <div class="linear-progress-bar-outer">
        <div class="linear-progress-bar-inner" :style="{width:((currentStep/noOfSteps)*100)+'%'}">

        </div>
    </div>
    <div class="linear-progress-bar-caption">
        {{ currentStep }} of {{ noOfSteps }}
    </div>
  </div>
</template>

<script>
export default {
    props:{
        noOfSteps:{
            default:3 //only for testing
        },
        currentStep:{
            default: 1 //only for testing
        }
    }

}
</script>

<style scoped>
.linear-progress-bar-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 200px;
    justify-content: space-around;
}

.linear-progress-bar-outer,.linear-progress-bar-inner{
    height: 6.5px;
    border-radius: 32px;
}

.linear-progress-bar-outer{
    width: 120px;
    background: #E8EDFC;
}

.linear-progress-bar-inner{
    background: #5B7EDB;
}
.linear-progress-bar-caption{
    color: var(--neutrals-400, #999);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 18px */
}
</style>